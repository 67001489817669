import { makeStyles } from "@material-ui/core";

export const useCollectionToolStyles = makeStyles((theme) => ({
    collection_tool: {
        width: "100%",
        height: "100%",
        position: "absolute",
        maxHeight: "100%",
        fontFamily: '"Times New Roman", Times, serif',
        backgroundImage: "linear-gradient(to top, #dfe9f3 0%, white 100%)",
    },
    loading_screen: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
}));
