import { makeStyles } from "@material-ui/core";

export const useScoreAdjusterStyles = makeStyles((theme) => ({
    adjustField: {
        display: "flex",
        width: "60%",
        justifyContent: "center",
        fontWeight: "bold",
        //alignItems: 'center'
    },
    adjustCurrentStatus: {
        display: "flex",
        paddingTop: "5%",
        width: "25%",
        fontSize: "0.8em",
        alignItems: "center",
        //alignItems: 'center',
        //justifyContent: 'center',
    },
    formControlTeamFields: {
        width: "35%",
        display: "flex",
        alignItems: "center",
    },
    formControl: {
        margin: 2,
        width: "50%",
        maxWidth: 500,
    },
    adjustSetField: {
        paddingTop: "5%",
        display: "flex",
        alignItems: "center",
        height: "4vh", /* to fit on smaller browser size - SRT-1593 */
    },
    setButton: {
        height: "100%",
    },
}));
