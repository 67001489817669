import { Button, Paper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectSafety, selectHomeFieldGoal, selectAwayFieldGoal, selectKickOff, selectCloseOpenGame, selectStatus,
    selectTeamPossesion, selectHomeTouchdownDisabled, selectAwayTouchdownDisabled, selectAwayOnePointMadeMissed,
    selectHomeOnePointMadeMissed, selectHomeTwoPointsMadeMissed, selectAwayTwoPointsMadeMissed, selectSnap,
    selectCommandId
} from '../../store/selectors';
import { q2_q4 } from './AmericanFootball';
import { useSRTMainButtonStyles } from './SRTMainButtonStyles';

//import { q2_q4 } from './AmericanFootball';

export const buttonColors = {
    //setStatus: { color: 'grey', textColor: 'black' },
    touchdown: { color: '#ff867f', textColor: 'white' },
    onePointConversionAttempt: { color: '#6f74dd', textColor: 'white' },
    twoPointsConversionAttempt: { color: '#6f74dd', textColor: 'white' },
    onePointConversionMade: { color: 'lightgreen', textColor: 'black' },
    onePointConversionMissed: { color: '#ff7961', textColor: 'white' },
    twoPointsConversionMade: { color: 'lightgreen', textColor: 'black' },
    twoPointsConversionMissed: { color: '#ff7961', textColor: 'white' },
    safety: { color: '#ff7961', textColor: 'white' },
    penalty: { color: '#ef5350', textColor: 'white' },
    challenge: { color: '#ff7961', textColor: 'white' },
    timeout: { color: '#6f74dd', textColor: 'white' },
    fieldGoalAttempt: { color: '#6f74dd', textColor: 'white' },
    fieldGoalMade: { color: 'lightgreen', textColor: 'black' },
    fieldGoalMissed: { color: '#ff7961', textColor: 'white' },
    down: { color: 'black', textColor: 'white' },
    snap: { color: 'grey', textColor: 'black' },
    possession: { color: 'lightgreen', textColor: 'black' },
    activeTeam: { color: '#ffffa8', textColor: 'black' },
    yards: { color: '#ffffa8', textColor: 'black' },
    replayReview: { color: '#e53935', textColor: 'white' },
    status_unsure: { color: '#e53935', textColor: 'white' },
    status_ok: { color: 'lightgreen', textColor: 'black' },
    setStatus: { color: '#e3f2fd', textColor: 'black' },
    standby: { color: '#e3f2fd', textColor: 'black' },
    kickoff: { color: '#6f74dd', textColor: 'white' },
}


export default function SRTMainButton({ text, popUp, disabled, handleSendMainCommands,
    handleClickOpenPopUp, handleSendStatus, commandKey, team,
}) {
    /* Take from store! */
    const isSafetyDisabled = useSelector(selectSafety);
    const homeFieldGoalButtons = useSelector(selectHomeFieldGoal);
    const awayFieldGoalButtons = useSelector(selectAwayFieldGoal);
    const homeTouchdownDisabled = useSelector(selectHomeTouchdownDisabled);
    const awayTouchdownDisabled = useSelector(selectAwayTouchdownDisabled);
    const homeOnePointMadeMissed = useSelector(selectHomeOnePointMadeMissed);
    const awayOnePointMadeMissed = useSelector(selectAwayOnePointMadeMissed);
    const homeTwoPointsMadeMissed = useSelector(selectHomeTwoPointsMadeMissed);
    const awayTwoPointsMadeMissed = useSelector(selectAwayTwoPointsMadeMissed);
    const kickOff = useSelector(selectKickOff);
    const isCloseOrOpenGame = useSelector(selectCloseOpenGame);
    const currentStatus = useSelector(selectStatus);
    const currentTeamPossesion = useSelector(selectTeamPossesion);
    const currentSnap = useSelector(selectSnap);
    const commandId = useSelector(selectCommandId);

    const specialButtons = ['replayReview', 'status_ok', 'status_unsure', 'lostCommunication'];
    //const disabledNotHiddenButtons = ['touchdown', 'down', 'snap'];
    const closeOpenGameCommands = ['status_ok', 'status_unsure'];

    const classes = useSRTMainButtonStyles();

    /* Variable tempDisabled used for defining which button to be shown or not in specific scenarios */
    let tempDisabled = true;
    let tempTeam = team === 1 ? 'home' : 'away';

    switch (commandKey) {

        case 'setStatus':
            //tempDisabled = true;
            break;

        case 'onePointConversionAttempt':
        case 'twoPointsConversionAttempt':
            if (team === 1) {
                tempDisabled = homeTouchdownDisabled ? true : false;
            }
            else {
                tempDisabled = awayTouchdownDisabled ? true : false;
            }
            break;

        case 'onePointConversionMade':
        case 'onePointConversionMissed':
            if (team === 1) {
                tempDisabled = homeOnePointMadeMissed ? true : false;
            }
            else {
                tempDisabled = awayOnePointMadeMissed ? true : false;
            }
            break;

        case 'twoPointsConversionMade':
        case 'twoPointsConversionMissed':
            if (team === 1) {
                tempDisabled = homeTwoPointsMadeMissed ? true : false;
            }
            else {
                tempDisabled = awayTwoPointsMadeMissed ? true : false;
            }
            break;

        case 'safety':
            tempDisabled = isSafetyDisabled !== tempTeam;
            break;

        case 'fieldGoalMade':
        case 'fieldGoalMissed':
            if (team === 1) {
                tempDisabled = homeFieldGoalButtons ? true : false;
            }
            else {
                tempDisabled = awayFieldGoalButtons ? true : false;
            }
            break;

        case 'down':
        case 'touchdown':
            if (team === 1) {
                tempDisabled = currentTeamPossesion === 'none' ? false : currentTeamPossesion === 'home' ? true : false;
            }
            else {
                tempDisabled = currentTeamPossesion === 'none' ? false : currentTeamPossesion === 'home' ? false : true;
            }
            break;

        case 'snap':
            /* Enable SNAP for the team in possession,once it’s updated disable the button until another command is updated. 
                After this the SNAP button can be enabled once again for the team in possession. - SRT-1456 */
            if (team === 1) {
                tempDisabled = currentTeamPossesion === 'none' ? false :
                    currentTeamPossesion === 'home' ? currentSnap === 'none' ? true : currentSnap === 'away' ? true : false :
                        currentSnap === 'home' ? false :
                            currentSnap === 'none' ? false :
                                currentSnap === 'away' ? false : true;
            }
            else {
                tempDisabled = currentTeamPossesion === 'none' ? false :
                    currentTeamPossesion === 'home' ? currentSnap === 'none' ? false : currentSnap === 'away' ? true : false :
                        currentSnap === 'home' ? true :
                            currentSnap === 'none' ? true :
                                currentSnap === 'away' ? false : true;
            }
            break;

        default:
            break;
    }

    /* It depents if we have Kickoff taken or not  */
    /* Their is a case in Q2 and Q4 when we need to show all buttons without kickoff taken (`q2_q4.includes(currentStatus)`) */
    /* Variable isDisabled (combination of different variables/scenarios) to be used when & which button should be SHOWN */
    const isDisabled =
        ((tempDisabled && (kickOff !== 'none' || q2_q4.includes(currentStatus))) ||
            (!disabled && kickOff === 'none')) ? false : true;

    const styles = {
        specialStyle: {
            color: 'white',
            width: '100%',
            /* Lightgreen color for Open game and Red color for Close game and Replay Review */
            backgroundColor: isCloseOrOpenGame === 'status_ok' && closeOpenGameCommands.includes(commandKey) ? 'lightgreen' : '#e53935',
        },
        buttonStyle: {
            width: '100%',
            fontSize: '0.9vw',
        },
    }

    return (
        <div className={specialButtons.includes(commandKey) ? classes.fullWidth : classes.paperWrapper}>
            <Paper elevation={3} className={classes.paper}>
                {/* <ThemeProvider theme={theme}> */}
                <Button
                    id={`btn-${tempTeam}-${commandKey}`}
                    style={Object.assign({
                        backgroundColor: buttonColors[commandKey]?.color,
                        opacity: isDisabled ? (commandKey === 'safety' ? 0.3 : 1) : 1,
                        color: buttonColors[commandKey]?.textColor,
                        /* Hide buttons , but do not hide Safety button (just disabled) as it hides the hole row */
                        display: isDisabled ? (commandKey !== 'safety' ? 'none' : kickOff !== 'none' ? 'flex' : 'none') : 'flex'
                    },
                        specialButtons.includes(commandKey) ? styles.specialStyle : styles.buttonStyle)
                    }
                    //color="primary"
                    disabled={isDisabled}
                    variant="contained"
                    size="small"
                    //className={classes.btnMargin}
                    onClick={() => {
                        if (popUp) {
                            if (commandKey === 'setStatus') {
                                handleClickOpenPopUp(commandKey, team, false, commandId, false, null)
                                //handleSendStatus();
                            }
                            else {
                                /* send uncf command */
                                handleSendMainCommands(commandId, 'command', commandKey, team, Math.abs(currentStatus * 1), false, false, false, '', '', false);
                                /* the initial sent command should be uncf and pop up will be opened */
                                handleClickOpenPopUp(commandKey, team, false, commandId, false, null)
                            }
                        }
                        else {
                            /* If there is no pop up then we send confirmed command straight away */
                            handleSendMainCommands(commandId, 'command', commandKey, team, Math.abs(currentStatus * 1), true, false, false, '', '', false);
                        }
                    }}
                >
                    {text}
                </Button>
                {/* </ThemeProvider> */}
            </Paper >
        </div >
    )
}


