import { Button } from '@material-ui/core';
import React, { useEffect } from 'react'; // we need this to make JSX compile
import Clock from 'react-live-clock';
import 'moment-timezone';
import './Navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavBarStyles } from './NavBarStyles';
import ScoreAdjuster from './ScoreAdjuster';
import { selectStatus, selectOverlayDisplay, selectConnectionStatus, selectHeartBeat } from '../../../store/selectors';
import { setHeartBeat } from '../../../store/actions';
import { allStatusesAvailable } from '../common/constants';


const NavBar = () => {

    const classes = useNavBarStyles();
    const currentStatus = useSelector(selectStatus);
    const overlayDisplay = useSelector(selectOverlayDisplay);
    const connectionStatus = useSelector(selectConnectionStatus);
    const heartBeat = useSelector(selectHeartBeat);
    const dispatch = useDispatch();

    let today = new Date();
    let date = (today.getDate() + '.' + (today.getMonth() + 1)) + '.' + today.getFullYear();

    useEffect(() => {
        /* Make the heart beat to pulse when we load from back-end and have no overlay for adding commands */
        if (overlayDisplay === 'none') {
            dispatch(setHeartBeat({
                className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
                color: connectionStatus ? 'lightgreen' : 'red',
                text: connectionStatus ? 'Connected' : 'No connection'
            }));
        }
        // eslint-disable-next-line 
    }, [overlayDisplay]);

    /* Update the hook to shows when there is no connection with back-end OR user is connected with heart beat signal */
    useEffect(() => {
        dispatch(setHeartBeat({
            className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
            color: connectionStatus ? 'lightgreen' : 'red',
            text: connectionStatus ? 'Connected' : 'No connection'
        }));

        // eslint-disable-next-line 
    }, [connectionStatus]);

    return (
        <div className={classes.navbar_desktop}>

            <div className={classes.heart_beat_field}>
                <div className={heartBeat['className']} style={styles.heart_beat}>
                    <Button
                        style={Object.assign({ backgroundColor: heartBeat['color'] }, styles.heart_beat)}
                        variant="contained"
                        color="primary"
                        size="small"
                        data-cy="heartbeat"
                        data-testid="heartbeat"
                    >
                        {heartBeat['text']}
                    </Button>
                </div>
            </div>

            <div className={classes.status_and_game_time_clock}>
                <div data-testid="current-status" className={classes.current_status} data-cy="current-status">
                    {allStatusesAvailable[currentStatus].title}
                </div>
            </div>



            <div className={classes.curent_day_field}>
                <ScoreAdjuster />

                <div>
                    <div className="current_day" data-cy="current-day">
                        {date}
                    </div>
                    <div className={classes.current_time} data-cy="current-time">
                        <Clock format={'HH:mm:ss'} ticking={true} timezone={"UTC"} />
                    </div>
                    <div data-testid="label-server-time" className={classes.server_time_text} data-cy="label-server-time">
                        Server Time: UTC
                    </div>
                </div>
            </div>
        </div>
    );
}

let styles = {
    heart_beat: {
        borderRadius: '15px'
    },
}


export default NavBar;