import { makeStyles } from "@material-ui/core";

export const useTableTennisStyles = makeStyles((theme) => ({
    tennisTableWrapper: {
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
    },
    match_data: {
        //paddingTop: '0.5%',
        width: "100%",
        height: "93%",
        overflow: "auto",
    },
    command_log: {
        //backgroundColor: '#f7f7f7',
        margin: "0.5% 0% 1% 0.5%",
        height: "55%",
        //height : '1000px',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "15px",
        /* zIndex: 1001,
        position: 'relative' as 'relative', */
    },
}));
