/* All commands that will have pop up for confirmation */
export const allPopUpCommands = ["point" /* , 'timeout' */];
export const sportID = "269467";
/* keep uncf point to prevent adding two uncf */
export const settings = ["releaseControl", "takeControl", 'callcenterOk', 'callcenterLost'];
/* Array which contain 'MSF endpoints' related to increasing feed sequence */
export const feedSequenceIdCommands = [
    "currentserver",
    "matchstatus",
    "point",
    "gamescore" /* , 'reset' */,
];

/* All command rows with the respective colors */
export const allTeamCommands = {
    serve: {
        color: "#f5f7fa 0%, #e2ebf0 100%",
        cancelDisabled: true,
        editDisabled: false,
    },
    let: {
        color: "#f5f7fa 0%, #e9e9e7 100%",
        cancelDisabled: true,
        editDisabled: false,
    },
    point: {
        color: "#f5f7fa 0%, #84fab0 100%",
        cancelDisabled: false,
        editDisabled: false,
    },
    timeout: {
        color: "#f5f7fa 0%, #fcb69f 100%",
        cancelDisabled: true,
        editDisabled: false,
    },
    firstServe: {
        color: "#f5f7fa 0%, #ffd1ff 100%",
        cancelDisabled: true,
        editDisabled: false,
    },
};

export const allNoTeamCommands = {
    status_ok: {
        color: "linear-gradient(to top, #0ba360 0%, #3cba92 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    status_unsure: {
        color: "linear-gradient(to top, #b50d13 99%, #fad0c4 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    matchstatus: {
        color: "linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    gamestart: {
        color: "linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    gamefinish: {
        color: "linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    comment: {
        color: "linear-gradient(to top, #4481eb 0%, #04befe 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    preMatchInfo: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    pitch: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    attendance: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    homeColor1: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    homeColor2: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    awayColor1: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    awayColor2: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    confirmScore: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
    gamescore: {
        color: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
        cancelDisabled: true,
        editDisabled: true,
    },
};

export const allTeamCommandsKeys = Object.keys(allTeamCommands);
export const allNoTeamCommandsKeys = Object.keys(allNoTeamCommands);

export const allStatusesAvailable = {
    "0": { title: "Not Started", nextStatus: 1 },
    "1": { title: "Game 1", nextStatus: -1 },
    "-1": { title: "End Game 1", nextStatus: 2 },
    "2": { title: "Game 2", nextStatus: -2 },
    "-2": { title: "End Game 2", nextStatus: 3 },
    "3": { title: "Game 3", nextStatus: -3 },
    "-3": { title: "End Game 3", nextStatus: 4 },
    "4": { title: "Game 4", nextStatus: -4 },
    "-4": { title: "End Game 4", nextStatus: 5 },
    "5": { title: "Game 5", nextStatus: -5 },
    "-5": { title: "End Game 5", nextStatus: 6 },
    "6": { title: "Game 6", nextStatus: -6 },
    "-6": { title: "End Game 6", nextStatus: 7 },
    "7": { title: "Game 7", nextStatus: -7 },
    "-7": { title: "End Game 7", nextStatus: 8 },
    "8": { title: "Game 8", nextStatus: -8 },
    "-8": { title: "End Game 8", nextStatus: 9 },
    "9": { title: "Game 9", nextStatus: -9 },
    "-9": { title: "End Game 9", nextStatus: 111 },
    "100": { title: "Interrupted", nextStatus: 101 },
    "101": { title: "Abandoned", nextStatus: 111 },
    "102": { title: "Cancelled", nextStatus: 111 },
    "111": { title: "Finished", nextStatus: 111 },
};

export const activeStatuses = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const skipFirstServePopupStatuses = [101, 102];

