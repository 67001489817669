import React from 'react';
import Button from '@material-ui/core/Button';
import { sportID, } from './common/constants';
import { event_id } from '../../App';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { green, red } from '@material-ui/core/colors';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { sendCommand } from "../CollectionTool.utils";
import { setOverlayDisplay } from '../../store/actions';
//import { createTheme } from '@material-ui/core/styles';
import { createTheme } from "@material-ui/core";
import { selectCommandId, selectFeedCommandInc } from '../../store/selectors'


const Reset = () => {

    const commandId = useSelector(selectCommandId);
    const feedCommandInc = useSelector(selectFeedCommandInc);

    let resetObj = {
        type: "command",
        page: "desktop",
        value: {
            feed: {
                "sequence": feedCommandInc,
                "isConfirmed": true,
                "utcTimestamp": new Date().toISOString()
            },
            id: commandId,
            sport_id: sportID,
            "event_id": event_id,
            feedAction: 'reset',
            "UTCtimestamp": new Date().toISOString()
        }
    };

    const dispatch = useDispatch();

    /* Green/red theme for buttons */
    const theme = createTheme({
        palette: {
            primary: green,
            secondary: red,
        },
    });

    /* This pop up will be open when user click at RESET button */
    const popupReset = (obj, dispatch) => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div data-testid="popup-reset" className='custom-ui' data-cy="popup-reset">
                        <h1 data-testid="popup-reset-title">Are you sure to reset the event?</h1>
                        <div style={styles.yes_no_field}>
                            <Button
                                style={{ fontSize: '1.3em', borderRadius: '15px' }}
                                variant="contained"
                                color="primary"
                                //size="large"
                                onClick={() => {
                                    /* Show the overlay pop up to not be able to add more commands until this one is processed and Overlay will hide   */
                                    dispatch(setOverlayDisplay('block'));
                                    sendCommand(obj);
                                }}
                                data-cy="popup-reset-yes"
                                data-testid="popup-reset-yes"
                            >
                                Yes
                            </Button>

                            <MuiThemeProvider theme={theme}>
                                <Button
                                    style={{ fontSize: '1.3em', borderRadius: '15px' }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={onClose}
                                    data-cy="popup-reset-no"
                                    data-testid="popup-reset-no"
                                >
                                    No
                                </Button>
                            </MuiThemeProvider>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <div className="reset" style={styles.reset_field}>
            <div className="opened_apps">
                {/*<div>Opened Callcenters:</div>*/}
                {/* <div>Viewers etc:</div> */}
            </div>
            <div>
                <Button
                    id="btn-reset"
                    style={styles.buttons}
                    variant="contained"
                    color="secondary"
                    size="large"
                    data-cy="button-reset"
                    data-testid="button-reset"
                    onClick={() => {
                        popupReset(resetObj, dispatch);
                    }}
                >
                    Reset
                </Button>
            </div>
        </div>
    )
}

let styles = {
    reset_field: {
        margin: '0% 1%',
        height: '3%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '80%',
    },
    buttons: {
        margin: '1%',
        borderRadius: 15,
        //padding: '1%',
    },
    yes_no_field: {
        display: 'flex',
        justifyContent: 'space-around',
    },
}

export default Reset;