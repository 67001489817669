import React, { /* useEffect */ } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CommandList from './CommandList';
import { useCommandFilterStyles } from './CommandFilterStyles';
import { TabPanel } from '../TabPanel';
import { selectCommandLogArrays } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

export default function CommandFilter() {
    const classes = useCommandFilterStyles();
    /* Hook used to change tabs (SHOW ALL , POINT ... ) */
    const [tabsValue, setTabsValue] = React.useState(0);
    const commandLogArrays = cloneDeep((useSelector(selectCommandLogArrays)));

    /* Func to update the hook for changing tabs (SHOW ALL , POINT ... ) */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabsValue(newValue);
    };

    return (
        <div id="command_filter_field" className={classes.root}>

            <AppBar id="command_log_appbar" position="static" className={classes.command_log_appbar}>
                <Tabs data-testid="tabs-field" value={tabsValue} onChange={handleChange} aria-label="simple tabs example">
                    <Tab data-testid="tab-show-all" data-cy="tab-show-all" label="Show All" id={'simple-tab-0'} aria-controls={'simple-tabpanel-0'} />
                    <Tab data-testid="tab-point" data-cy="tab-point" label="Point" id={'simple-tab-1'} aria-controls={'simple-tabpanel-1'} />
                    <Tab data-testid="tab-comments" data-cy="tab-comments" label="Comments" id={'simple-tab-2'} aria-controls={'simple-tabpanel-2'} />
                    <Tab data-testid="tab-info" data-cy="tab-info" label="Info" id={'simple-tab-3'} aria-controls={'simple-tabpanel-3'} />
                </Tabs>
            </AppBar>
            <TabPanel data-testid="tab-panel-show-all" uniqueId="commandLogs-tabpanel" uniqueLabel="commandLogs-tab" data-cy="show-all-tab" className={classes.command_log_tabpanel} value={tabsValue} index={0}>
                <CommandList data-testid="command-list-all" commandLogArray={commandLogArrays.all} showButtons={false} tab={'showAll'} />
            </TabPanel>
            <TabPanel data-testid="tab-panel-point" uniqueId="commandLogs-tabpanel" uniqueLabel="commandLogs-tab" data-cy="point-tab" value={tabsValue} index={1}>
                <CommandList data-testid="command-list-point" commandLogArray={commandLogArrays.points} showButtons={false} tab={'point'} />
            </TabPanel>
            <TabPanel data-testid="tab-panel-comments" uniqueId="commandLogs-tabpanel" uniqueLabel="commandLogs-tab" data-cy="comment-tab" value={tabsValue} index={2}>
                <CommandList data-testid="command-list-comments" commandLogArray={commandLogArrays.comments} showButtons={false} tab={'comments'} />
            </TabPanel>
            <TabPanel data-testid="tab-panel-info" uniqueId="commandLogs-tabpanel" uniqueLabel="commandLogs-tab" data-cy="info-tab" value={tabsValue} index={3}>
                <CommandList data-testid="command-list-info" commandLogArray={commandLogArrays.info} showButtons={false} tab={'info'} />
            </TabPanel>
        </div>
    );
}
