import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { 
    selectStatus, selectUser, selectGameScoreToStatusId, selectMapIdToActionId, selectCommandId, 
    selectCommandInc, selectFeedCommandInc 
} from '../../../store/selectors';
import { activeStatuses, sportID } from '../common/constants';
import cloneDeep from 'lodash.clonedeep';
import { validateScorePoint } from '../utils';
import { sendCommand } from "../../CollectionTool.utils";
import { event_id } from '../../../App';
import { useScoreAdjusterStyles } from './ScoreAdjusterStyles';

const ScoreAdjuster = () => {

    const classes = useScoreAdjusterStyles();
    const user = useSelector(selectUser);
    const currentStatus = useSelector(selectStatus);
    const gameScoreToStatusId = useSelector(selectGameScoreToStatusId);
    const mapIdToActionId = useSelector(selectMapIdToActionId);
    const commandId = useSelector(selectCommandId);
    const commandInc = useSelector(selectCommandInc);
    const feedCommandInc = useSelector(selectFeedCommandInc);

    let id = gameScoreToStatusId[currentStatus] ? gameScoreToStatusId[currentStatus] : commandId;

    let obj = {
        type: 'command',
        user: user,
        page: 'desktop',
        value: {
            id: id,
            key: 'gamescore',
            // eslint-disable-next-line camelcase
            sport_id: sportID,
            team: 0,
            isConfirmed: true,
            isModified: commandId !== id ? true : false,
            // eslint-disable-next-line camelcase
            gameNumber: Number(currentStatus),
            // eslint-disable-next-line camelcase
            event_id: event_id,
            sequence: commandInc,
            internal: true,
            UTCtimestamp: new Date().toISOString(),
            feedAction: 'gamescore'
        }
    }

    if (id !== commandId && typeof mapIdToActionId[id] !== 'undefined') {
        obj.value['feedId'] = mapIdToActionId[id];
    }

    const [gameScore, setGameScore] = useState([-1, -1]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isAdjustScoreDisabled, setIsAdjustScoreDisabled] = useState(true);

    const handleChange = (event, index) => {
        let tempScore = cloneDeep(gameScore);
        if (event.target.value !== '') {
            tempScore[index] = parseInt(event.target.value);
        } else {
            tempScore[index] = -1;
            setIsAdjustScoreDisabled(true);
        }
        setGameScore(tempScore);
    }

    /*When the status is changed to active one the set score adjuster is enabled and vice versa and input values are set to default ones*/
    useEffect(() => {
        if (activeStatuses.includes(currentStatus)) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
        setGameScore([-1, -1]);
        // eslint-disable-next-line 
    }, [currentStatus]);

    useEffect(() => {
        /*Check if the added score for the game is correct*/
        if (validateScorePoint(gameScore)) {
            setIsAdjustScoreDisabled(false);
        } else {
            setIsAdjustScoreDisabled(true);
        }
        // eslint-disable-next-line 
    }, [gameScore]);

    return (
        <div className={classes.adjustField}>
            <div className={classes.adjustCurrentStatus}>
                {activeStatuses.includes(currentStatus) ? 'Game ' + currentStatus : ''}
            </div>

            <form autoComplete="off" className={classes.formControlTeamFields}>
                <FormControl id="score_field_home" className={classes.formControl}>
                    <TextField
                        type="number"
                        disabled={isDisabled}
                        helperText="Home"
                        data-cy="home-score-input"
                        data-testid="home-score-input"
                        value={gameScore[0] === -1 ? '' : gameScore[0]}
                        onChange={(e) => {
                            handleChange(e, 0)
                        }}
                        inputProps={{
                            name: 'home-score-input'
                        }}
                    />
                </FormControl>
                <FormControl id="score_field_away" className={classes.formControl}>
                    <TextField
                        type="number"
                        disabled={isDisabled}
                        helperText="Away"
                        data-cy="away-score-input"
                        data-testid="away-score-input"
                        value={gameScore[1] === -1 ? '' : gameScore[1]}
                        onChange={(e) => {
                            handleChange(e, 1)
                        }}
                        inputProps={{
                            name: 'away-score-input',
                        }}
                    />

                </FormControl>
            </form>

            <div className={classes.adjustSetField}>
                <Button
                    disabled={isDisabled === false && isAdjustScoreDisabled === false ? false : true}
                    variant="contained"
                    size="small"
                    data-cy="set-score-button"
                    data-testid="set-score-button"
                    className={classes.setButton}
                    onClick={() => {
                        if (validateScorePoint(gameScore)) {
                            obj.value['feed'] = {
                                gameNumber: Number(currentStatus),
                                scoreInPoints: {
                                    competitorOne: gameScore[0],
                                    competitorTwo: gameScore[1]
                                },
                                sequence: feedCommandInc,
                                isConfirmed: true,
                                utcTimestamp: new Date().toISOString()
                            }

                            obj.value['value'] = {
                                gamescore: gameScore
                            };

                            sendCommand(obj);
                            setGameScore([-1, -1]);
                        }
                    }}>
                    Set
                </Button>
            </div>
        </div>

    );
}

export default ScoreAdjuster;