import { makeStyles } from "@material-ui/core";

export const useCommentsDropdownStyles = makeStyles((theme) => ({
    form_root: {
        width: "60%",
        //justifyContent: 'space-around',
        display: "flex",
        alignItems: "center",
    },
    formControl: {
        margin: 2,
        width: "90%",
        maxWidth: 500,
        textTransform: "capitalize",
    },
}));
