import { makeStyles } from "@material-ui/core";

export const useStatusFieldStyles = makeStyles((theme) => ({
    select: {
        "& ul": {
            backgroundColor: "#f7f5f5",
        },
        "& li": {
            fontSize: '0.9em',
            fontStyle: 'oblique',
        },
    },
    set_status_field: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50%',
        //flexDirection: 'column',
    },
    set_status: {
        display: 'flex',
        flexGrow: 1,
    },
    formControl: {
        margin: 2,
        width: '90%',
        maxWidth: 500,
        //textTransform: 'capitalize'
    },
    set_status_button: {
        backgroundColor: '#e3f2fd',
        opacity: 1,
        color: 'black',
        display: 'flex',
        fontSize: '0.7em'
    }
}));