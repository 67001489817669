import React from 'react';

const Header = () => {

    return (
        <div className="game_info_header" style={styles.game_info_header}>
            <div data-testid="game-info-text">Game Info</div>
        </div>
    )
}

let styles = {
    game_info_header: {
        borderRadius: '15px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'grey'
    },
}

export default Header;