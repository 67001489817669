import React from 'react';
import CommandFilter from './CommandFilter';

const CommandLog = () => {

    return (
        <div data-testid="commands-log" className="commands" style={styles.commands}>
            <CommandFilter />
        </div>
    )
}

let styles = {
    commands: {
        height: '100%',
        width: '70%',
        //overflow: 'auto',
    },
}

export default CommandLog;