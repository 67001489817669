import { makeStyles } from "@material-ui/core";

export const useCommandListStyles = makeStyles((theme) => ({
    show_button: {
        display: "flex",
        justifyContent: "center",
        borderRadius: "15px",
    },
    hide_button: {
        display: "none",
    },
    row_wrapper: {
        margin: "0%",
        //padding: '0.5%',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        //gap: '1%',
        //borderRadius: '10px',
    },
    form_root: {
        //width: '30%',
        //justifyContent: 'space-around',
        display: "flex",
        alignItems: "center",
    },
    formControl: {
        margin: 2,
        width: "90%",
        maxWidth: 500,
        textTransform: "capitalize",
    },
    button_edit_field:{
        display: "flex",
        justifyContent: "center",
        borderRadius: "15px",
        backgroundColor: "lightgreen",
    }
}));
