import React from 'react';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirstServeDisplay, selectButtonBlink, selectSportSuggestedStatus } from '../../../store/selectors';
import { setSportSuggestedStatus, setOpenConfirmPopUp } from '../../../store/actions';
import { skipFirstServePopupStatuses } from '../common/constants';
import MenuItem from '@material-ui/core/MenuItem';
import { useStatusFieldStyles } from './StatusFieldStyles';
import { store } from '../../../store';

/* Fill with correct statuses in the dropdown */
export const allStatusesNeeded = () => {
    const eventInfo = store.getState().eventInfo;
    const sets = eventInfo?.sets || 9;
    let arr = [];
    /* Include Not started as default */
    arr.push(<MenuItem data-cy="status-item-0" key={0} value="0">Not Started</MenuItem>);
    /* from game_1 to whatever sets we have as event_info */
    for (let i = 1; i <= sets; i++) {
        arr.push(<MenuItem data-cy={"status-item-" + (2 * (i - 1) + 1)} key={i} value={'' + i}>{'Game ' + i}</MenuItem>)
        arr.push(<MenuItem data-cy={"status-item-" + (2 * i)} key={i * -1} value={'' + (i * -1)}>{'End Game ' + i}</MenuItem>)
    }

    /* Include INTERRUPTED/FINISHED/CANCELLED by default */
    arr.push(<MenuItem data-cy={"status-item-" + (2 * sets + 1)} key={100} value="100">Interrupted</MenuItem>);
    arr.push(<MenuItem data-cy={"status-item-" + (2 * sets + 2)} key={101} value="101">Abandoned</MenuItem>);
    arr.push(<MenuItem data-cy={"status-item-" + (2 * sets + 3)} key={102} value="102">Cancelled</MenuItem>);
    arr.push(<MenuItem data-cy={"status-item-" + (2 * sets + 4)} key={111} value="111">Finished</MenuItem>);

    return arr;
}


const StatusField = () => {

    const classes = useStatusFieldStyles();
    const firstServeDisplay = useSelector(selectFirstServeDisplay);
    const dispatch = useDispatch();
    const suggestedStatusObject = useSelector(selectSportSuggestedStatus);
    const buttonBlink = useSelector(selectButtonBlink);
    const suggestedStatus = suggestedStatusObject?.tabletennis;

    return (
        <div className={classes.set_status_field}>

            <form autoComplete="off" className={classes.set_status}>
                <FormControl id="select_status_change" className={classes.formControl}>
                    <InputLabel data-cy="status-label" id="label_status">Status</InputLabel>

                    <Select
                        data-cy="select-status-change"
                        labelId="label"
                        data-testid="select-status-change"
                        id="status"
                        value={suggestedStatus}
                        onChange={(e) => {
                            dispatch(setSportSuggestedStatus({ tabletennis: Number(e.target.value) }));
                        }}
                        MenuProps={{ classes: { paper: classes.select } }}>
                        {allStatusesNeeded()}
                    </Select>
                </FormControl>
            </form>

            <div className={buttonBlink}>
                <Button
                    variant="contained"
                    className={classes.set_status_button}
                    //color="primary"
                    size="small"
                    data-cy="button-status-send"
                    onClick={() => {
                        if (firstServeDisplay === 'block' && !skipFirstServePopupStatuses.includes(suggestedStatus)) {
                            dispatch(setOpenConfirmPopUp({ type: 'noFirstServe', open: true, cancel_yes: 'none', ok: 'block' }));
                            return;
                        }
                        dispatch(setOpenConfirmPopUp({ type: 'setStatus', open: true, cancel_yes: 'block', ok: 'none' }));
                    }}>
                    Set Status
                </Button>
            </div>
        </div >
    )
}

export default StatusField;