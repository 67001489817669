import { setEventInfo, setScore, setConnectionStatus, setCurrentUser, setIsDisabledTakenControl } from '../store/actions';
import { store } from '../store';
import { getInitialTTScoreState } from './TableTennis/utils';
import { cloneDeep } from 'lodash';

let socketId = 0;
let loadingFlag = false;
let socket;

export const sendCommand = (obj) => {

    try {
        socket.send(JSON.stringify(obj)); //send data to the server
    } catch (error) {
        console.log(error); // catch error
    }
}

export const getData = (sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand) => {
    let test_data;
    const config = store.getState().config;
    if (config.REACT_APP_ENV !== 'PROD') {
        fetch('./test_events/' + sport + '.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                test_data = Object.assign(myJson);
                store.dispatch(setEventInfo(test_data));
                if ( sport === 'tabletennis'){
                    store.dispatch(setScore(getInitialTTScoreState(test_data?.sets || 9)));
                }
                connect(sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand);
            });
    } else {
        store.dispatch(setEventInfo({"error" : true}));
    }
}

export const connect = (sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand) => {
    const {config, username, token } = store.getState();
    const url = "?page="+ page + "&event_id=" + event_id + "&sport=" + sport + '&user=' + username + '&token=' + token ;
    const websocket_url = config.REACT_APP_WEBSOCKET_URL + url;
    socket = new WebSocket(websocket_url);
    
    socket.onopen = () => {
        // on connecting, do nothing but log it to the console
        console.log('connected');

        store.dispatch(setConnectionStatus(true));

        let obj = { type: 'load', value: {} }

        sendCommand(obj);
    }

    socket.onmessage = evt => {
        let message;
        // listen to data sent from the websocket server
        try {
            message = JSON.parse(evt.data);
        } catch (e) {
            console.log(e);     // error in the above string (in this case, yes)!
        }
        //console.log('onmessage ', message);

        setMessage(message);

        switch (message.type) {

            case 'load':
                store.dispatch(setCurrentUser(message.user));
                if (!loadingFlag) {
                    socketId=message.num;
                    loadingFlag = true;
                    
                    if (typeof message.value !== 'undefined') {
                        if (message.value !== false) {
                            message.value = message.value.split("\n");
                        } else {
                            message.value = [];
                            store.dispatch(setIsDisabledTakenControl(false));
                        }
                    }

                    if (!event_id.startsWith('test') && message.eventInfo && message.eventInfo.reporter) {
                        store.dispatch(setEventInfo(message.eventInfo.reporter));
                        if ( sport === 'tabletennis'){
                            store.dispatch(setScore(getInitialTTScoreState(message?.eventInfo?.reporter?.sets || 9)));
                        }
                    }

                    if (!event_id.startsWith('test') && message.eventInfo === 'error') {
                        store.dispatch(setEventInfo({"error" : true}));
                    }

                    setDbData(message.value);
                }
                break;

            case 'command':
            case 'setting':
                /* Put user & actionId in the command */
                if (message.user) {
                    message.value['user'] = cloneDeep(message.user);
                }
                if (message.actionId) {
                    message.value['actionId'] = cloneDeep(message.actionId);
                }

                /* Check if it is the same CC and update the hook */
                if (message.value.key === 'takeControl') {
                    setIsControlTaken(message.num === socketId); 
                }
                if (message.value.key === 'releaseControl') {
                    setIsControlTaken(false);
                }
                setNewCommand([JSON.stringify(message)]);
                break;
            case 'reset':
                window.location.reload();
                break;

            default:
                break;
        }
    }

    socket.onclose = (e) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        store.dispatch(setConnectionStatus(false));
        setTimeout(function () {
            connect(sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand);
        }, 1000);
    }

    socket.onerror = (err) => {
        console.log('Socket encountered error: ', err.message, 'Closing socket');
        socket.close();
    };
}