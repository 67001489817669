import React from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { selectCloseOpenGame, selectStatus, selectMasterCloseGame, selectCommandId } from '../../../store/selectors';
import './OpenClose.css';
import { handleSendMainCommands } from '../utils';

const OpenClose = () => {
    
    const closeGame = useSelector(selectCloseOpenGame);
    const currentStatus = useSelector(selectStatus);
    const masterCloseGame = useSelector(selectMasterCloseGame);
    const commandId = useSelector(selectCommandId);
    
    return (
        <div className="open_close_field" style={styles.open_close_field}>
            <Button
                onClick={() => {
                    handleSendMainCommands(commandId, 'command', closeGame, 0, Math.abs(currentStatus * 1), true, false, false, false, '', '', false)
                }}
                data-testid="button-open-close"
                data-cy="button-open-close"
                style={Object.assign({}, (closeGame === 'status_ok' ? (masterCloseGame ? styles.master_closed_game : styles.open_game) : styles.close_game))}
                variant="contained"
                disabled={masterCloseGame}
                className={closeGame === 'status_ok' ? 'to_open_game' : ''}
            >
                {closeGame === 'status_unsure' ? 'close game' : 'open game'}
            </Button>
        </div>
    )
}

let styles = {
    open_close_field: {
        height: '50%',
        //width: '50%'
    },
    close_game: {
        height: '100%',
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to top , #b50d13 75%, #fad0c4 99%, #fad0c4 100%)',
        class:'close'
    },
    open_game: {
        height: '100%',
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        class:'open'
    },
    master_closed_game: {
        height: '100%',
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to top, #555659 0%, #595a5e 100%)',
        class:'masterClosed',
        color: 'white'

    }
}

export default OpenClose;