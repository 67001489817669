import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import { createTheme } from '@material-ui/core/styles';
import { createTheme } from "@material-ui/core";
import { sendCommand } from '../CollectionTool.utils';
import {
    FormControl, Grid, Paper, Table, TableCell, TableContainer, TableHead,
    TableRow, TextField, ThemeProvider
} from '@material-ui/core';
import CommandList from './CommandList';
import DialogConfirmPopup from './DialogConfirmPopup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { sportID, allStatusesAvailable, activeStatuses, mediaButtonStatuses, q2_q4, /* objValueValueTextCommands */ } from './AmericanFootball';
import './SimpleTabs.css';
import SRTLineupTextSetField from './SRTLineupTextSetField'
import SRTMainButton from './SRTMainButton';
import { event_id } from '../../App';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
//import {handleSliderChange} from './Slider.utils';
import { useDispatch, useSelector } from "react-redux";
import {
    selectGameTimeState, selectCurrentGameInfoState, selectCloseOpenGame, selectAwayActiveTeam, selectHomeActiveTeam,
    selectStatus, selectTeamPossesion, selectUser, selectSportSuggestedStatus, selectCommandId, selectCommandInc,
    selectFeedCommandInc, selectEventInfo
} from '../../store/selectors';
import { setOverlayDisplay, setSportSuggestedStatus, setOpenConfirmPopUp } from '../../store/actions';
import { useSimpleTabsStyles } from './SimpleTabStyles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: any;
    classes: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, classes, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`updateEditCancelInfo-tabpanel-${index}`}
            aria-labelledby={`updateEditCancelInfo-tab-${index}`}
            className={classes.every_overlay}
            {...other}
        >
            {value === index && (
                <Box p={3} className={classes.paddingAndHeight}>
                    <Typography className={classes.TypographyDiv} component="div">
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ButtonInTabs = ({ children }) => {
    return <Button children={children} />;
};

export default function SimpleTabs({
    data, handleSendMainCommands, lock, popUpCommand, setPopUpCommand, commandLogArrays, sliderValue,
    setSliderValue, absoluteLeftProperty, setAbsoluteLeftProperty, sliderDirection }: any) {

    /* Take all the selectors from the store */
    //const player = useSelector(selectOnePlayer);
    const dispatch = useDispatch();
    const classes = useSimpleTabsStyles();

    const eventInfo = useSelector(selectEventInfo);
    const overtimePeriodDuration = eventInfo.overtime_period_duration === 15 ? 15 : 10;
    const gameTime = useSelector(selectGameTimeState);
    const isCloseOrOpenGame = useSelector(selectCloseOpenGame);
    const currentStatus = useSelector(selectStatus);
    const commandId = useSelector(selectCommandId);
    const commandInc = useSelector(selectCommandInc);
    const feedCommandInc = useSelector(selectFeedCommandInc);
    const {
        currentWeather,
        currentPreMatchInfo,
        currentWind, currentAttendance,
        currentTossWinner,
        currentHomeChoice,
        currentAwayChoice,
        currentHomeColor1,
        currentHomeColor2,
        currentAwayColor1,
        currentAwayColor2
    } = useSelector(selectCurrentGameInfoState);

    const homeActiveTeam = useSelector(selectHomeActiveTeam);
    const awayActiveTeam = useSelector(selectAwayActiveTeam);
    const suggestedStatusObject = useSelector(selectSportSuggestedStatus);
    const suggestedStatus = suggestedStatusObject.americanfootball;
    const teamPossesion = useSelector(selectTeamPossesion);
    const user = useSelector(selectUser);


    let start_date = [data?.startdate || "No start date Available"];

    /* used for changing directory of the field slider */
    const direction = createTheme({
        direction: sliderDirection
    });

    const [tabPanelValue, setTabPanelValue] = useState(0);

    const handleSliderChange = (event: any, newValue: number | number[] | number | string) => {
        setSliderValue(Number(newValue));

        if (newValue <= 100) {
            teamPossesion === 'home' ? setAbsoluteLeftProperty((Number(newValue) - 1.5)) : setAbsoluteLeftProperty(100 - (Number(newValue) + 1.5));
        }
        else {
            setAbsoluteLeftProperty(-1); /* move the slider to the beggining position of the field. To be outside the field */
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? 0 : parseInt(e.target.value);
        if (value >= 0 && value <= 100) {
            setSliderValue(value);

            if (teamPossesion === 'home') {
                setAbsoluteLeftProperty(value - 1.5);
            }
            else {
                setAbsoluteLeftProperty(100 - value - 1.5); /* place at mirror position , as we changed direction */
            }
        }
    };

    const handleBlur = () => {
        if (sliderValue < 0) {
            setSliderValue(0);
            setAbsoluteLeftProperty(-1);
        } else if (sliderValue > 100) {
            setSliderValue(100);
            setAbsoluteLeftProperty(100 - 1.5);
        }
    };

    const [premadeText, setPremadeText] = useState('');
    const [customText, setCustomText] = useState('');


    /* Hook for openning DIALOG for EDIT/CANCEL commands */
    const [open, setOpen] = useState(false);
    /* Hook to keep team value when pressing on EDIT button */
    const [team, setTeam] = useState('');
    /* Update the team HOOK when chaning from dropdown */
    const handleChangeTeam = (event) => {
        setTeam(event.target.value)
    }
    /* Hook for take the correct index(row) user click to delete */
    const [cancelEditProps, setCancelEditProps] = useState({
        type: 'edit',
        index: 0
    });

    /* Fill with correct statuses in the dropdown */
    const allStatusesNeeded = () => {
        var arr = [];
        let count = 1;
        for (let prop in allStatusesAvailable) {
            arr.push(<MenuItem key={count} value={Object.keys(allStatusesAvailable)[count - 1]}>{allStatusesAvailable[prop].title}</MenuItem>);
            count++;
        }
        return arr;
    }

    /* This func is used when user is in CANCEL/EDIT pop up and needs action to be taken (Deleting/Editing the command or closing the pop up) */
    const handleCloseCommandList = (type: string, confirmOrDelete: boolean, edit: any, index?: any,) => {
        /* close dialog pop up */
        setOpen(false);
        /* Send the correct team value when editting */

        const temp_command = commandLogArrays.edit_cancel_commands[cancelEditProps.index];
        let isModified = false;

        /* if commands need to be deleted or Confirmed we send the command with needed props/values */
        if (confirmOrDelete && index !== null) {
            /* edit/confirm the command */
            if (type === 'edit') {
                const temp_team = team === 'home' ? 1 : 2;
                if (temp_command.isConfirmed) {
                    isModified = true;
                    /* Take command team which we will EDIT */
                    //let commandTeam = temp_command.team;
                    //let otherTeam = commandTeam === 1 ? 2 : 1;

                    /* Sanity check for Invalid score - SRT-1308 */
                    // if (((score.current_score[otherTeam - 1] + 1 > 11 &&
                    // Math.abs((score.current_score[otherTeam - 1] + 1) - (score.current_score[commandTeam - 1] - 1)) > 2))) {
                    /* Open dialog pop up and Update the hook for invalidScore vision*/
                    // setOpen(true);
                    // setCancelEditProps({ type: 'invalidScore', index: index });
                    // }
                    // else {
                    //handleSendMainCommands(commandLogArrays.edit_cancel_commands[cancelEditProps.index].id, commandLogArrays.edit_cancel_commands[cancelEditProps.index].key, temp_team, commandLogArrays.edit_cancel_commands[cancelEditProps.index].status, commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled, true , isModified , commandLogArrays.edit_cancel_commands[cancelEditProps.index].text)
                    handleSendMainCommands(
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                        'command',
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                        temp_team,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                        true,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled,
                        isModified,
                        '',
                        '',
                        edit,
                        temp_command.feed?.pointNumber /* take pointNumber from the sent command feed object */
                    )
                    // }
                }
                else {
                    //handleSendMainCommands(commandLogArrays.edit_cancel_commands[cancelEditProps.index].id, commandLogArrays.edit_cancel_commands[cancelEditProps.index].key, temp_team, commandLogArrays.edit_cancel_commands[cancelEditProps.index].status, commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled, true , isModified , commandLogArrays.edit_cancel_commands[cancelEditProps.index].text)
                    handleSendMainCommands(
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                        'command',
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                        temp_team,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                        true,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled,
                        isModified,
                        '',
                        '',
                        edit,
                        temp_command.feed.pointNumber /* take pointNumber from the sent command feed object */
                    )
                }
            }

            /* Delete the command */
            else {
                //handleSendMainCommands(commandLogArrays.edit_cancel_commands[cancelEditProps.index].id, commandLogArrays.edit_cancel_commands[cancelEditProps.index].key, commandLogArrays.edit_cancel_commands[cancelEditProps.index].team, commandLogArrays.edit_cancel_commands[cancelEditProps.index].status, true, commandLogArrays.edit_cancel_commands[cancelEditProps.index].isConfirmed , isModified , commandLogArrays.edit_cancel_commands[cancelEditProps.index].text)
                handleSendMainCommands(
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                    'command',
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].team,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].isConfirmed,
                    true,
                    isModified,
                    'unknown',
                    temp_command.text,
                    edit
                )
            }
        }
    };

    /* This func is called when user click at the Cancel or Edit button in EDIT/Cancel tab and we parse index of the row  */
    const handleClickOpenCommandList = (type: string, index: any, teamAvailable) => {

        /* Update hook as we need props of the command which we want to Cancel/Edit */
        setPopUpCommand({
            key: commandLogArrays.edit_cancel_commands[index].key,
            team: commandLogArrays.edit_cancel_commands[index].team,
            confirmed: commandLogArrays.edit_cancel_commands[index].isConfirmed,
            id: commandLogArrays.edit_cancel_commands[index].id,
            modified: commandLogArrays.edit_cancel_commands[index].isModified,
            value: commandLogArrays.edit_cancel_commands[index].text,
            //type: /* commandLogArrays.edit_cancel_commands[index].type || */ '',
        });

        if (teamAvailable) {
            /* Take the team value when click on EDIT button + YES for the command in question */
            setTeam(commandLogArrays.edit_cancel_commands[index].team === 1 ? 'home' : 'away');
        }
        /* Open dialog pop up */
        setOpen(true);
        /* Update the hook to keep the correct props */
        setCancelEditProps({ type: type, index: index });
    };

    const populateDropdowns = (type, team) => {
        var arr = [];
        for (let index = 1; index <= 11; index++) {
            arr.push(
                <div key={'div-' + type + '-' + index} className={classes.maxHeight}>
                    <SRTLineupTextSetField order={index} type={type} team={team} handleClickOpenPopUp={handleClickOpenPopUp} />
                </div>
            )
        }
        return arr
    }


    /* When user click on MAIN Command buttons or Lineup SET button or STATUS button */
    const handleClickOpenPopUp = (key: string, team: number, confirmed: boolean, id: number, modified: boolean, valueObj?: object /* ,
        order?: number, lineUpType?: string, name?: string, teamType?: string, gameStatus?: string, 
        status?: string, type?: string, value?: any */) => {

        /* LINEUP OPTION */
        if (key === 'player') {
            dispatch(setOpenConfirmPopUp({ type: 'player', open: true, cancel_yes: 'block', ok: 'none' }));
        }

        /* MAIN BUTTONS OPTION */
        else {
            /* Show uncf button ONLY for touchdown Pop up */
            if (key === 'touchdown') {
                dispatch(setOpenConfirmPopUp({ type: key, open: true, cancel_yes: 'block', ok: 'block' }));
            }
            else {
                dispatch(setOpenConfirmPopUp({ type: key, open: true, cancel_yes: 'block', ok: 'none' }));
            }
        }
        setPopUpCommand({ key, team, confirmed, id, modified, valueObj });
        /* setPopUpCommand({ 
            key, team, confirmed, id, modified, value, valueObj[order], lineUpType, name, teamType, gameStatus, status 
        }); */
    };


    /* With this func , as we are in the POP UP , we send cancelled or confirmed command/lineup  */
    const handleClosePopUp = (canceled_or_confirmed: boolean, type, typeName) => {

        dispatch(setOpenConfirmPopUp({ type: 'confirm', open: false, cancel_yes: 'none', ok: 'block' }));

        /* Cancel/close the command pop up */
        if (canceled_or_confirmed) {
            /* If we cancel the line up pop , then we do not need command to be sent */
            if (type !== 'player') {
                handleSendMainCommands(commandId, 'command', popUpCommand.key, popUpCommand.team, Math.abs(currentStatus * 1), popUpCommand.confirmed, canceled_or_confirmed, popUpCommand.modified, '', '', false);
            }
        }
        /* Confirmed command */
        else {
            /* related to lineup commands */
            if (popUpCommand.key === 'player') {
                /* sanity check to have required parameters for name and position */
                if (popUpCommand.valueObj?.name && popUpCommand.valueObj?.name !== '' && popUpCommand.valueObj?.position && popUpCommand.valueObj?.position !== '') {
                    handleSendMainCommands(commandId, 'command', popUpCommand.key, popUpCommand.team, Math.abs(currentStatus * 1),
                        true, canceled_or_confirmed, popUpCommand.modified, '',
                        ({ player: popUpCommand.valueObj?.name || '', order: popUpCommand.valueObj?.order, team_type: popUpCommand.valueObj?.type, position: popUpCommand.valueObj?.position }),
                        false);
                }
            }
            else {
                /* related to all main page buttons */
                // switch (popUpCommand.key) {
                //     case 'activeTeam':
                //         handleSendMainCommands(commandId, 'command', popUpCommand.key, popUpCommand.team, Math.abs(currentStatus * 1), true, canceled_or_confirmed, popUpCommand.modified, '', { teamType: popUpCommand.teamType }, false);
                //         break;

                //     default:
                //         handleSendMainCommands(commandId, 'command', popUpCommand.key, popUpCommand.team, Math.abs(currentStatus * 1), true, canceled_or_confirmed, popUpCommand.modified, '', {}, false);
                //         break;
                // }
                console.log('popUpCommand', popUpCommand);
                handleSendMainCommands(
                    commandId,
                    'command',
                    popUpCommand.key,
                    popUpCommand.team,
                    /* Take new timeout which was selected from the pop up dropdown or the status we are in */
                    popUpCommand.valueObj?.timeoutStatus || Math.abs(currentStatus * 1), 
                    true,
                    canceled_or_confirmed,
                    popUpCommand.modified,
                    '',
                    popUpCommand.valueObj,
                    false);

            }
        }
    };

    /* Change tabs (UPDATES , EDIT/CANDEL , GAME INFO ) with this func */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabPanelValue(newValue);
    };

    /* update hooks for GAME INFO / PRE MATCH related commands */
    const handleSelect = (event) => {
        /* Send preMatchInfo confirmed command with the value taken fron the 'event.target.value' */
        handleSendMainCommands(commandId, 'command', event.target.name, 0, Math.abs(currentStatus * 1), true, false, false, 'gameInfo', event.target.value, false)
    };

    /* With chaning status from dropdown we need to update it */
    const handleChangeStatus = (event) => {
        dispatch(setSportSuggestedStatus({americanfootball: event.target.value}));
    }

    const handleChangeText = (type: string) => (event) => {
        type === 'Custom' ? setCustomText(event.target.value) : setPremadeText(event.target.value);
    }

    let teams = [data ? data.team_home : "No Team 1 Info", data ? data.team_away : "No Team 2 Info"];

    useEffect(() => {
        if (currentStatus !== 1) {
            dispatch(setSportSuggestedStatus({americanfootball: allStatusesAvailable[currentStatus].nextStatus}));
        }
        // eslint-disable-next-line 
    }, [currentStatus]);

    const handleSendStatus = () => {

        let feed = {
            "matchStatus": allStatusesAvailable[suggestedStatus].msfName || "Unknown",
            "sequence": feedCommandInc,
            "isConfirmed": true,
            "utcTimestamp": "2021-10-05T09:48:20.397Z"
        };

        let obj = {
            type: "command",
            page: "desktop",
            value: {
                "id": commandId,
                //"key": (suggestedStatus * 1) < 100 ? ((suggestedStatus * 1) >= 0 ? 'gamestart' : 'gamefinish') : 'matchstatus',
                "key": 'status',
                "team": 0,
                "isConfirmed": true,
                "gameNumber": Math.abs(suggestedStatus * 1),
                "event_id": event_id,
                "sequence": commandInc,
                "UTCtimestamp": new Date().toISOString(),
                "sport_id": sportID,
                "gameTime": suggestedStatus < 0 ? gameTime : (Math.abs(suggestedStatus * 1) < 34 ? 15 * 60 : overtimePeriodDuration * 60),
                "feed": feed,
                "feedAction": 'MatchStatus'
            },
            user: user
        };

        /* Show overlay pop up to prevent adding other commands before this one is processed */
        dispatch(setOverlayDisplay('block'));
        sendCommand(obj);
    }

    return (
        <div id="simpleTabsWrapper" className={classes.root}>

            <AppBar id="updatesEditCancelGameInfo_appbar" position="static" className={classes.updatesEditCancelGameInfo_appbar}>
                <Tabs className="tabsWrapper" value={tabPanelValue} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Updates" {...a11yProps(0)} />
                    <Tab label="Edit/Cancel" {...a11yProps(1)} />
                    <Tab label="Game Info" {...a11yProps(2)} />
                    <Tab disabled={true} label="Lineups" {...a11yProps(3)} />
                    <ButtonInTabs>
                        <div
                            style={Object.assign({ display: activeStatuses.includes(currentStatus) ? 'block' : 'none' }, styles.home_away_kickoff)}
                            className="home_kickoff_button"
                            onClick={() => {
                                /* the initial sent command should be uncf  and pop up will be opened */
                                handleClickOpenPopUp("kickoff", 1, false, commandId, false);
                                /* send uncf command */
                                handleSendMainCommands(commandId, 'command', 'kickoff', 1, Math.abs(currentStatus * 1), false, false, false, '', '', false);
                            }}>
                            Home KickOff
                        </div>
                    </ButtonInTabs>
                    <ButtonInTabs>
                        <div
                            style={Object.assign({ display: activeStatuses.includes(currentStatus) ? 'block' : 'none' }, styles.home_away_kickoff)}
                            className="away_kickoff_button"
                            onClick={() => {
                                /* the initial sent command should be uncf  and pop up will be opened */
                                handleClickOpenPopUp("kickoff", 2, false, commandId, false);
                                /* send uncf command */
                                handleSendMainCommands(commandId, 'command', 'kickoff', 2, Math.abs(currentStatus * 1), false, false, false, '', '', false);
                            }}>
                            Away KickOff
                        </div>
                    </ButtonInTabs>
                </Tabs>
            </AppBar>

            <TabPanel className={classes.everyUpTab} value={tabPanelValue} index={0} classes={classes}>

                <div className={classes.home_away_participants_field}>
                    <Paper elevation={3} className={classes.paper}>
                        <div className={classes.participant_field}>{teams[1]}</div>
                    </Paper>

                    <div className={classes.set_status_wrapper}>
                        <div className={classes.set_status_field}>
                            <form autoComplete="off" className={classes.set_status}>
                                <FormControl id="select_status_change" className={classes.formControl}>
                                    <InputLabel id="label_status">Status</InputLabel>

                                    <Select labelId="label" id="status" value={suggestedStatus} onChange={handleChangeStatus} MenuProps={{ classes: { paper: classes.select } }}>
                                        {allStatusesNeeded()}
                                    </Select>
                                </FormControl>
                            </form>
                            <div className={classes.setStatus_standby_field}>
                                <SRTMainButton text={'Set Status'} commandKey={'setStatus'} team={0} popUp={true} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                <SRTMainButton text={'Standby'} commandKey={'standby'} team={0} popUp={false} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                            </div>
                        </div>


                    </div>
                    <Paper elevation={3} className={classes.paper}>
                        <div className={classes.participant_field}>{teams[0]}</div>
                    </Paper>
                </div>

                <div className={classes.gap}></div>

                <div className={classes.home_away_buttons}>
                    <div className={lock.display === 'none' ? classes.lock_overlay_field_none : classes.lock_overlay_field_block}></div>

                    {/* AWAY field buttons */}
                    <div className={classes.field_buttons}>

                        <div className={classes.selectActiveTeamField}>
                            {/* <Paper elevation={5} className={classes.paper}> */}
                            <div className={classes.teamAbbreviation}>AWAY</div>
                            {/* </Paper> */}

                            <div className={awayActiveTeam.startsWith("Please") ? classes.selectActiveTeamGrey : classes.selectActiveTeamGreen}>
                                <span>
                                    {awayActiveTeam.toUpperCase()}
                                </span>
                            </div>
                        </div>

                        <div className={classes.upper_team_buttons}>
                            <div className={classes.buttonRow}>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'TOUCHDOWN'} commandKey={'touchdown'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'1P CON.ATTEMPT'} commandKey={'onePointConversionAttempt'} team={2} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'1P.MADE'} commandKey={'onePointConversionMade'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'1P.MISSED'} commandKey={'onePointConversionMissed'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'SAFETY'} commandKey={'safety'} team={2} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'2P CON.ATTEMPT'} commandKey={'twoPointsConversionAttempt'} team={2} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'2P.MADE'} commandKey={'twoPointsConversionMade'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'2P.MISSED'} commandKey={'twoPointsConversionMissed'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        {/* empty */}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'F.GOAL ATTEMPT'} commandKey={'fieldGoalAttempt'} team={2} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'FG.MADE'} commandKey={'fieldGoalMade'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'FG.MISSED'} commandKey={'fieldGoalMissed'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        {/* empty */}
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'DOWN'} commandKey={'down'} team={2} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'SNAP'} commandKey={'snap'} team={2} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'POSSESSION'} commandKey={'possession'} team={2} popUp={false} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div className={classes.gap}></div>
                        <div className={classes.down_team_buttons}>
                            <div>
                                <Grid container spacing={3} >
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'TIMEOUT'} commandKey={'timeout'} team={2} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'CHALLENGE'} commandKey={'challenge'} team={2} popUp={true} disabled={(mediaButtonStatuses.includes(currentStatus) ? false : true) && !q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'PENALTY'} commandKey={'penalty'} team={2} popUp={true} disabled={(mediaButtonStatuses.includes(currentStatus) ? false : true) && !q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'ACTIVE TEAM'} commandKey={'activeTeam'} team={2} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.gap}></div>
                    </div>

                    <div className={classes.both_team_buttons}>
                        <SRTMainButton text={'REPLAY REVIEW'} commandKey={'replayReview'} team={0} popUp={true} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />

                        <div className={classes.fieldSliderField}>
                            <div className={classes.fieldImage} style={Object.assign({backgroundImage: "url(images/amf_field.png)"})}>
                                <div className={classes.fieldSlider} style={Object.assign({ display: teamPossesion === 'none' ? 'none' : 'block' }, { left: absoluteLeftProperty + '%' })}></div>
                                <div className={classes.fieldImageDirection} style={Object.assign({ display: teamPossesion === 'home' ? 'block' : 'none', backgroundImage: "url(images/arrow_left.png)" })}></div>
                                <div className={classes.fieldImageDirection} style={Object.assign({ display: teamPossesion === 'away' ? 'block' : 'none', backgroundImage: "url(images/arrow_right.png)" })}></div>
                            </div>

                            <div className={classes.yardSlider}>
                                <Grid container spacing={3} alignItems="baseline">
                                    <Grid item xs className={`${classes.sliderThumb}`}>
                                        <ThemeProvider theme={direction}>
                                            <Slider
                                                //marks
                                                //track="inverted"
                                                //valueLabelDisplay="on"
                                                disabled={teamPossesion !== 'none' ? false : true}
                                                value={typeof sliderValue === 'number' ? sliderValue : 50}
                                                onChange={handleSliderChange}
                                                aria-labelledby="input-slider"
                                                min={0}
                                                max={100}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={classes.yardsInputField}>
                                <Button
                                    disabled={teamPossesion !== 'none' && sliderValue >= 0 && sliderValue <= 100 ? false : true}
                                    variant="contained"
                                    className={classes.yardsButton}
                                    //color="primary"
                                    size="small"
                                    //className={classes.btnMargin}
                                    onClick={() => {
                                        let tempTeam = teamPossesion === 'home' ? 1 : 2;
                                        handleSendMainCommands(commandId, 'command', 'yards', tempTeam, Math.abs(currentStatus * 1), true, false, false, '', '', false);
                                    }}
                                >
                                    {teamPossesion !== 'none' ? teamPossesion + ' yards' : 'yards'}
                                </Button>

                                <Grid item>
                                    <Input
                                        disabled={teamPossesion !== 'none' ? false : true}
                                        className={classes.input}
                                        value={sliderValue/*  || '' */}
                                        margin="dense"
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                        inputProps={{
                                            className: classes.inputProps,
                                            step: 10,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </Grid>
                            </div>
                        </div>

                        <SRTMainButton text={isCloseOrOpenGame === 'status_unsure' ? 'CLOSE GAME' : 'OPEN GAME'} commandKey={isCloseOrOpenGame} team={0} popUp={false} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />

                        <SRTMainButton text={'LOST COMMUNICATION'} commandKey={'lostCommunication'} team={0} popUp={false} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />

                        <div className={classes.fullWidth} >
                            {/* Button TAKE CONTROL/RELEASE CONTROL will change states same as CLOSE/OPEN game */}
                            {/* We use hook 'lock' to keep all the props related to this button */}
                            <Button
                                /* we use lock props for backgroundColor and Z-index as we need to hide button when in RESET pop up (as reset pop up package has zIndex=99 by default ) */
                                style={Object.assign({ backgroundImage: lock.color, zIndex: lock.display === 'none' ? 1 : 1001 }, styles.buttons_border_radius, styles.fullWidth)}
                                variant="contained"
                                onClick={() => {
                                    handleSendMainCommands(commandId, 'setting', lock.key, 0, Math.abs(currentStatus * 1), true, false, false, '', '', false)
                                }}
                            >
                                {lock.text}
                            </Button>
                        </div>

                    </div>

                    {/* HOME field buttons */}
                    <div className={classes.field_buttons}>
                        <div className={classes.selectActiveTeamField}>
                            <div className={homeActiveTeam.startsWith("Please") ? classes.selectActiveTeamGrey : classes.selectActiveTeamGreen }>
                                <span>
                                    {homeActiveTeam.toUpperCase()}
                                </span>
                            </div>
                            {/* <Paper elevation={5} className={classes.paper}> */}
                            <div className={classes.teamAbbreviation}>HOME</div>
                            {/* </Paper> */}
                        </div>

                        <div className={classes.upper_team_buttons}>
                            <div className={classes.buttonRow}>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'1P.MISSED'} commandKey={'onePointConversionMissed'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'1P.MADE'} commandKey={'onePointConversionMade'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'1P CON.ATTEMPT'} commandKey={'onePointConversionAttempt'} team={1} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'TOUCHDOWN'} commandKey={'touchdown'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'2P.MISSED'} commandKey={'twoPointsConversionMissed'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'2P.MADE'} commandKey={'twoPointsConversionMade'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'2P CON.ATTEMPT'} commandKey={'twoPointsConversionAttempt'} team={1} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'SAFETY'} commandKey={'safety'} team={1} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'FG.MISSED'} commandKey={'fieldGoalMissed'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'FG.MADE'} commandKey={'fieldGoalMade'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <SRTMainButton text={'F.GOAL ATTEMPT'} commandKey={'fieldGoalAttempt'} team={1} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* empty */}
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'POSSESSION'} commandKey={'possession'} team={1} popUp={false} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SRTMainButton text={'SNAP'} commandKey={'snap'} team={1} popUp={false} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <SRTMainButton text={'DOWN'} commandKey={'down'} team={1} popUp={true} disabled={true} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        {/* empty */}
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                        <div className={classes.gap}></div>
                        <div className={classes.down_team_buttons}>
                            <div>
                                <Grid container spacing={3} >
                                    <Grid item xs={3}>
                                        <SRTMainButton text={'ACTIVE TEAM'} commandKey={'activeTeam'} team={1} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'PENALTY'} commandKey={'penalty'} team={1} popUp={true} disabled={(mediaButtonStatuses.includes(currentStatus) ? false : true) && !q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'CHALLENGE'} commandKey={'challenge'} team={1} popUp={true} disabled={(mediaButtonStatuses.includes(currentStatus) ? false : true) && !q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SRTMainButton text={'TIMEOUT'} commandKey={'timeout'} team={1} popUp={true} disabled={!q2_q4.includes(currentStatus)} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.gap}></div>
                    </div>
                </div>

                <div className={classes.comments}>

                    <div className={classes.predefined_field}>
                        <form autoComplete="off" className={classes.form_root}>
                            <FormControl id="select_premade_comment" className={classes.formControl}>
                                <InputLabel htmlFor="comment-simple">{('*predefined comment')}</InputLabel>
                                <Select
                                    value={premadeText}
                                    onChange={handleChangeText('Premade')}
                                    inputProps={{
                                        name: 'premade_comment'
                                    }}
                                >
                                    <MenuItem value={"TV Timeout"}>{"TV Timeout"}</MenuItem>
                                    <MenuItem value={"2-Minute Warning"}>{"2-Minute Warning"}</MenuItem>
                                    <MenuItem value={"Home team Player injured"}>{"Home team Player injured"}</MenuItem>
                                    <MenuItem value={"Away team Player injured"}>{"Away team Player injured"}</MenuItem>
                                    <MenuItem value={"Water drinking break"}>{"Water drinking break"}</MenuItem>
                                    <MenuItem value={"Play stopped due to serious injury"}>{"Play stopped due to serious injury"}</MenuItem>
                                    <MenuItem value={"Game interrupted due to electricity/light problems"}>{"Game interrupted due to electricity/light problems"}</MenuItem>
                                    <MenuItem value={"Game interrupted due to violence between the fans"}>{"Game interrupted due to violence between the fans"}</MenuItem>
                                    <MenuItem value={"Violence between fans and players"}>{"Violence between fans and players"}</MenuItem>
                                    <MenuItem value={"Violence between players"}>{"Violence between players"}</MenuItem>
                                    <MenuItem value={"Violence between fans"}>{"Violence between fans"}</MenuItem>
                                    <MenuItem value={"Terrorist act"}>{"Terrorist act"}</MenuItem>
                                    <MenuItem value={"Game delayed due to rain"}>{"Game delayed due to rain"}</MenuItem>
                                    <MenuItem value={"Game delayed due to weather"}>{"Game delayed due to weather"}</MenuItem>
                                    <MenuItem value={"Game delayed due to stadium conditions"}>{"Game delayed due to stadium conditions"}</MenuItem>
                                    <MenuItem value={"Game resumed"}>{"Game resumed"}</MenuItem>
                                    <MenuItem value={"Home team coach sent off"}>{"Home team coach sent off"}</MenuItem>
                                    <MenuItem value={"Away team coach sent off'"}>{"Away team coach sent off'"}</MenuItem>
                                    <MenuItem value={"Home Team 1 point safety"}>{"Home Team 1 point safety"}</MenuItem>
                                    <MenuItem value={"Away Team 1 point safety"}>{"Away Team 1 point safety"}</MenuItem>
                                </Select>
                            </FormControl>
                        </form>
                        <Button
                            id="send_premade_comment"
                            variant="outlined"
                            color="primary"
                            size="small"

                            className="send_premade_comment"
                            onClick={() => {
                                if (premadeText === '') {
                                    return;
                                } else {
                                    handleSendMainCommands(commandId, 'command', 'comment', 0, Math.abs(currentStatus * 1), true, false, false, 'premade', premadeText, false);
                                    setPremadeText('');
                                }
                            }}
                        >
                            {('send')}
                        </Button>
                    </div>

                    <div className={classes.contolled_by}
                        style={Object.assign({}, { display: lock.user ? 'flex' : 'none' })}>
                        <Typography
                            color={'primary'}
                            align="center"
                            variant='h5'
                        //style={{fontSize: '1.7em'}}
                        >
                            Controlled by: {lock.user}
                        </Typography>
                    </div>

                    <div className={classes.all_texts}>
                        <form autoComplete="off" className={classes.form_root}>
                            <FormControl id="input_custom_comment" className={classes.formControl}>
                                <TextField
                                    label={('*custom comment')}
                                    value={customText}
                                    onChange={handleChangeText('Custom')}
                                    inputProps={{
                                        name: 'custom_comment'
                                    }} />
                            </FormControl>
                        </form>
                        <Button
                            id="send_custom_comment"
                            variant="outlined"
                            color="primary"
                            size="small"
                            className="send_custom_comment"
                            onClick={() => {
                                /* do not send empty comments */
                                if (customText === '') {
                                    return;
                                } else {
                                    handleSendMainCommands(commandId, 'command', 'comment', 0, Math.abs(currentStatus * 1), true, false, false, 'custom', customText, false);
                                    setCustomText('');
                                }
                            }}
                        >
                            {('send')}
                        </Button>

                    </div>

                </div>
            </TabPanel>

            <TabPanel className={classes.everyUpTab} value={tabPanelValue} index={1} classes={classes}>
                <CommandList mapCommandLog={commandLogArrays.map_command_log} commandLogArray={commandLogArrays.edit_cancel_commands}
                    deletedCommandIds={commandLogArrays.deleted_commands_ids} showButtons={true}
                    tab={'editCancel'} handleClickOpenCommandList={handleClickOpenCommandList} team={team}
                    handleChangeTeam={handleChangeTeam} open={open} /* setOpen={setOpen}  */ cancelEditProps={cancelEditProps}
                    setCancelEditProps={setCancelEditProps} 
                />
            </TabPanel>

            <TabPanel className={classes.everyUpTab} value={tabPanelValue} index={2} classes={classes}>
                <div className={classes.game_info_field}>

                    {/* <div className={classes.game_info_header}>
                        <div>Game Info</div>
                    </div> */}

                    <div className={classes.game_info}>
                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Weather</InputLabel>
                                <Select
                                    //labelId="pre-match-native-label"
                                    value={currentWeather || ""}
                                    name="weather"
                                    inputProps={{
                                        id: 'weather',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value="sun">Sun</MenuItem>
                                    <MenuItem value="clouds">Clouds</MenuItem>
                                    <MenuItem value="rain">Rain</MenuItem>
                                    <MenuItem value="snow">Snow</MenuItem>
                                    <MenuItem value="fog">Fog</MenuItem>
                                    <MenuItem value="roof">Roof</MenuItem>
                                    <MenuItem value="night">Night</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Wind</InputLabel>
                                <Select
                                    //labelId="pre-match-native-label"
                                    value={currentWind || ""}
                                    name="wind"
                                    inputProps={{
                                        id: 'wind',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value="windy">Windy</MenuItem>
                                    <MenuItem value="no wind">No wind</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Pre Match Info</InputLabel>
                                <Select
                                    //labelId="pre-match-native-label"
                                    value={currentPreMatchInfo || ""}
                                    name="preMatchInfo"
                                    inputProps={{
                                        id: 'pre-match',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value="Players not on the court">Players NOT on the court</MenuItem>
                                    <MenuItem value="Players on the court">Players on the court</MenuItem>
                                    <MenuItem value="Players are warming up">Players are warming up</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Attendance</InputLabel>
                                <Select
                                    value={currentAttendance || ""}
                                    name="attendance"
                                    inputProps={{
                                        id: 'attendance',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'0%'}>0%</MenuItem>
                                    <MenuItem value={'50%'}>50%</MenuItem>
                                    <MenuItem value={'100%'}>100%</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_colors_divs}>
                            <FormControl className={classes.colorsField}>
                                <InputLabel>Home Color 1</InputLabel>
                                <Select
                                    value={currentHomeColor1 || ""}
                                    name="home_color_1"
                                    inputProps={{
                                        id: 'homeColor1',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'white'}>White</MenuItem>
                                    <MenuItem value={'black'}>Black</MenuItem>
                                    <MenuItem value={'red'}>Red</MenuItem>
                                    <MenuItem value={'blue'}>Blue</MenuItem>
                                    <MenuItem value={'green'}>Green</MenuItem>
                                    <MenuItem value={'yellow'}>Yellow</MenuItem>
                                    <MenuItem value={'orange'}>Orange</MenuItem>
                                    <MenuItem value={'purple'}>Purple</MenuItem>
                                    <MenuItem value={'pink'}>Pink</MenuItem>
                                    <MenuItem value={'brown'}>Brown</MenuItem>
                                    <MenuItem value={'gray'}>Gray</MenuItem>
                                    <MenuItem value={'lightgreen'}>Lightgreen</MenuItem>
                                    <MenuItem value={'skyblue'}>Skyblue</MenuItem>
                                    <MenuItem value={'burgundy'}>Burgundy</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl className={classes.colorsField}>
                                <InputLabel>Home Color 2</InputLabel>
                                <Select
                                    value={currentHomeColor2 || ""}
                                    name="home_color_2"
                                    inputProps={{
                                        id: 'homeColor2',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'white'}>White</MenuItem>
                                    <MenuItem value={'black'}>Black</MenuItem>
                                    <MenuItem value={'red'}>Red</MenuItem>
                                    <MenuItem value={'blue'}>Blue</MenuItem>
                                    <MenuItem value={'green'}>Green</MenuItem>
                                    <MenuItem value={'yellow'}>Yellow</MenuItem>
                                    <MenuItem value={'orange'}>Orange</MenuItem>
                                    <MenuItem value={'purple'}>Purple</MenuItem>
                                    <MenuItem value={'pink'}>Pink</MenuItem>
                                    <MenuItem value={'brown'}>Brown</MenuItem>
                                    <MenuItem value={'gray'}>Gray</MenuItem>
                                    <MenuItem value={'lightgreen'}>Lightgreen</MenuItem>
                                    <MenuItem value={'skyblue'}>Skyblue</MenuItem>
                                    <MenuItem value={'burgundy'}>Burgundy</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_colors_divs}>
                            <FormControl className={classes.colorsField}>
                                <InputLabel>Away Color 1</InputLabel>
                                <Select
                                    value={currentAwayColor1 || ""}
                                    name="away_color_1"
                                    inputProps={{
                                        id: 'awayColor1',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'white'}>White</MenuItem>
                                    <MenuItem value={'black'}>Black</MenuItem>
                                    <MenuItem value={'red'}>Red</MenuItem>
                                    <MenuItem value={'blue'}>Blue</MenuItem>
                                    <MenuItem value={'green'}>Green</MenuItem>
                                    <MenuItem value={'yellow'}>Yellow</MenuItem>
                                    <MenuItem value={'orange'}>Orange</MenuItem>
                                    <MenuItem value={'purple'}>Purple</MenuItem>
                                    <MenuItem value={'pink'}>Pink</MenuItem>
                                    <MenuItem value={'brown'}>Brown</MenuItem>
                                    <MenuItem value={'gray'}>Gray</MenuItem>
                                    <MenuItem value={'lightgreen'}>Lightgreen</MenuItem>
                                    <MenuItem value={'skyblue'}>Skyblue</MenuItem>
                                    <MenuItem value={'burgundy'}>Burgundy</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl className={classes.colorsField}>
                                <InputLabel>Away Color 2</InputLabel>
                                <Select
                                    value={currentAwayColor2 || ""}
                                    name="away_color_2"
                                    inputProps={{
                                        id: 'awayColor2',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'white'}>White</MenuItem>
                                    <MenuItem value={'black'}>Black</MenuItem>
                                    <MenuItem value={'red'}>Red</MenuItem>
                                    <MenuItem value={'blue'}>Blue</MenuItem>
                                    <MenuItem value={'green'}>Green</MenuItem>
                                    <MenuItem value={'yellow'}>Yellow</MenuItem>
                                    <MenuItem value={'orange'}>Orange</MenuItem>
                                    <MenuItem value={'purple'}>Purple</MenuItem>
                                    <MenuItem value={'pink'}>Pink</MenuItem>
                                    <MenuItem value={'brown'}>Brown</MenuItem>
                                    <MenuItem value={'gray'}>Gray</MenuItem>
                                    <MenuItem value={'lightgreen'}>Lightgreen</MenuItem>
                                    <MenuItem value={'skyblue'}>Skyblue</MenuItem>
                                    <MenuItem value={'burgundy'}>Burgundy</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Toss Winner</InputLabel>
                                <Select
                                    value={currentTossWinner || ""}
                                    name="tossWinner"
                                    inputProps={{
                                        id: 'tossWinner',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'home'}>Home</MenuItem>
                                    <MenuItem value={'away'}>Away</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Home choice</InputLabel>
                                <Select
                                    value={currentHomeChoice || ""}
                                    name="homeChoice"
                                    inputProps={{
                                        id: 'homeChoice',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'kickoff'}>Kickoff</MenuItem>
                                    <MenuItem value={'receive'}>Receive</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.game_info_child_divs}>
                            <FormControl className={classes.otherFormControl}>
                                <InputLabel>Away choice</InputLabel>
                                <Select
                                    value={currentAwayChoice || ""}
                                    name="awayChoice"
                                    inputProps={{
                                        id: 'awayChoice',
                                    }}
                                    onChange={(e) => {
                                        handleSelect(e);
                                    }}
                                >
                                    <MenuItem value={'kickoff'}>Kickoff</MenuItem>
                                    <MenuItem value={'receive'}>Receive</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <div className={classes.game_info_child_divs}>
                            <form className={classes.otherFormControl} noValidate autoComplete="off">
                                <TextField disabled id="standard-basic" label={start_date} helperText="Match Start Date" />
                            </form>
                        </div>

                    </div>
                </div>
            </TabPanel>

            <TabPanel className={classes.everyUpTab} value={tabPanelValue} index={3} classes={classes}>
                <div className={classes.lineUpWrapperField}>
                    {/* AWAY flex field */}
                    <div className={classes.homeAwayLineupField}>
                        <h3 style={{ margin: '0%' }}>{'AWAY - ' + teams[1]}</h3>
                        {/* Sticky Headers */}
                        <TableContainer component={Paper} /* className={classes.container} */>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <TableCell>Offense</TableCell>
                                        <TableCell>Defense</TableCell>
                                        <TableCell>Special Team</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <div className={classes.homeAwayPlayersField}>
                            {/* Away Offence players */}
                            <div /* style={{ width: '33%' , height: '100%'}} */>
                                {populateDropdowns('offensive', 2)}
                            </div>
                            {/* Away Defence players */}
                            <div /* style={{ width: '33%' , height: '100%'}} */>
                                {populateDropdowns('defensive', 2)}
                            </div>
                            {/* Away SpecialTeam players */}
                            <div /* style={{ width: '33%' , height: '100%'}} */>
                                {populateDropdowns('special', 2)}
                            </div>
                        </div>

                    </div>
                    {/* HOME flex field */}
                    <div className={classes.homeAwayLineupField}>
                        <h3 style={{ margin: '0%' }}>{'HOME - ' + teams[0]}</h3>
                        {/* Sticky Headers */}
                        <TableContainer component={Paper} >
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <TableCell>Offense</TableCell>
                                        <TableCell>Defense</TableCell>
                                        <TableCell>Special Team</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <div className={classes.homeAwayPlayersField}>
                            {/* Home Offence players */}
                            <div /* style={{ width: '33%' }} */>
                                {populateDropdowns('offensive', 1)}
                            </div>
                            {/* Home defensive players */}
                            <div /* style={{ width: '33%' }} */>
                                {populateDropdowns('defensive', 1)}
                            </div>
                            {/* Home SpecialTeam players */}
                            <div /* style={{ width: '33%' }} */>
                                {populateDropdowns('special', 1)}
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>

            {/* Dialog pop up which will be open for Edit or Cancel command */}
            <Dialog
                PaperProps={{
                    style: {
                        //backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                open={open}
                BackdropProps={{ style: { opacity: 0.1, whiteSpace: 'pre-line' } }}
                //disableBackdropClick={true}
                //onClose={() => handleClosePopUp(false)}
                aria-labelledby="alert-dialog-title-command-list"
                aria-describedby="alert-dialog-description-command-list"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-command-list">
                        {cancelEditProps.type === 'cancel' ?
                            'Are you sure you want to DELETE '
                            :
                            cancelEditProps.type === 'edit' ? 'Edit  ' : 'Invalid Score!'
                        }

                        {
                            cancelEditProps.type === 'invalidScore' ? null :
                                "#" + commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.id + " " +
                                (commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team === 1 ? 'Home' :
                                    commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team === 0 || !commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team ? ''
                                        :
                                        'Away') + " " +
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.key + "?"
                        }
                    </DialogContentText>

                    {/* Show/hide team dropdown depends on the type of the popup (Cancel or Edit) */}
                    {
                        cancelEditProps.type === 'cancel' || cancelEditProps.type === 'invalidScore' ?
                            null
                            :
                            <form autoComplete="off" className={classes.form_root}>
                                <FormControl id="team_formControl" className={classes.formControl}>
                                    <InputLabel htmlFor="select_team_command_list">{('*TEAM')}</InputLabel>
                                    <Select
                                        id="select_team_command_list"
                                        value={team}
                                        onChange={handleChangeTeam}
                                    >
                                        <MenuItem value="home">Home</MenuItem>
                                        <MenuItem value="away">Away</MenuItem>
                                    </Select>
                                </FormControl>
                            </form>
                    }
                </DialogContent>

                {
                    cancelEditProps.type === 'invalidScore' ?
                        <DialogActions>
                            <Button onClick={() => {
                                handleCloseCommandList(cancelEditProps.type, false, null, null)
                            }}
                                variant="contained"
                                color="secondary">
                                OK
                            </Button>
                        </DialogActions>

                        :

                        <DialogActions>
                            <Button onClick={() => {
                                handleCloseCommandList(cancelEditProps.type, false, null, null)
                            }}
                                variant="contained"
                                color="secondary">
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    handleCloseCommandList(cancelEditProps.type, true, 'edit', cancelEditProps.index)
                                }}
                                variant="contained"
                                color="primary"
                                autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                }
            </Dialog>

            <DialogConfirmPopup handleClosePopUp={handleClosePopUp} popUpCommand={popUpCommand} setPopUpCommand={setPopUpCommand} handleSendStatus={handleSendStatus} />

        </div>
    );
}

let styles = {

    home_away_kickoff: {
        borderRadius: '5px',
        border: '1px solid black',
        padding: '0 2%',
        //backgroundImage: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)',
        //backgroundImage: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        backgroundColor: '#002984',
        color: 'white',
        fontSize: "1vw",
    },
    buttons_border_radius: {
        borderRadius: '5px',
        //border : 1,
    },
    fullWidth: {
        width: '100%'
    }
}