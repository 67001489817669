import React from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { selectStatus, selectIsDisabledTakenControl, selectCommandId, selectLock } from '../../../store/selectors';
import { handleSendMainCommands } from '../utils';
import cloneDeep from 'lodash.clonedeep';

const TakeReleaseControl = () => {
    const currentStatus = useSelector(selectStatus);
    const isDisabled = useSelector(selectIsDisabledTakenControl);
    const commandId = useSelector(selectCommandId);
    const lock = cloneDeep(useSelector(selectLock));

    return (
        <div className="take_release_field" style={styles.open_close_field} >
            {/* Button TAKE CONTROL/RELEASE CONTROL will change states same as CLOSE/OPEN game */}
            {/* We use hook 'lock' to keep all the props related to this button */}
            <Button
                /* we use lock props for backgroundColor and Z-index as we need to hide button when in RESET pop up (as reset pop up package has zIndex=99 by default ) */
                style={Object.assign({ backgroundImage: lock.color, zIndex: lock.display === 'none' ? 1 : 1001 }, styles.buttons_border_radius /* , styles.lock */)}
                variant="contained"
                data-cy="button-control"
                data-testid="button-control"
                disabled={isDisabled}
                onClick={() => {
                    handleSendMainCommands(commandId, 'setting', lock.key, 0, Math.abs(currentStatus * 1), true, false, false, false, '', '', false)
                }}
            >
                {lock.text}
            </Button>
        </div>
    )
}

let styles = {
    open_close_field: {
        height: '5vh' /* to fit on smaller browser sizes !!! SRT-1593 */
    },
    buttons_border_radius: {
        borderRadius: '5px',
        height: '100%',
        fontSize: '0.7em'
    },
}

export default TakeReleaseControl;