import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TabPanelProps } from '../../types';
import { useTabPanelStyles } from './TabPanelStyles';

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, uniqueId, uniqueLabel, ...other } = props;
    const classes = useTabPanelStyles();
    return (
        <div
            className="TabPanel"
            role="tabpanel"
            hidden={value !== index}
            id={`${uniqueId}-${index}`}
            data-testid={uniqueId + "-" + index}
            aria-labelledby={`${uniqueLabel}-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={classes.boxP3}>
                    <Typography className={uniqueId === "commandLogs-tabpanel" ? classes.TypographyDiv : classes.TypographyDiv2} component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}