import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTeams } from '../../store/selectors';


export default function SRTLineupTextSetField({ order, type, team, handleClickOpenPopUp }) {

    const playersDetail = useSelector(selectTeams);

    return (
        <>
            <TextField
                disabled
                onClick={() => {

                }}
                //style={{ width: '72%' }}
                size='small'
                id={order + '-' + type + '-' + team}
                //label={type + ' player ' + index}
                variant="standard"
                value={(playersDetail[team - 1][type][order - 1]?.name) || 'Please select'}
                name='players'
            />

            <Button
                style={{ padding: '0px'/* , marginTop: '0%' */ }}
                variant='outlined'
                color='primary'
                name="lineupSetButton"
                onClick={() => {
                    handleClickOpenPopUp('player', team, false, null, null, { order, type })
                }}
            >
                Set
            </Button>
        </>
    )
}
