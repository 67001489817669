import React, { } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { selectCurrentGameInfoState, selectStatus, selectCommandId } from '../../../../store/selectors';
import { useDispatch, useSelector } from "react-redux";
import { setAttendance, setAwayColor1, setAwayColor2, setHomeColor1, setHomeColor2, setPreMatchInfo} from '../../../../store/actions';
import { handleSendMainCommands } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        minWidth: '50%',
    },
}));

const GameInfo = ({ type }) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const currentStatus = useSelector(selectStatus);
    const commandId = useSelector(selectCommandId);

    const handleChange = (event, setFunction) => {
        dispatch(setFunction(event.target.value))
        /* Send preMatchInfo confirmed command with the value taken fron the 'event.target.value' */
        handleSendMainCommands(commandId, 'command', event.target.name, 0, Math.abs(currentStatus * 1), true, false, false, false, 'gameInfo', event.target.value, false)
    }

    let result = <div>No info</div>;

    const { currentPreMatchInfo, currentAttendance, currentHomeColor1, currentHomeColor2, currentAwayColor1, currentAwayColor2 }
        = useSelector(selectCurrentGameInfoState);

    switch (type) {

        case 'preMatchInfo':
            result =
                <div className="pre_match_info_field" style={Object.assign({}, styles.game_info_child_divs)}>
                    <FormControl className={classes.formControl}>
                        <InputLabel data-testid="pre-match-info-label" data-cy="pre-match-info-label">Pre Match Info</InputLabel>
                        <Select
                            //labelId="pre-match-native-label"
                            value={currentPreMatchInfo}
                            name={type}
                            onChange={(e) => {
                                handleChange(e, setPreMatchInfo)
                            }}
                            inputProps={{
                                id: 'pre-match',
                            }}
                            data-cy="select-pre-match-info"
                            data-testid="select-pre-match-info"
                        >
                            <MenuItem data-cy="pre-match-info-item-1" value="Players not on the court">Players NOT on the court</MenuItem>
                            <MenuItem data-cy="pre-match-info-item-2" value="Players on the court">Players on the court</MenuItem>
                            <MenuItem data-cy="pre-match-info-item-3" value="Players are warming up">Players are warming up</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            break;

        case 'attendance':
            result =
                <div className="attendance_field" style={Object.assign({}, styles.game_info_child_divs)}>
                    <FormControl className={classes.formControl}>
                        <InputLabel data-testid="attendance-label" data-cy="attendance-label">Attendance</InputLabel>
                        <Select
                            value={currentAttendance}
                            name={type}
                            onChange={(e) => {
                                handleChange(e, setAttendance)
                            }}
                            inputProps={{
                                id: 'attendance',
                            }}
                            data-cy="select-attendance"
                            data-testid="select-attendance"
                        >
                            <MenuItem data-cy="attendance-item-1" value={'0%'}>0%</MenuItem>
                            <MenuItem data-cy="attendance-item-2" value={'50%'}>50%</MenuItem>
                            <MenuItem data-cy="attendance-item-3" value={'100%'}>100%</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            break;

        case 'homeColor1':
            result =
                <FormControl style={styles.colorsField}>
                    <InputLabel data-testid="home-color-1-label" data-cy="home-color-1-label">Home Color 1</InputLabel>
                    <Select
                        value={currentHomeColor1 || ""}
                        name={type}
                        inputProps={{
                            id: 'homeColor1',
                        }}
                        onChange={(e) => {
                            handleChange(e, setHomeColor1)
                        }}
                        data-cy="select-home-color-1"
                        data-testid="select-home-color-1"
                    >
                        <MenuItem data-cy="home-color-1-item-1" value={'white'}>White</MenuItem>
                        <MenuItem data-cy="home-color-1-item-2" value={'black'}>Black</MenuItem>
                        <MenuItem data-cy="home-color-1-item-3" value={'red'}>Red</MenuItem>
                        <MenuItem data-cy="home-color-1-item-4" value={'blue'}>Blue</MenuItem>
                        <MenuItem data-cy="home-color-1-item-5" value={'green'}>Green</MenuItem>
                        <MenuItem data-cy="home-color-1-item-6" value={'yellow'}>Yellow</MenuItem>
                        <MenuItem data-cy="home-color-1-item-7" value={'orange'}>Orange</MenuItem>
                        <MenuItem data-cy="home-color-1-item-8" value={'purple'}>Purple</MenuItem>
                        <MenuItem data-cy="home-color-1-item-9" value={'pink'}>Pink</MenuItem>
                        <MenuItem data-cy="home-color-1-item-10" value={'brown'}>Brown</MenuItem>
                        <MenuItem data-cy="home-color-1-item-11" value={'gray'}>Gray</MenuItem>
                        <MenuItem data-cy="home-color-1-item-12" value={'lightgreen'}>Lightgreen</MenuItem>
                        <MenuItem data-cy="home-color-1-item-13" value={'skyblue'}>Skyblue</MenuItem>
                    </Select>
                </FormControl>
            break;

        case 'homeColor2':
            result =
                <FormControl style={styles.colorsField}>
                    <InputLabel data-testid="home-color-2-label" data-cy="home-color-2-label">Home Color 2</InputLabel>
                    <Select
                        value={currentHomeColor2 || ""}
                        name={type}
                        inputProps={{
                            id: 'homeColor2',
                        }}
                        onChange={(e) => {
                            handleChange(e, setHomeColor2)
                        }}
                        data-cy="select-home-color-2"
                        data-testid="select-home-color-2"
                    >
                        <MenuItem data-cy="home-color-2-item-1" value={'white'}>White</MenuItem>
                        <MenuItem data-cy="home-color-2-item-2" value={'black'}>Black</MenuItem>
                        <MenuItem data-cy="home-color-2-item-3" value={'red'}>Red</MenuItem>
                        <MenuItem data-cy="home-color-2-item-4" value={'blue'}>Blue</MenuItem>
                        <MenuItem data-cy="home-color-2-item-5" value={'green'}>Green</MenuItem>
                        <MenuItem data-cy="home-color-2-item-6" value={'yellow'}>Yellow</MenuItem>
                        <MenuItem data-cy="home-color-2-item-7" value={'orange'}>Orange</MenuItem>
                        <MenuItem data-cy="home-color-2-item-8" value={'purple'}>Purple</MenuItem>
                        <MenuItem data-cy="home-color-2-item-9" value={'pink'}>Pink</MenuItem>
                        <MenuItem data-cy="home-color-2-item-10" value={'brown'}>Brown</MenuItem>
                        <MenuItem data-cy="home-color-2-item-11" value={'gray'}>Gray</MenuItem>
                        <MenuItem data-cy="home-color-2-item-12" value={'lightgreen'}>Lightgreen</MenuItem>
                        <MenuItem data-cy="home-color-2-item-13" value={'skyblue'}>Skyblue</MenuItem>
                    </Select>
                </FormControl>
            break;

        case 'awayColor1':
            result =
                <FormControl style={styles.colorsField}>
                    <InputLabel data-testid="away-color-1-label" data-cy="away-color-1-label">Away Color 1</InputLabel>
                    <Select
                        value={currentAwayColor1 || ""}
                        name={type}
                        inputProps={{
                            id: 'awayColor1',
                        }}
                        onChange={(e) => {
                            handleChange(e, setAwayColor1)
                        }}
                        data-cy="select-away-color-1"
                        data-testid="select-away-color-1"
                    >
                        <MenuItem data-cy="away-color-1-item-1" value={'white'}>White</MenuItem>
                        <MenuItem data-cy="away-color-1-item-2" value={'black'}>Black</MenuItem>
                        <MenuItem data-cy="away-color-1-item-3" value={'red'}>Red</MenuItem>
                        <MenuItem data-cy="away-color-1-item-4" value={'blue'}>Blue</MenuItem>
                        <MenuItem data-cy="away-color-1-item-5" value={'green'}>Green</MenuItem>
                        <MenuItem data-cy="away-color-1-item-6" value={'yellow'}>Yellow</MenuItem>
                        <MenuItem data-cy="away-color-1-item-7" value={'orange'}>Orange</MenuItem>
                        <MenuItem data-cy="away-color-1-item-8" value={'purple'}>Purple</MenuItem>
                        <MenuItem data-cy="away-color-1-item-9" value={'pink'}>Pink</MenuItem>
                        <MenuItem data-cy="away-color-1-item-10" value={'brown'}>Brown</MenuItem>
                        <MenuItem data-cy="away-color-1-item-11" value={'gray'}>Gray</MenuItem>
                        <MenuItem data-cy="away-color-1-item-12" value={'lightgreen'}>Lightgreen</MenuItem>
                        <MenuItem data-cy="away-color-1-item-13" value={'skyblue'}>Skyblue</MenuItem>
                    </Select>
                </FormControl>
            break;

        case 'awayColor2':
            result =
                <FormControl style={styles.colorsField}>
                    <InputLabel data-testid="away-color-2-label" data-cy="away-color-2-label">Away Color 2</InputLabel>
                    <Select
                        value={currentAwayColor2 || ""}
                        name={type}
                        inputProps={{
                            id: 'awayColor2',
                        }}
                        onChange={(e) => {
                            handleChange(e, setAwayColor2)
                        }}
                        data-cy="select-away-color-2"
                        data-testid="select-away-color-2"
                    >
                        <MenuItem data-cy="away-color-2-item-1" value={'white'}>White</MenuItem>
                        <MenuItem data-cy="away-color-2-item-2" value={'black'}>Black</MenuItem>
                        <MenuItem data-cy="away-color-2-item-3" value={'red'}>Red</MenuItem>
                        <MenuItem data-cy="away-color-2-item-4" value={'blue'}>Blue</MenuItem>
                        <MenuItem data-cy="away-color-2-item-5" value={'green'}>Green</MenuItem>
                        <MenuItem data-cy="away-color-2-item-6" value={'yellow'}>Yellow</MenuItem>
                        <MenuItem data-cy="away-color-2-item-7" value={'orange'}>Orange</MenuItem>
                        <MenuItem data-cy="away-color-2-item-8" value={'purple'}>Purple</MenuItem>
                        <MenuItem data-cy="away-color-2-item-9" value={'pink'}>Pink</MenuItem>
                        <MenuItem data-cy="away-color-2-item-10" value={'brown'}>Brown</MenuItem>
                        <MenuItem data-cy="away-color-2-item-11" value={'gray'}>Gray</MenuItem>
                        <MenuItem data-cy="away-color-2-item-12" value={'lightgreen'}>Lightgreen</MenuItem>
                        <MenuItem data-cy="away-color-2-item-13" value={'skyblue'}>Skyblue</MenuItem>
                    </Select>
                </FormControl>
            break;
    }

    return result;
}

let styles = {
    game_info_child_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '99%',
    },
    colorsField: {
        width: '47%',
        //margin: theme.spacing(1),
    },
}

export default GameInfo;