import { makeStyles } from "@material-ui/core";

export const useTabPanelStyles = makeStyles((theme) => ({
    boxP3: {
        padding: '0px',
        height: '100%',
    },
    TypographyDiv: {
        height: '100%',
        class: 'TypographyDiv'
    },
    TypographyDiv2: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        class: 'TypographyDiv2'
    },
}));