import React, { useState, useEffect } from 'react'; // we need this to make JSX compile
import NavBar from './NavBarAF';
import SimpleTabs from './SimpleTabs';
import CommandFilter from './CommandFilter';
import Button from '@material-ui/core/Button';
import { sendCommand } from "../CollectionTool.utils";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { green, red } from '@material-ui/core/colors';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import ScrollAnimation from 'react-animate-on-scroll';
// import '../Navbar.css';
import { event_id } from '../../App';
import FixtureTable from './Table';
import { FormControl, Input } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import {
    setGameTime, startClock, setWeather, setPreMatchInfo, setWind, setAttendance, setTossWinner,
    setHomeChoice, setAwayChoice, setHomeColor1, setHomeColor2, setAwayColor1, setAwayColor2, setPlayers, setSafety,
    setHomeFieldGoal, setAwayFieldGoal, setKickOff, setCloseOpenGame, setHomeActiveTeam, setAwayActiveTeam, setOverlayDisplay,
    setStatus, setTeamPossesion, setAwayDown, setHomeDown, setAwayTouchdownDisabled, setHomeTouchdownDisabled,
    setAwayOnePointMadeMissed, setHomeOnePointMadeMissed, setAwayTwoPointsMadeMissed, setHomeTwoPointsMadeMissed, setSnap, setIsClockRunning,
    setMapIdToActionId, setCommandId, setCommandInc, setFeedCommandInc, setHeartBeat
} from '../../store/actions';
import {
    selectGameTimeState, selectOverlayDisplay, selectStatus, selectTeamPossesion, selectUser, selectSnap, selectHomeFieldGoal,
    selectAwayFieldGoal, selectHomeOnePointMadeMissed, selectAwayOnePointMadeMissed, selectHomeTwoPointsMadeMissed,
    selectAwayTwoPointsMadeMissed, selectKickOff, selectHomeDown, selectAwayDown, selectHomeTouchdownDisabled,
    selectAwayTouchdownDisabled, selectIsClockRunning, selectCurrentGameInfoState, selectMapIdToActionId, selectCommandId, 
    selectCommandInc, selectFeedCommandInc, selectConnectionStatus
} from '../../store/selectors';
import { setClockValue } from "./utils";
import { useAmericanFootballStyles } from './AmericanFootballStyles';
//import SRTMainButton from './SRTMainButton';
import cloneDeep from 'lodash.clonedeep';


/* This pop up will be open when user click at RESET button */
let popupReset = (obj, dispatch, classes) => {
    confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>Are you sure to reset the event?</h1>
                    <div className={classes.yes_no_field}>
                        <Button
                            style={{ fontSize: '1.3em', borderRadius: '15px' }}
                            variant="contained"
                            color="primary"
                            //size="large"
                            onClick={() => {
                                /* Show the overlay pop up to not be able to add more commands until this one is processed and Overlay will hide   */
                                dispatch(setOverlayDisplay('block'));
                                sendCommand(obj);
                            }}
                        >
                            Yes
                        </Button>

                        <MuiThemeProvider theme={theme}>
                            <Button
                                style={{ fontSize: '1.3em', borderRadius: '15px' }}
                                variant="contained"
                                color="secondary"
                                onClick={onClose}
                            >
                                No
                            </Button>
                        </MuiThemeProvider>
                    </div>
                </div>
            );
        }
    });
}

/* Green/red theme for buttons */
const theme = createTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

/* All commands that will have pop up for confirmation */
export const allPopUpCommands = ['down', 'touchdown', 'fieldGoalAttempt', 'fieldGoalMade', 'fieldGoalMissed', 'activeTeam', 'penalty', 'timeout', 'challenge', 'replayReview', 'kickoff', 'safety'];
export const sportID = '269467';
export const startingHalfStatuses = [26, 30];
export const statusesHaveBeenThrough = [];


/* keep uncf point to prevent adding two uncf */
/* TO DO - make this globally for all uncf commands , not only POINTS !!!  */
export const allUncfPoints = [];

export const settings = ['releaseControl', 'takeControl', 'callcenterOk', 'callcenterLost'];
/* Array which contain 'MSF endpoints' related to increasing feed sequence */
export const feedSequenceIdCommands = ['CurrentPossession', 'YardsToEndZone', 'Touchdown', 'Timeout', 'Snap', 'Safety',
    'MatchStatus', 'Kickoff'];

//Commands which will have text property in the value object (value-value-text: string)
export const objValueValueTextCommands = ['comment', 'preMatchInfo', 'weather', 'wind', 'tossWinner', 'pitch', 'attendance',
    'home_color_1', 'home_color_2', 'away_color_1', 'away_color_2', 'homeChoice', 'awayChoice'];

export const objValueValueCommands = ['yards'];

export const allStatusesAvailable = {
    "0": { title: 'Not Started', nextStatus: 26, msfName: 'NotStarted', special: true },
    "1": { title: 'Not Started', nextStatus: 26, msfName: 'NotStarted', special: true },
    "2": { title: 'Warmup', nextStatus: 26, msfName: 'Warmup', special: true },
    "5": { title: 'Postponed', nextStatus: 5, msfName: 'Postponed', special: true },
    "6": { title: 'Finished', nextStatus: 6, msfName: 'Finished', special: true },
    "12": { title: 'Interrupted', nextStatus: 12, msfName: 'Interrupted', special: true },
    "15": { title: 'Coverage Stopped', nextStatus: 15, msfName: 'CoverageStopped', special: true },
    "17": { title: 'Abandoned', nextStatus: 19, msfName: 'Abandoned', special: true },
    "18": { title: 'Cancelled', nextStatus: 18, msfName: 'Cancelled', special: true },
    "19": { title: 'To Finish', nextStatus: 19, msfName: 'Unknown', special: true },
    "25": { title: 'Delayed', nextStatus: 26, msfName: 'Delayed', special: true },
    "26": { title: '1st Quarter', nextStatus: 27, msfName: 'InProgress' },
    "27": { title: 'End of 1th Quarter', nextStatus: 28 },
    "28": { title: '2nd Quarter', nextStatus: 29, msfName: 'InProgress' },
    "29": { title: 'End of 2nd Quarter', nextStatus: 30 },
    "30": { title: '3rd Quarter', nextStatus: 31, msfName: 'InProgress' },
    "31": { title: 'End of 3rd Quarter3', nextStatus: 32 },
    "32": { title: '4th Quarter', nextStatus: 33, msfName: 'InProgress' },
    "33": { title: 'End of 4th Quarter', nextStatus: 6 },
    "34": { title: 'Overtime', nextStatus: 35, msfName: 'InProgress' },
    "35": { title: 'End of Overtime', nextStatus: 36 },
    "36": { title: 'Finished After Overtime', nextStatus: 36 },
    "37": { title: '2nd Overtime', nextStatus: 38, msfName: 'InProgress' },
    "38": { title: 'End of 2nd Overtime', nextStatus: 36 },
    "39": { title: '3rd Overtime', nextStatus: 40, msfName: 'InProgress' },
    "40": { title: 'End of 3th Overtime', nextStatus: 36 },
    "41": { title: '4th Overtime', nextStatus: 42, msfName: 'InProgress' },
    "42": { title: 'End of 4th Overtime', nextStatus: 36 },
    "43": { title: '5th Overtime', nextStatus: 44, msfName: 'InProgress' },
    "44": { title: 'End of 5th Overtime', nextStatus: 36 },
    "45": { title: '6th Overtime', nextStatus: 46, msfName: 'InProgress' },
    "46": { title: 'End of 6th Overtime', nextStatus: 36 },
    "47": { title: '7th Overtime', nextStatus: 48, msfName: 'InProgress' },
    "48": { title: 'End of 7th Overtime', nextStatus: 36 },
    "49": { title: '8th Overtime', nextStatus: 50, msfName: 'InProgress' },
    "50": { title: 'End of 8th Overtime', nextStatus: 36 },
    "51": { title: '9th Overtime', nextStatus: 52, msfName: 'InProgress' },
    "52": { title: 'End of 9th Overtime', nextStatus: 36 },
    "53": { title: '10th Overtime', nextStatus: 54, msfName: 'InProgress' },
    "54": { title: 'End Of 10th Overtime', nextStatus: 36 }
}

export const activeStatuses = [26, 28, 30, 32, 34, 37, 39, 41, 43, 45, 47, 49, 51, 53];
export const mediaButtonStatuses = [2, 27, 29, 31, 33, 35, 38, 40, 42, 44, 46, 48, 50, 52, 54];
export const q2_q4 = [28, 32];

/* All command rows with the respective colors */
export const allTeamCommands = {
    serve: { color: '#f5f7fa 0%, #e2ebf0 100%', cancelDisabled: true, editDisabled: false },
    let: { color: '#f5f7fa 0%, #e9e9e7 100%', cancelDisabled: true, editDisabled: false },
    point: { color: '#f5f7fa 0%, #84fab0 100%', cancelDisabled: false, editDisabled: false },
    timeout: { color: '#f5f7fa 0%, #fcb69f 100%', cancelDisabled: true, editDisabled: false },
    firstServe: { color: '#f5f7fa 0%, #ffd1ff 100%', cancelDisabled: true, editDisabled: false },
};

export const allNoTeamCommands = {
    status_ok: { color: 'lightgreen', cancelDisabled: true, editDisabled: true },
    status_unsure: { color: 'linear-gradient(#ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)', cancelDisabled: true, editDisabled: true },
    matchstatus: { color: 'linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)', cancelDisabled: true, editDisabled: true },
    gamestart: { color: 'linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)', cancelDisabled: true, editDisabled: true },
    gamefinish: { color: 'linear-gradient(to right, #d7d2cc 0%, rgb(86 99 109 / 50%) 100%)', cancelDisabled: true, editDisabled: true },
    comment: { color: 'linear-gradient(to top, #4481eb 0%, #04befe 100%)', cancelDisabled: true, editDisabled: true },
    preMatchInfo: { color: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)', cancelDisabled: true, editDisabled: true },
    pitch: { color: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)', cancelDisabled: true, editDisabled: true },
    attendance: { color: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)', cancelDisabled: true, editDisabled: true },
}

export const allTeamCommandsKeys = Object.keys(allTeamCommands);
export const allNoTeamCommandsKeys = Object.keys(allNoTeamCommands);

let timer;


export default function AmericanFootballTable({ data, db_data, newCommand, isControlTaken }: any) {
    const dispatch = useDispatch();
    const gameTime = useSelector(selectGameTimeState);
    const overlayDisplay = useSelector(selectOverlayDisplay);
    const currentTeamPossesion = useSelector(selectTeamPossesion);
    const currentStatus = useSelector(selectStatus);
    const user = useSelector(selectUser);
    const currentSnap = useSelector(selectSnap);
    const currentKickOff = useSelector(selectKickOff);
    const currentHomeDown = useSelector(selectHomeDown);
    const currentAwayDown = useSelector(selectAwayDown);
    const currentHomeFieldGoal = useSelector(selectHomeFieldGoal);
    const currentAwayFieldGoal = useSelector(selectAwayFieldGoal);
    const currentHomeOnePointMadeMissed = useSelector(selectHomeOnePointMadeMissed);
    const currentAwayOnePointMadeMissed = useSelector(selectAwayOnePointMadeMissed);
    const currentHomeTwoPointsMadeMissed = useSelector(selectHomeTwoPointsMadeMissed);
    const currentAwayTwoPointsMadeMissed = useSelector(selectAwayTwoPointsMadeMissed);
    const currentHomeTouchdownDisabled = useSelector(selectHomeTouchdownDisabled);
    const currentAwayTouchdownDisabled = useSelector(selectAwayTouchdownDisabled);
    const isClockRunning = useSelector(selectIsClockRunning);
    const { currentHomeColor1, currentHomeColor2, currentAwayColor1, currentAwayColor2 } = useSelector(selectCurrentGameInfoState);
    const mapIdToActionId = useSelector(selectMapIdToActionId);
    const commandId = useSelector(selectCommandId);
    const commandInc = useSelector(selectCommandInc);
    const feedCommandInc = useSelector(selectFeedCommandInc);
    const connectionStatus = useSelector(selectConnectionStatus);

    const classes = useAmericanFootballStyles();


    const clockValue = setClockValue(gameTime);

    let resetObj = {
        type: "command",
        page: "desktop",
        value: {
            feed: {
                "sequence": feedCommandInc,
                "isConfirmed": true,
                "utcTimestamp": new Date().toISOString()
            },
            id: commandId,
            sport_id: sportID,
            "event_id": event_id,
            feedAction: 'reset',
            "UTCtimestamp": new Date().toISOString()
        }
    };

    let teams = [data?.team_home || "No Team 1 Info", data?.team_away || "No Team 2 Info"];
    //let start_date = [data?.startdate || "No start date Available"];

    const [score, setScore] = useState({
        total: [0, 0],
        26: [0, 0],
        28: [0, 0],
        30: [0, 0],
        32: [0, 0],
        34: [0, 0],
        37: [0, 0],
        39: [0, 0],
        41: [0, 0],
        43: [0, 0],
        45: [0, 0],
        47: [0, 0],
        49: [0, 0],
        51: [0, 0],
        53: [0, 0],
    })

    /* Increase/Decrease score count */
    const scoreCalculation = (command, onLoad, temp_score?) => {
        let newValue = [0, 0],
            count = 0,
            positiveNegative = 1; /* variable to describe either we will substract or add */

        if (!command.isConfirmed && !command.isCancelled) {
            positiveNegative = 1;
        }
        else if (command.isCancelled) {
            positiveNegative = -1;
        }
        /* We need to return temp_score if the command is Confirmed as do not want new calculation */
        /* Calculations are made at the uncf command */
        else if (/* onLoad ||  */command.isConfirmed) {
            return temp_score;
        }

        switch (command.key) {
            case 'safety':
                count = 2 * positiveNegative;
                break;
            case 'touchdown':
                count = 6 * positiveNegative;
                break;
            case 'onePointConversionMade':
                count = 1 * positiveNegative;
                break;
            case 'twoPointsConversionMade':
                count = 2 * positiveNegative;
                break;
            default:
                break;
        }
        /* Update with how much will increase/decrease the value */
        command.team === 2 ? newValue[1] = count : newValue[0] = count;
        /* on Refresh update temp_score and return it , as needs to be updated after he loop is finished in populateDataOnLoad */
        if (onLoad) {
            temp_score[command.gameNumber] = [
                temp_score[command.gameNumber][0] + newValue[0],
                temp_score[command.gameNumber][1] + newValue[1]
            ]
            return temp_score;
        }
        /* Initial commands will update the score hook with desired value */
        else {
            setScore({
                ...score,
                [command.gameNumber]: [
                    score[command.gameNumber][0] + newValue[0],
                    score[command.gameNumber][1] + newValue[1]],
            })
        }
    }

    /* Default slider field value */
    const [sliderValue, setSliderValue] = React.useState<number | string | Array<number | string>>(50);

    /* Default slider direction */
    const [sliderDirection, setSliderDirection] = useState('ltr');

    /* This is default value for div property left for fieldSlider */
    const [absoluteLeftProperty, setAbsoluteLeftProperty] = React.useState<number | string | Array<number | string>>(49);

    const [maxGameTime, setMaxGameTime] = useState(0);

    /* Hook to keep lock state/key/color/text */
    const [lock, setUnlock] = useState({
        display: 'block',
        key: 'takeControl',
        text: 'TAKE CONTROL',
        color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
        //color: '#e040fb',
        user: ''
    });

    /* Hook to keep start clock/stop clock state */
    const [startStopClock, setStartStopClock] = useState({
        name: 'Start Clock',
        key: 'startClock',
        color: "primary"
    });

    /* Keep last added details when opening the CONFIRMATION pop up */
    const [popUpCommand, setPopUpCommand] = useState({
        key: '',
        team: 0,
        confirmed: false,
        id: 0,
        modified: false,
        valueObj: {}
    });

    const [offsetMin, setOffsetMin] = useState(0);
    const [offsetSec, setOffsetSec] = useState(0);

    /* Hook to keep value for display or not */
    //const [firstServeDisplay, setFirstServeDisplay] = useState('block');
    /* Hook to keep if the match is notStarted (gameNumber 0) , started (gameNumber 1) , inProgress ( > gameNumber 1) or halfTime( end of ... game) */
    //const [gameStarted, setGameStarted] = useState('notStarted');

    /* Keep all commands and filtered command by type as we need to display different stuff for different TABS (SHOW ALL , POINT ...) */
    const [commandLogArrays, setCommandLogArrays] = useState({
        all: [],
        points: [],
        comments: [],
        info: [],
        edit_cancel_commands: [],
        deleted_commands_ids: [],
        map_command_log: {}
    });

    const handleSendMainCommands = (id: number, type: string, key: string, team: number, status: number, isConfirmed: boolean,
        isCancelled: boolean, isModified: boolean, type_comment?: any, value?: any, edit?: any) => {

        let feed = {};
        let feedAction = '';
        let objValue = {};

        /* Populate feed , feedAction and VALUE props */
        //if (value && value !== null) { /* goes here only if we have some value props */
        switch (key) {
            case 'status_ok':
                feed = {
                    "isReliable": true,
                    "isConfirmed": true,
                    //"reason": 'unknown',
                    "utcTimestamp": new Date().toISOString(),
                }
                feedAction = 'reliability';
                break;

            case 'status_unsure':
                feed = {
                    "reason": 'unknown',
                    "isReliable": false,
                    "isConfirmed": true,
                    "utcTimestamp": new Date().toISOString(),
                }
                feedAction = 'reliability';
                break;

            case 'replayReview':
                feed = {
                    "isPlayUnderReview": true,
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString(),
                }
                feedAction = 'PlayReview';
                break;

            /* Send matchStatus feed for predifined comments */
            case 'comment':
                feed = {
                    "text": value,
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": new Date().toISOString()
                }

                //if (type_comment === 'premade' || type_comment === undefined) {
                // if (value === 'Walkover competitor ' + teams[0]) {
                //     feed = {
                //         "matchStatus": "WalkoverCompetitorOne",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }
                // if (value === 'Walkover competitor ' + teams[1]) {
                //     feed = {
                //         "matchStatus": "WalkoverCompetitorTwo",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }
                // if (value === 'Retirement competitor ' + teams[0]) {
                //     feed = {
                //         "matchStatus": "WinnerCompetitorOneByRetirementOrDefault",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }
                // if (value === 'Retirement competitor ' + teams[1]) {
                //     feed = {
                //         "matchStatus": "WinnerCompetitorTwoByRetirementOrDefault",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }
                // if (value === 'Home medical timeout') {
                //     feed = {
                //         "matchStatus": "MedicalTimeoutCompetitorOne",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }
                // if (value === 'Away medical timeout') {
                //     feed = {
                //         "matchStatus": "MedicalTimeoutCompetitorTwo",
                //         "sequence": feedCommandInc,
                //         "isConfirmed": true,
                //         "utcTimestamp": new Date().toISOString(),
                //     }
                // }

                //feedAction = type_comment === 'custom' ? '' : 'matchstatus';
                //}

                break;

            case 'preMatchInfo':
                if (value === 'Players on the court') {
                    feed = {
                        "matchStatus": "OnCourt",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": new Date().toISOString(),
                    }
                }
                if (value === 'Players not on the court') {
                    feed = {
                        "matchStatus": "PreMatch",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": new Date().toISOString(),
                    }
                }
                if (value === 'Players are warming up') {
                    feed = {
                        "matchStatus": "WarmingUp",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": new Date().toISOString(),
                    }
                }
                feedAction = 'matchstatus';
                break;

            case 'player':
                if (value && value !== null) {
                    objValue = {
                        name: value?.player,
                        order: value?.order,
                        player_id: value?.playerId,
                        position: value?.position,
                        team_type: value?.team_type
                    };
                }
                break;

            case 'activeTeam':
                if (value && value !== null) {
                    objValue = {
                        type: value?.type,
                    };
                }

                // feed = {
                //     "home": "Unknown",
                //     "away": "Unknown"
                // }
                break;

            case 'kickoff':
                if (value && value !== null) {
                    objValue = {
                        name: value?.name,
                        player_id: value?.playerId,
                    };
                }

                feed = {
                    "playerId": 0,
                    "yards": 0, /* TO-DO What should be here !!!? */
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'Kickoff';
                break;

            case 'safety':
                if (value && value !== null) {
                    objValue = {
                        name: value && value[key],
                        player_id: value?.playerId,
                    }
                }

                feed = {
                    "teamId": team,
                    "isNullifiedByPenalty": true,
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'Safety';
                break;

            case 'down':
                if (value && value !== null) {
                    objValue = {
                        number: value && value[key],
                        scrimmage_yards: value?.scrimmage_yards,
                    }
                }
                break;

            case 'fieldGoalMade':
            case 'fieldGoalMissed':
                if (value && value !== null) {
                    objValue = {
                        name: value && value[key],
                        player_id: value?.playerId,
                    }
                }

                feed = {
                    "playerId": 0,
                    "isNullifiedByPenalty": true,
                    "result": key === 'fieldGoalMade' ? 'Made' : 'Missed',
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                break;

            case 'challenge':
                if (value && value !== null) {
                    objValue = {
                        outcome: value?.outcome
                    }
                }

                feed = {
                    "team": team === 1 ? 'home' : 'away',
                    "gameTime": gameTime,
                    "period": {
                        "number": 0, /* TO-DO input correct period here! */
                        "type": "Regular"
                    },
                    "isConfirmed": isConfirmed,
                    "result": value?.outcome || 'Unknown',
                    "utcTimestamp": new Date().toISOString()

                }
                break;

            case 'penalty':
                if (value && value !== null) {
                    objValue = {
                        name: value?.name,
                        type: value?.type,
                        yards: value?.yards,
                        player_id: value?.playerId,
                    }
                }

                feed = {
                    "team": team === 1 ? 'home' : 'away',
                    "playerId": 0,
                    "gameTime": gameTime,
                    "type": '......',
                    "isConfirmed": isConfirmed,
                    //"activeUnits":  ???????????
                    "yards": sliderValue,
                    "utcTimestamp": new Date().toISOString()

                }
                break;

            case 'touchdown':
                if (value && value !== null) {
                    objValue = {
                        name: value?.name,
                        player_id: value?.playerId,
                    }
                }
                feed = {
                    "teamId": team,
                    "playerId": 0,
                    "isNullifiedByPenalty": true,
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'Touchdown';
                break;

            case 'timeout':
                feed = {
                    "teamId": team,
                    "period": {
                        "number": 0, /* TO-DO input correct period here! */
                        "type": "Regular"
                    },
                    "gameClock": gameTime,
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'Timeout';
                break;

            case 'onePointConversionMade':
            case 'onePointConversionMissed':
                if (value && value !== null) {
                    objValue = {
                        name: value && value[key],
                        player_id: value?.playerId,
                    }
                }
                break;

            case 'twoPointsConversionMade':
            case 'twoPointsConversionMissed':
                if (value && value !== null) {
                    objValue = {
                        name: value && value[key],
                        player_id: value?.playerId,
                    }
                }
                feed = {
                    "teamId": team,
                    "playerId": 0,
                    "result": key === 'twoPointsConversionMade' ? 'Made' : 'Missed',
                    "isNullifiedByPenalty": true,
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": new Date().toISOString()
                }
                break;

            case 'possession':
                feed = {
                    "teamId": team,
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'CurrentPossession';
                break;

            case 'snap':
                feed = {
                    "huddle": true,
                    "formation": "Shotgun", /* TO-DO WTF is this ? :D */
                    "isConfirmed": true,
                    "sequence": feedCommandInc,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'Snap';
                break;

            case 'yards':
                feed = {
                    "teamId": team,
                    "yards": sliderValue,
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": new Date().toISOString()
                }
                feedAction = 'YardsToEndZone';
                break;

            default:
                break;
        }
        //}

        let obj = {
            type: type,
            value: {
                "key": key,
                "team": team,
                "isConfirmed": isConfirmed,
                "gameNumber": status,
                "event_id": event_id,
                "UTCtimestamp": new Date().toISOString(),
                "sport_id": sportID,
                "feedAction": feedAction,
                "gameTime": gameTime,
                //feed                        : feed
            },
            user: user
        };

        /* snap is teamless , but we need to specify which button is for the Home/Away team and hardcode team value = 0 here */
        if (key === 'snap') {
            obj.value['snapTeam'] = team;
            obj.value['team'] = 0;
        }

        if (key === 'offset') {
            obj.value['value'] = value;
            obj.value['gameTime'] += value;
        }

        if (type === 'command') {
            obj.value['id'] = id;
            obj.value['sequence'] = commandInc;
        }

        /* Assign value object if there are such available for the command */
        if (Object.keys(objValue).length !== 0) {
            obj.value['value'] = objValue;
        }

        /* Add feed object to the command ONLY if we have full feed object with needed props for MSF */
        if (Object.keys(feed).length > 0) {
            obj.value['feed'] = feed;
        }

        if (isModified) {
            obj.value['isModified'] = true;
        }

        /* This shows when command is from EDIT/CANCEL tab */
        if (edit === 'edit') {
            obj.value['edit'] = true;
        }

        if (isCancelled) {
            obj.value['isCancelled'] = isCancelled;
            obj.value['id'] = popUpCommand.id;

            /* Still this is not ready for MSF!!!  */
            //feed['isCancelled'] = true;
        }

        if (allPopUpCommands.includes(key) && isConfirmed) {
            obj.value['id'] = popUpCommand.id;
        }

        if (objValueValueTextCommands.includes(key)) {
            if (typeof obj.value['value'] === 'undefined') {
                obj.value['value'] = { text: value }
            }
        }

        /* THIS WILL BE CHANGED !!! */
        if (objValueValueCommands.includes(key)) {
            if (typeof obj.value['value'] === 'undefined') {
                obj.value['value'] = { text: sliderValue }
            }
        }


        // /* Add new prop to determine whether should be PUT request to MSF */
        // if (key === 'point' && isConfirmed) {
        //     obj.value['putRequest'] = true;
        // }

        /* Show overlay pop up to prevent adding other commands before this one is processed */
        dispatch(setOverlayDisplay('block'))

        dispatch(setHeartBeat({
            className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
            color: connectionStatus ? 'lightgreen' : 'red',
            text: connectionStatus ? 'Sending...' : 'No connection'
        }));

        /* related to feedId */
        /* All cancelled/modified command should have feedId  */
        if ((commandId !== id || (key === 'point' && isConfirmed) || isCancelled) && typeof mapIdToActionId[id] !== 'undefined') {
            obj.value['feedId'] = mapIdToActionId[id];
        }

        sendCommand(obj);
    }

    /* 
    - !!! Update hooks after the loop !!!
    - increment ID and INC
    - goes through all commands and filter to different arrays */
    const populateData = (commandLog) => {

        if (commandLog && commandLog.length === 0) { return; }

        /* Hide OVERLAY and user will be able to add commands */
        dispatch(setOverlayDisplay('none'));

        /* variables used for updating the hook after the loop */
        let temp_gameTime = gameTime, temp_isClockRunning = isClockRunning, temp_timestamp = '', temp_maxGameTime = maxGameTime,
            temp_status = currentStatus, temp_snap = currentSnap,
            temp_kickoff = currentKickOff,
            temp_home_down = currentHomeDown, temp_away_down = currentAwayDown,
            temp_home_touchdown = currentHomeTouchdownDisabled, temp_away_touchdown = currentAwayTouchdownDisabled,
            temp_home_fieldGoal = currentHomeFieldGoal, temp_away_fieldGoal = currentAwayFieldGoal,
            //temp_home_safety = 0, temp_away_safety = 0,
            temp_home_onepoint_made_missed = currentHomeOnePointMadeMissed, temp_away_onepoint_made_missed = currentAwayOnePointMadeMissed,
            temp_home_twopoints_made_missed = currentHomeTwoPointsMadeMissed, temp_away_twopoints_made_missed = currentAwayTwoPointsMadeMissed,
            temp_home_color_1 = currentHomeColor1, temp_home_color_2 = currentHomeColor2,
            temp_away_color_1 = currentAwayColor1, temp_away_color_2 = currentAwayColor2,
            temp_team_possesion = currentTeamPossesion,
            temp_slider_direction = String(sliderDirection),
            temp_slider_value = sliderValue, temp_absolute_left = absoluteLeftProperty,
            temp_lock = lock,
            temp_score = cloneDeep(score),
            temp_map_id_to_action_id = cloneDeep(mapIdToActionId),
            temp_command_id = commandId,
            temp_command_inc = commandInc,
            temp_feed_command_inc = feedCommandInc;


        if (commandLog && commandLog.length !== 0) {
            commandLog.forEach(element => {
                if (element !== "") {

                    let command = JSON.parse(element);
                    /* Assign the user to the every command value */
                    command.value['user'] = command.user;
                    let team = command.value.team === 1 ? 'home' : command.value.team === 0 ? 'none' : 'away';

                    if (command.value.key !== 'comment' && !settings.includes(command.value.key)) {
                        /* set snap button to be Active when any command is sent (without snap) */
                        temp_snap = 'none';
                    }

                    switch (command.value.key) {
                        case 'status_unsure':
                            dispatch(setCloseOpenGame('status_ok'))
                            break;

                        case 'status_ok':
                            dispatch(setCloseOpenGame('status_unsure'))
                            break;

                        case 'yards':
                            /* Update the temp_slider_value with last yards added from the command*/
                            temp_slider_value = command.value.value.text;

                            if (team === 'away') {
                                temp_slider_direction = 'rtl';
                                temp_absolute_left = (100 - command.value.value.text - 1.5);
                            } else {
                                temp_slider_direction = 'ltr';
                                temp_absolute_left = (command.value.value.text - 1.5);
                            }
                            break;

                        case 'activeTeam':
                            if (command.value.isConfirmed) {
                                if (command.value.value && command.value.value.type) {
                                    const setTeamAction = team === 'home' ? setHomeActiveTeam : setAwayActiveTeam;
                                    dispatch(setTeamAction(command.value.value.type))
                                }
                            }
                            break;

                        case 'player':
                            dispatch(setPlayers(command.value.team, command.value.value.team_type, command.value.value.order - 1, command.value.value));
                            break;

                        case 'down':
                            if (command.value.isConfirmed) {
                                if (command.value.value !== undefined) {
                                    let currentDownResult = '';

                                    if (command.value.value?.number !== undefined) {
                                        currentDownResult += command.value.value?.number + ['st', 'nd', 'rd', 'th'][command.value.value?.number - 1];
                                    }
                                    if (command.value.value?.number !== undefined && command.value.value?.scrimmage_yards !== undefined) {
                                        currentDownResult += ' & ';
                                    }

                                    if (command.value.value?.scrimmage_yards !== undefined) {
                                        currentDownResult += command.value.value?.scrimmage_yards;
                                    }

                                    if (team === 'home') {
                                        temp_home_down = currentDownResult;
                                        temp_away_down = '';
                                    }
                                    else {
                                        temp_away_down = currentDownResult;
                                        temp_home_down = '';
                                    }
                                }
                            }
                            break;

                        case 'fieldGoalAttempt':
                            if (command.value.isConfirmed) {
                                team === 'away' ? temp_away_fieldGoal = true : temp_home_fieldGoal = true;
                            }
                            break;

                        case 'fieldGoalMade':
                        case 'fieldGoalMissed':
                            if (command.value.isConfirmed) {
                                team === 'away' ? temp_away_fieldGoal = false : temp_home_fieldGoal = false;
                            }
                            break;

                        case 'safety':
                            temp_score = scoreCalculation(command.value, true, temp_score);
                            break;

                        case 'snap':
                            temp_isClockRunning = true;
                            temp_snap = command.value.snapTeam === 1 ? 'home' : 'away';
                            break;
                        case 'startClock':
                            temp_isClockRunning = true;
                            break;

                        case 'touchdown':
                            temp_isClockRunning = false;

                            if (command.value.isConfirmed) {
                                team === 'home' ? temp_home_touchdown = true : temp_away_touchdown = true;
                            };

                            temp_score = scoreCalculation(command.value, true, temp_score);
                            break;

                        case 'stopClock':
                            temp_isClockRunning = false;
                            break;

                        case 'onePointConversionAttempt':
                            team === 'home' ? temp_home_onepoint_made_missed = true : temp_away_onepoint_made_missed = true;
                            break;

                        case 'twoPointsConversionAttempt':
                            team === 'home' ? temp_home_twopoints_made_missed = true : temp_away_twopoints_made_missed = true;
                            break;

                        case 'onePointConversionMade':
                        case 'onePointConversionMissed':
                        case 'twoPointsConversionMade':
                        case 'twoPointsConversionMissed':
                            if (command.value.isConfirmed) {
                                if (team === 'home') {
                                    temp_home_touchdown = false;
                                    temp_home_onepoint_made_missed = false;
                                    temp_home_twopoints_made_missed = false;
                                }
                                else {
                                    temp_away_touchdown = false;
                                    temp_away_onepoint_made_missed = false;
                                    temp_away_twopoints_made_missed = false;
                                }
                            }

                            if (command.value.key === 'onePointConversionMade' || command.value.key === 'twoPointsConversionMade') {
                                temp_score = scoreCalculation(command.value, true, temp_score);
                            }
                            break;

                        case 'possession':
                            /* Update the temp_slider_value */
                            /* If it is different than the last team possesion then we need to take the mirror option on the field */
                            if (temp_team_possesion !== team && temp_team_possesion !== 'none') {
                                temp_slider_value = 100 - Number(temp_slider_value);
                            }
                            // else {
                            //     temp_slider_value = sliderValue;
                            // }

                            team === 'home' ? temp_slider_direction = 'ltr' : temp_slider_direction = 'rtl'; /* change direction */

                            /* Adjust the correct team taken the possesion to the temp valiable */
                            temp_team_possesion = team;
                            //temp_safety_disabled = team;
                            break;

                        case 'comment':
                            commandLogArrays.comments.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            break;
                        case 'kickoff':
                            if (command.value.isConfirmed) {
                                temp_kickoff = team;

                                /* Hide 1P & 2P CONVERSION ATTEMPT buttons */
                                temp_home_touchdown = false;
                                temp_away_touchdown = false;
                                temp_home_onepoint_made_missed = false;
                                temp_away_onepoint_made_missed = false;
                                temp_home_twopoints_made_missed = false;
                                temp_away_twopoints_made_missed = false;
                                temp_home_fieldGoal = false;
                                temp_away_fieldGoal = false;
                            }

                            /* Reset down count for both team at any Kickoff added */
                            temp_home_down = '';
                            temp_away_down = '';

                            //dispatch(setKickOff(team));
                            break;

                        // case 'pitch':
                        //     commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                        //     setCurrentPitch(command.value.value.text)
                        //     break;
                        case 'preMatchInfo':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setPreMatchInfo(command.value.value.text));
                            break;
                        case 'weather':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setWeather(command.value.value.text));
                            break;
                        case 'wind':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setWind(command.value.value.text));
                            break;
                        case 'attendance':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setAttendance(command.value.value.text));
                            break;
                        case 'home_color_1':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            temp_home_color_1 = command.value.value.text;
                            break;
                        case 'home_color_2':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            temp_home_color_2 = command.value.value.text;
                            //setsetHomeColor({ ...setHomeColor, 2: command.value.value.text })
                            break;
                        case 'away_color_1':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            temp_away_color_1 = command.value.value.text;
                            break;
                        case 'away_color_2':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            temp_away_color_2 = command.value.value.text;
                            break;
                        case 'tossWinner':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setTossWinner(command.value.value.text));
                            break;
                        case 'homeChoice':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setHomeChoice(command.value.value.text));
                            break;
                        case 'awayChoice':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setAwayChoice(command.value.value.text));
                            break;

                        case 'status':
                            temp_status = command.value.gameNumber;
                            //setCurrentStatus(command.value.gameNumber);

                            if (activeStatuses.includes(command.value.gameNumber)) {
                                temp_maxGameTime = command.value.gameTime;
                                temp_isClockRunning = false;
                                temp_team_possesion = team;
                            }

                            if (!statusesHaveBeenThrough.includes(command.value.gameNumber)) {
                                statusesHaveBeenThrough.push(command.value.gameNumber);
                            }

                            /* Reset active teams on 1st quarter & 3rd quarter for both teams */
                            if (startingHalfStatuses.includes(command.value.gameNumber)) {
                                dispatch(setHomeActiveTeam("Please select active team"))
                                dispatch(setAwayActiveTeam("Please select active team"))
                            }

                            /* Hide all main buttons when in Inactive statuses */
                            if (!activeStatuses.includes(command.value.gameNumber)) {
                                temp_kickoff = 'none';
                                temp_team_possesion = 'none';
                            }
                            else {
                                //     setOffsetMin(0);
                                //     setOffsetSec(0);
                                //     setStartStopClock({ name: "Start Clock", key: "startClock", color: "primary" });
                                //     setMaxGameTime(newCommand.gameTime);
                                //     dispatch(stopClock());
                                //     dispatch(setGameTime(newCommand.gameTime));
                            }

                            /* Reset slider and input slider values to default */
                            temp_slider_value = 50;
                            temp_absolute_left = 49;

                            break;
                        // case 'gamestart':
                        //     /* not deleted */
                        //     if (!command.value.isCancelled) {
                        //         //setCurrentStatus(command.value.gameNumber);
                        //         temp_status = command.value.gameNumber;

                        //         /* Take score for the current status if we have such set previosly and update the hook */
                        //         score.current_score = score['game_' + temp_status].slice(0);

                        //         setScore(score);

                        //         /* Change the hook to started if we just start game_1 */
                        //         if (command.value.gameNumber === 1) {
                        //             setGameStarted('started');
                        //         }

                        //         /* If the match is in game_2 or more and not Interrupted/Finished/Abondoned we change the hook */
                        //         else if (command.value.gameNumber > 1 && command.value.gameNumber < 10) {
                        //             setGameStarted('inProgress')
                        //         }

                        //         /* in All statuses after id 10 should hide all main Buttons  */
                        //         else if (command.value.gameNumber >= 10) {
                        //             setGameStarted('endOfSomeGame');
                        //         }
                        //     }

                        //     /* deleted  */
                        //     else {
                        //         // /* this variable will store previous status */
                        //         // let temp_status_calculation = (command.value.gameNumber - 1);
                        //         // /* if  temp_status_calculation is equal to 0 , no need to multiply it by -1 */
                        //         // temp_status_calculation !== 0 && setCurrentStatus(temp_status_calculation * (-1));	
                        //     }

                        //     break;
                        // case 'gamefinish':
                        //     /* not deleted */
                        //     if (!command.value.isCancelled) {

                        //         /* If it is gamefinish we need to hide all butons in that case */
                        //         if (command.value.gameNumber > 0) {
                        //             setGameStarted('endOfSomeGame')
                        //         }
                        //         //setCurrentStatus(command.value.gameNumber * (-1));
                        //         temp_status = command.value.gameNumber * (-1);

                        //     }

                        //     /* deleted */
                        //     // else {
                        //     // 	setCurrentStatus(command.value.gameNumber);
                        //     // }

                        //     break;
                        // case 'matchstatus':
                        //     if (!command.value.isCancelled) {
                        //         temp_status = command.value.gameNumber;

                        //         /* If it is matchstatus(Interrupted , Abandoned) we need to hide all butons in that case */
                        //         if (command.value.gameNumber > 0) {
                        //             setGameStarted('endOfSomeGame')
                        //         }
                        //     }

                        //     break;

                        case 'takeControl':
                            /* If it is the same socket(UI) update the hook/button */
                            if (isControlTaken) {
                                temp_lock = {
                                    display: 'none',
                                    key: 'releaseControl',
                                    text: 'RELEASE CONTROL',
                                    color: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
                                    //color: '#e040fb',
                                    user: command.value.user
                                };
                            }
                            /* If it is for other CC which is open , just update the user taken control to be seen */
                            else {
                                temp_lock.user = command.value.user;
                            }
                            break;

                        case 'releaseControl':
                            temp_lock = {
                                display: 'block',
                                key: 'takeControl',
                                text: 'TAKE CONTROL',
                                color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
                                //color: '',
                                user: ''
                            };
                            break;

                        default:
                            break;
                    }

                    if (command?.value?.gameTime) {
                        temp_gameTime = command.value.gameTime;
                        temp_timestamp = command.value.UTCtimestamp;
                    }

                    /* Increment command ID */
                    if (!settings.includes(command.value.key) &&
                        !command.value.isModified &&
                        ((command.value.edit === true && allPopUpCommands.includes(command.value.key) && (!command.value.isCancelled || (command.value.isCancelled && !command.value.isConfirmed))) || command.value.edit === undefined) &&
                        (!(!command.value.isConfirmed && allPopUpCommands.includes(command.value.key)) || !allPopUpCommands.includes(command.value.key) || command.value.isCancelled)) {
                        temp_command_id++;
                    }

                    if (command.value.actionId !== "") {
                        temp_map_id_to_action_id[command.value.id] = command.actionId;
                    }

                    /* We want to see uncf points in the EDIT/CANCEL tab which is displaying 'commandLogArrays.edit_cancel_commands' */
                    if (!command.value.isCancelled) {
                        if (allUncfPoints.includes(command.value.id) && !command.value.isCancelled) {
                            commandLogArrays.edit_cancel_commands.unshift({ feed: command.value.feed, id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, team: command.value.team, status: command.value.gameNumber, text: command.value.value && command.value.value.text ? command.value.value.text : {}, /* score_home: score.current_score[0], score_away: score.current_score[1], */ sequence: command.value.sequence });
                        }

                        // if (command.value.isConfirmed /* || (allUncfPoints.includes(command.value.id) && !command.value.isConfirmed) */) {	

                        // 	let index: number;
                        // 	commandLogArrays.edit_cancel_commands.some(function (elem, i) {
                        //     return elem.id === command.value.id  ? (index = i, true) : false;
                        // 	});
                        // 	if (index >= 0) {
                        // 		commandLogArrays.edit_cancel_commands.splice(index, 1);
                        // 	}

                        // 	commandLogArrays.edit_cancel_commands.unshift({id:command.value.id, key: command.value.key, isConfirmed:command.value.isConfirmed , team: command.value.team, status: command.value.gameNumber, text: command.value.value && command.value.value.text ? command.value.value.text : {} , score_home:score.current_score[0], score_away:score.current_score[1]});
                        // }
                    }
                    else {
                        commandLogArrays.deleted_commands_ids.push(command.value.id);
                    }

                    /* As we have the uncf and cnf point command in commandLogArrays.edit_cancel_commands , we need to remove the uncf one before pushing the confirmed to the ARRAY */
                    if (commandLogArrays.deleted_commands_ids.includes(command.value.id) || (!command.value.isCancelled && command.value.isConfirmed)) {
                        let index: number;
                        commandLogArrays.edit_cancel_commands.some(function (elem, i) {

                            return elem.id === command.value.id ? (index = i, true) : false;
                        });
                        if (index >= 0) {
                            commandLogArrays.edit_cancel_commands.splice(index, 1);
                        }

                        /* Push the confirmed and not canceled command only to commandLogArrays.edit_cancel_commands */
                        if (!commandLogArrays.deleted_commands_ids.includes(command.value.id) && !settings.includes(command.value.key)) {
                            /* Add this command object to the index position as we want commands to be displayed in Edit/Cancel tab consistently */
                            commandLogArrays.edit_cancel_commands.splice(index, 0, { feed: command.value.feed, id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, team: command.value.team, status: command.value.gameNumber, text: command.value.value && command.value.value.text ? command.value.value.text : {}, /* score_home: score.current_score[0], score_away: score.current_score[1], */ sequence: command.value.sequence });
                            //commandLogArrays.edit_cancel_commands.unshift({id:command.value.id, key: command.value.key, isConfirmed:command.value.isConfirmed , team: command.value.team, status: command.value.gameNumber, text: command.value.value && command.value.value.text ? command.value.value.text : {} , score_home:score.current_score[0], score_away:score.current_score[1] , sequence: command.value.sequence});
                        }
                    }

                    if (!settings.includes(command.value.key)) {
                        commandLogArrays.all.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, isCancelled: command.value.isCancelled, isModified: command.value.isModified, /* score_home: score.current_score[0], score_away: score.current_score[1], */ team: command.value.team, sequence: command.value.sequence, status: command.value.gameNumber })
                        /* Update this object with all the state of commansd and display/ue it in CommandRow.tsx */
                        commandLogArrays.map_command_log[command.value.sequence] = JSON.parse(JSON.stringify(command.value));
                        temp_command_inc++;
                    }

                    /* Increase feedCommandInc for some commands only */
                    if (command.value.feedAction && feedSequenceIdCommands.includes(command.value.feedAction)) {
                        temp_feed_command_inc++;
                    }
                }
            });
        }
        /* !!! UPDATE ALL HOOKS/DISPATCH AFTER THE LOOP WITH THEIR TEMP VARIABLES !!! */



        setSliderValue(temp_slider_value);
        setAbsoluteLeftProperty(temp_absolute_left);
        setSliderDirection(temp_slider_direction);

        /* Update score hook!!! */
        setScore(temp_score);

        dispatch(setTeamPossesion(temp_team_possesion));
        dispatch(setSafety(temp_team_possesion));
        dispatch(setKickOff(temp_kickoff));
        dispatch(setHomeColor1(temp_home_color_1));
        dispatch(setHomeColor2(temp_home_color_2));
        dispatch(setAwayColor1(temp_away_color_1));
        dispatch(setAwayColor2(temp_away_color_2));
        dispatch(setHomeFieldGoal(temp_home_fieldGoal));
        dispatch(setAwayFieldGoal(temp_away_fieldGoal));

        dispatch(setSnap(temp_snap));

        dispatch(setHomeTouchdownDisabled(temp_home_touchdown));
        dispatch(setAwayTouchdownDisabled(temp_away_touchdown));

        dispatch(setHomeOnePointMadeMissed(temp_home_onepoint_made_missed));
        dispatch(setHomeTwoPointsMadeMissed(temp_home_twopoints_made_missed));
        dispatch(setAwayOnePointMadeMissed(temp_away_onepoint_made_missed));
        dispatch(setAwayTwoPointsMadeMissed(temp_away_twopoints_made_missed));

        dispatch(setStatus(temp_status));
        dispatch(setIsClockRunning(temp_isClockRunning));

        dispatch(setHomeDown(temp_home_down));
        dispatch(setAwayDown(temp_away_down));

        setCommandLogArrays(JSON.parse(JSON.stringify(commandLogArrays)));
        setMaxGameTime(temp_maxGameTime);
        dispatch(setGameTime(!temp_isClockRunning ? temp_gameTime : temp_gameTime + Math.floor((Date.parse(temp_timestamp) - Date.now()) / 1000)));
        dispatch(setMapIdToActionId(temp_map_id_to_action_id));
        dispatch(setCommandId(temp_command_id));
        dispatch(setCommandInc(temp_command_inc));
        dispatch(setFeedCommandInc(temp_feed_command_inc));

        if (temp_isClockRunning) {
            setStartStopClock({ name: "Stop Clock", key: "stopClock", color: "red" });
            dispatch(startClock());
            clearInterval(timer);
            timer = setInterval(() => {
                dispatch(setGameTime());
            }, 1000);
        } else {
            setStartStopClock({ name: "Start Clock", key: "startClock", color: "primary" });
            clearInterval(timer);
        }

        /*On load we need to update user (if w!temp_isClockRunning ? e have such taken control on other CC) */
        setUnlock(temp_lock);

    }

    useEffect(() => {
        populateData(newCommand);
        // eslint-disable-next-line	
    }, [newCommand]);

    useEffect(() => {
        populateData(db_data);
        // eslint-disable-next-line	
    }, [db_data]);

    useEffect(() => {
        if (!isControlTaken) {
            setUnlock({
                display: 'block',
                key: 'takeControl',
                text: 'TAKE CONTROL',
                //color: 'aa00c7',
                color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
                user: newCommand.user || ''
            })
        }
        // eslint-disable-next-line	
    }, [isControlTaken]);

    useEffect(() => {
        /* Make the heart beat to pulse when we load from back-end and have no overlay for adding commands */
        if (overlayDisplay === 'none') {
            dispatch(setHeartBeat({
                className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
                color: connectionStatus ? 'lightgreen' : 'red',
                text: connectionStatus ? 'Connected' : 'No connection'
            }));
        }
        // eslint-disable-next-line 
    }, [overlayDisplay]);

    /* Update the hook to shows when there is no connection with back-end OR user is onopen with heart beat signal */
    useEffect(() => {
        dispatch(setHeartBeat({
            className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
            color: connectionStatus ? 'lightgreen' : 'red',
            text: connectionStatus ? 'Connected' : 'No connection'
        }));

        // eslint-disable-next-line 
    }, [connectionStatus]);

    return (
        <div className={classes.americanFootballWrapper}>

            {/* This is Global OVERLAY div! overlayDisplay is 'none' or 'block' depend on if the command is processed */}
            <div className={overlayDisplay === 'none' ? classes.overlay_sending_commands_none : classes.overlay_sending_commands_block}></div>


            <div className={classes.match_data}>
                <div className={classes.event_info}>
                    <div className={classes.heart_beat_league_information_field}>
                        <NavBar data={data} allStatusesAvailable={allStatusesAvailable} />
                        <div className={classes.leagueInformation}>
                            {data.stage + ' ' + data.tournament + ' ' + data.startdate}
                        </div>
                    </div>

                    <div className={classes.table}>
                        <FixtureTable teams={teams} score={score} />
                    </div>

                    <div className={classes.clockField}>
                        <div className={classes.clockDisplay}>{clockValue}</div>
                        <div className={classes.startStopClock}>
                            <div>
                                <Button
                                    id="startStopClockButton"
                                    style={Object.assign({ display: activeStatuses.includes(currentStatus) ? 'block' : 'none', backgroundColor: startStopClock.color === "primary" ? "lightgreen" : "#CD5C5C" }, styles.startStopClockButton)}
                                    variant="contained"
                                    //color={startStopClock.color === "primary" ? "primary" : "secondary"}
                                    size="small"
                                    onClick={() => {
                                        handleSendMainCommands(commandId, 'command', startStopClock.key, 0, (currentStatus * 1), true, false, false, '', '', false);
                                    }}
                                >
                                    {startStopClock.name}
                                </Button>

                                {/* <SRTMainButton text={'Start clock'} commandKey={'startClock'} team={0} popUp={false} disabled={false} handleClickOpenPopUp={handleClickOpenPopUp} handleSendMainCommands={handleSendMainCommands} handleSendStatus={handleSendStatus} /> */}
                            </div>

                            {/* <form autoComplete="off" className={classes.form_root}> */}
                            <FormControl id="" className={classes.formControl}>
                                <Input
                                    value={offsetMin || ''}
                                    placeholder='Min'
                                    className={classes.inputFields}
                                    onChange={e => {
                                        const value = e.target.value === '' ? 0 : parseInt(e.target.value);
                                        const newGameTime = value * 60 + offsetSec + gameTime;
                                        if ((newGameTime >= 0 && newGameTime <= maxGameTime)) {
                                            setOffsetMin(value);
                                        }
                                    }}
                                    //className={classes.input}
                                    inputProps={{
                                        className: classes.inputProps,
                                        min: -15,
                                        max: 15,
                                        type: 'number',
                                        'aria-labelledby': 'input-offset-minutes',
                                    }}
                                />
                            </FormControl>

                            <FormControl id="" className={classes.formControl}>
                                <Input
                                    value={offsetSec || ''}
                                    placeholder='Sec'
                                    className={classes.inputFields}
                                    onChange={e => {
                                        const value = e.target.value === '' ? 0 : parseInt(e.target.value);
                                        const newGameTime = value + (offsetMin) * 60 + gameTime;
                                        if ((value > -60 && value < 60 && newGameTime >= 0 && newGameTime <= maxGameTime)) {
                                            setOffsetSec(value);
                                        }
                                    }}
                                    inputProps={{
                                        className: classes.inputProps,
                                        min: -59,
                                        max: 59,
                                        type: 'number',
                                        'aria-labelledby': 'input-offset-seconds',
                                    }}
                                />
                            </FormControl>
                            {/* </form> */}

                            <div>
                                <Button
                                    id="set-button"
                                    style={styles.startStopClockButton}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        handleSendMainCommands(commandId, 'command', 'offset', 0, (currentStatus * 1), true, false, false, '', offsetMin * 60 + offsetSec, false);
                                    }}
                                >
                                    Set
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.tabs}>
                    <SimpleTabs
                        handleSendMainCommands={handleSendMainCommands} data={data} lock={lock}
                        popUpCommand={popUpCommand}
                        setPopUpCommand={setPopUpCommand}
                        commandLogArrays={JSON.parse(JSON.stringify(commandLogArrays))}
                        sliderValue={sliderValue} setSliderValue={setSliderValue}
                        absoluteLeftProperty={absoluteLeftProperty} setAbsoluteLeftProperty={setAbsoluteLeftProperty}
                        sliderDirection={sliderDirection} />
                </div>

                <div className={classes.command_log}>
                    <div className={classes.commands}>
                        <CommandFilter commandLogArrays={JSON.parse(JSON.stringify(commandLogArrays))} />
                    </div>
                </div>

                <div className={classes.reset_field}>
                    <div className="opened_apps">
                        {/* <div>Opened Callcenters:</div> */}
                        {/* <div>Viewers etc:</div> */}
                    </div>
                    <div>
                        <Button
                            id="btn-reset"
                            className={classes.buttons}
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => {
                                /* pass setOverlayDisplay as parameter */
                                popupReset(resetObj, dispatch, classes);
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

let styles = {
    startStopClockButton: {
        fontSize: '1vw',
        color: 'white'
    }
}