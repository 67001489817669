import { makeStyles } from "@material-ui/core";

export const useLastCommandFieldStyles = makeStyles((theme) => ({
    set_status_delete_last_command_field: {
        height: "90%",
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
    },
    last_command_field: {
        width: "65%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    last_command_button: {
        height: "30%",
    },
    hide: {
        display: "none",
    },
}));
