
import { makeStyles } from "@material-ui/core";

export const useSimpleTabsStyle = makeStyles((theme) => ({
    root: {
        display: 'block',
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        height: '100%',
        margin: 0,
        padding: 0,
        //backgroundColor : theme.palette.background.paper,
    },
    select: {
        "& ul": {
            backgroundColor: "#f7f5f5",
        },
        "& li": {
            fontSize: '0.9em',
            fontStyle: 'oblique',
        },
    },
    updatesEditCancelGameInfo_appbar: {
        height: '10%',
        //borderRadius: '15px',
        //zIndex: 1001,
        //position: 'relative',
    },
    everyUpTab: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        height: '90%',
        //borderRadius: '15px',
    },
    gameInfoTabField: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        height: '40%',
        //borderRadius: '15px',
    },
    // formControl: {
    //     minWidth: '50%',
    //     //margin: theme.spacing(1),
    // },
    tabs: {
        //minHeight: '50%',
        //maxHeight: '60%',
        height: '55%',
        margin: '0% 0% 0% 0.5%',
        //overflowY: 'auto',
    },
    form_root: {
        width: '60%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    home_away_participants_field: {
        margin: '3% 1% 0% 1%',
        height: '30%',
        //width: '100%',    
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    home_away_buttons: {
        height: '35%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    comments: {
        margin: '1% 0% 0% 0%',
        height: '11%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        //flexDirection: 'column',
    },
    field_buttons: {
        //pointerEvents: 'none',
        height: '5vh', /* to fit at smaller browser sizes - SRT-1593 */
        width: '40%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
    },
    both_team_buttons: {
        height: '100%',
        width: '30%',
        gap: '10px', /* It maskes space between elements in Flexbox field */
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        //zIndex: 1001,
    },
    game_info_field: {
        padding: '0.5% 0 0 0.5%',
        height: '40%',
        width: '99%',
        display: 'flex',
        //justifyContent: 'space-around',
        //alignItems: 'center',
        flexDirection: 'column',
        //backgroundColor: 'lightblue'
    },
    game_info: {
        //padding: '1%',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    game_info_child_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '99%',
    },
    game_info_colors_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '32%',
    },
    formControl: {
        margin: 2,
        width: '90%',
        maxWidth: 500,
        textTransform: 'capitalize'
    },
    set_status_delete_last_command_field: {
        height: '90%',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
    },
    boxP3: {
        padding: '0px',
        height: '100%',
        //backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
    },
    TypographyDiv: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    every_overlay: {
        //margin: '1%',
        // border: '0.5px solid' ,
        // borderRadius: '15px',
        // height : '100%',
        // width: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        // backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        //overflowY: 'auto',
    },

}));