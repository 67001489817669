import { makeStyles } from "@material-ui/core";

export const useOverlayListStyles = makeStyles((theme) => ({
    overlay_sending_commands_block: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'block',
        flexDirection: 'column',
        zIndex: 1001,
        //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)'
    },
    overlay_sending_commands_none: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'none',
        flexDirection: 'column',
        zIndex: 1001,
    },
}));
