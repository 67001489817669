import { makeStyles } from "@material-ui/core";

export const useAmericanFootballStyles = makeStyles((theme) => ({
    overlay_sending_commands_block: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'block',
        flexDirection: 'column',
        zIndex: 1001,
        opacity: '0.5',
        backgroundColor: 'lightgrey',
        //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)'
    },
    overlay_sending_commands_none: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'none',
        flexDirection: 'column',
        zIndex: 1001,
        opacity: '0.5',
        backgroundColor: 'lightgrey',
        //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)'
    },
    americanFootballWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column'
    },
    event_info: {
        margin: '0 0 0 0.5%',
        //borderRadius: '15px',
        height: '20%',
        width: '99.5%',
        display: 'flex',
        //justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '1vw'
        //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
    },
    flex_divs: {
        display: 'flex',
        alignItems: 'center',
        width: '33%',
        justifyContent: 'center',
    },
    heart_beat_league_information_field: {
        height: '70%',
        display: 'flex',
        alignItems: 'end',
        width: '20%',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    leagueInformation: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.9em',
    },
    table: {
        width: '60%',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1vw'
    },
    clockField: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '25%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    startStopClock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        //justifyContent: 'inherit',
        alignItems: 'center'
    },
    clockDisplay: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '3em'
    },
    match_data: {
        //paddingTop: '0.5%',
        width: '100%',
        height: '99%',
        overflow: 'auto',
    },
    tabs: {
        height: '65%',
        //margin: '0% 0% 0% 0.5%',
        margin: '0% 0% 0% 0%',
    },
    command_log: {
        //backgroundColor: '#f7f7f7',
        margin: '0.5% 0.5% 0% 0.5%',
        height: '45%',
        //width: '100%',
        //height : '1000px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '15px',
        /* zIndex: 1001,
        position: 'relative', */
    },
    commands: {
        height: '100%',
        width: '100%',
        //overflow: 'auto',
    },
    // statistics: {
    //     margin: '1%',
    //     display: 'flex',
    //     justifyContent: 'flex-start',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     height: '100%',
    //     width: '30%',
    // },
    reset_field: {
        margin: '0% 1%',
        height: '7%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        //fontSize: '1vw',
    },
    buttons: {
        margin: '1%',
        borderRadius: 15,
        fontSize: '1vw',
        //padding: '1%',
    },
    yes_no_field: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    clock: {
        display: 'none',
    },
    status_and_game_time_clock: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    stat: {
        padding: '0.9%',
        display: 'flex',
        justifyContent: 'space-between',
        width: '99%',
        borderBottom: '1px dashed'
    },
    teams_and_score_field: {
        flexDirection: 'column',
        width: '70%',
        height: '100%',
    },
    teams_and_score: {
        display: 'flex',
        justifyContent: 'center',
        //fontWeight: 'bold',
        fontSize: '1.3em',
        //gap: '1%',
        width: '100%',
    },
    divs_team_name_field: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '33%',
    },
    score: {
        padding: '0 2%',
        border: '1px solid',
        borderRadius: '15px',
        fontSize: '1.8em',
    },
    heart_beat_field: {
        display: 'flex',
        //justifyContent: 'space-around',
    },
    heart_beat: {
        borderRadius: '15px',
        //backgroundColor: 'lightgreen',
    },
    form_root: {
        width: '30%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        textAlign: 'center',
        margin: '2%',
        width: '15%',
        maxWidth: 500,
        textTransform: 'capitalize',
    },
    inputFields: {
        fontSize: '0.9vw',
    },
    inputProps: {
        textAlign: 'center'
    }
}));
