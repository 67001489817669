import { AnyAction } from "redux";
import { RootState, actionTypes } from "./types";
import cloneDeep from "lodash.clonedeep";

export const initialState: RootState = {
    gameTime: 0,
    isClockRunning: false,
    currentWeather: "",
    currentPreMatchInfo: "",
    currentWind: "",
    currentAttendance: "",
    currentTossWinner: "",
    currentHomeChoice: "",
    currentAwayChoice: "",
    currentHomeColor1: "",
    currentHomeColor2: "",
    currentAwayColor1: "",
    currentAwayColor2: "",
    currentTeams: [
        {
            offensive: [],
            defensive: [],
            special: [],
        },
        {
            offensive: [],
            defensive: [],
            special: [],
        },
    ],
    currentSafety: "none",
    currentHomeFieldGoal: false,
    currentAwayFieldGoal: false,
    currentHomeTouchdownDisabled: false,
    currentAwayTouchdownDisabled: false,
    currentHomeOnePointMadeMissed: false,
    currentAwayOnePointMadeMissed: false,
    currentHomeTwoPointsMadeMissed: false,
    currentAwayTwoPointsMadeMissed: false,
    currentKickOff: "none",
    currentCloseOpenGame: "status_unsure",
    currentHomeActiveTeam: "Please select active team",
    currentAwayActiveTeam: "Please select active team",
    currentOverlayDisplay: "block",
    currentStatus: 0,
    currentHomeDown: "",
    currentAwayDown: "",
    currentHomeYards: 0,
    currentAwayYards: 0,
    currentTeamPossesion: "none",
    currentSnap: "none",
    currentFirstServeDisplay: "block",
    currentUser: "",
    eventInfo: {},
    score: {},
    buttonBlink: 'send_button_field',
    overallGameScore: [0, 0],
    currentSportSuggestedStatus: {
        tabletennis: 1,
        americanfootball: 26
    },
    currentTeamButtonsDisabled: {
        home: {
            serve: true,
            others: true
        },
        away: {
            serve: true,
            others: true
        }
    },
    currentOpenConfirmPopUp: {
        type: 'confirm',
        open: false,
        cancel_yes: 'none',
        ok: 'none'
    },
    currentUncfPointId: 0,
    currentPopUpCommand: {
        tabletennis: {
            key: '',
            team: 0,
            confirmed: false,
            id: 0,
            modified: false,
            value: ''
        },
        americanfootball: {

        }
    },
    commandLogArrays: {
        all: [],
        points: [],
        comments: [],
        info: [],
        edit_cancel_commands: [],
        deleted_commands_ids: [],
        map_command_log: {}
    },
    currentGameScoreToStatusId: {},
    currentMasterCloseGame: false,
    currentMSFStatus: 'Unknown',
    currentLastIdForUpdateStatus: 0,
    currentLastCnfPointId: 0,
    currentIsDisabledTakenControl: true,
    mapIdToActionId: {},
    commandId: 1,
    commandInc: 1,
    feedCommandInc: 1,
    connectionStatus: false,
    heartBeat: {
        className: 'heart_beat',
        color: '#F08080',
        text: 'Loading...'
    },
    lock: {
        display: 'block',
        key: 'takeControl',
        text: 'TAKE CONTROL',
        color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
        user: ''
    },
    authorized: false, 
    config: {},
    username: '', 
    token: '', 
    organizationId: ''
};

export const reducer = (state = initialState, action: AnyAction): RootState => {
    switch (action.type) {
        case actionTypes.setGameTime: {
            return {
                ...state,
                gameTime:
                    action.payload > 1
                        ? action.payload
                        : state.isClockRunning && state.gameTime > 0
                        ? state.gameTime - action.payload
                        : state.gameTime,
            };
        }
        case actionTypes.stopClock: {
            return {
                ...state,
                isClockRunning: action.payload,
            };
        }
        case actionTypes.startClock: {
            return {
                ...state,
                isClockRunning: action.payload,
            };
        }
        case actionTypes.setWeather: {
            return {
                ...state,
                currentWeather: action.payload,
            };
        }
        case actionTypes.setPreMatchInfo: {
            return {
                ...state,
                currentPreMatchInfo: action.payload,
            };
        }
        case actionTypes.setWind: {
            return {
                ...state,
                currentWind: action.payload,
            };
        }
        case actionTypes.setAttendance: {
            return {
                ...state,
                currentAttendance: action.payload,
            };
        }
        case actionTypes.setTossWinner: {
            return {
                ...state,
                currentTossWinner: action.payload,
            };
        }
        case actionTypes.setHomeChoice: {
            return {
                ...state,
                currentHomeChoice: action.payload,
            };
        }
        case actionTypes.setAwayChoice: {
            return {
                ...state,
                currentAwayChoice: action.payload,
            };
        }
        case actionTypes.setHomeColor1: {
            return {
                ...state,
                currentHomeColor1: action.payload,
            };
        }
        case actionTypes.setHomeColor2: {
            return {
                ...state,
                currentHomeColor2: action.payload,
            };
        }
        case actionTypes.setAwayColor1: {
            return {
                ...state,
                currentAwayColor1: action.payload,
            };
        }
        case actionTypes.setAwayColor2: {
            return {
                ...state,
                currentAwayColor2: action.payload,
            };
        }
        case actionTypes.setPlayers: {
            const { team, type, index, player } = action.payload;

            const tempCurrentTeams = [...state.currentTeams];
            tempCurrentTeams[team - 1][type][index] = cloneDeep(player);

            return {
                ...state,
                currentTeams: tempCurrentTeams,
            };
        }

        case actionTypes.setCloseOpenGame: {
            return {
                ...state,
                currentCloseOpenGame: action.payload,
            };
        }

        case actionTypes.setOverlayDisplay: {
            return {
                ...state,
                currentOverlayDisplay: action.payload,
            };
        }

        case actionTypes.setStatus: {
            return {
                ...state,
                currentStatus: action.payload,
            };
        }

        case actionTypes.setCurrentUser: {
            return {
                ...state,
                currentUser: action.payload,
            };
        }

        case actionTypes.setSportSuggestedStatus: {
            return {
                ...state,
                currentSportSuggestedStatus: action.payload,
            };
        }

        case actionTypes.setOpenConfirmPopUp: {
            return {
                ...state,
                currentOpenConfirmPopUp: action.payload,
            };
        }

        case actionTypes.setPopUpCommand: {
            return {
                ...state,
                currentPopUpCommand: action.payload,
            };
        }

        case actionTypes.setMasterCloseGame: {
            return {
                ...state,
                currentMasterCloseGame: action.payload,
            };
        }

        case actionTypes.setMSFStatus: {
            return {
                ...state,
                currentMSFStatus: action.payload,
            };
        }

        case actionTypes.setLastIdForUpdateStatus: {
            return {
                ...state,
                currentLastIdForUpdateStatus: action.payload,
            };
        }

        case actionTypes.setIsDisabledTakenControl: {
            return {
                ...state,
                currentIsDisabledTakenControl: action.payload,
            };
        }

        case actionTypes.setMapIdToActionId: {
            return {
                ...state,
                mapIdToActionId: action.payload,
            };
        }

        case actionTypes.setCommandId: {
            return {
                ...state,
                commandId: action.payload,
            };
        }

        case actionTypes.setCommandInc: {
            return {
                ...state,
                commandInc: action.payload,
            };
        }

        case actionTypes.setFeedCommandInc: {
            return {
                ...state,
                feedCommandInc: action.payload,
            };
        }

        case actionTypes.setConnectionStatus: {
            return {
                ...state,
                connectionStatus: action.payload,
            };
        }

        case actionTypes.setHeartBeat: {
            return {
                ...state,
                heartBeat: action.payload,
            };
        }

        case actionTypes.setEventInfo: {
            return {
                ...state,
                eventInfo: action.payload,
            };
        }

        case actionTypes.setScore: {
            return {
                ...state,
                score: action.payload,
            };
        }

        case actionTypes.setLock: {
            return {
                ...state,
                lock: action.payload,
            };
        }

        case actionTypes.setButtonBlink: {
            return {
                ...state,
                buttonBlink: action.payload,
            };
        }

        case actionTypes.setCommandLogArrays: {
            return {
                ...state,
                commandLogArrays: action.payload,
            };
        }


        case actionTypes.setOverallGameScore: {
            return {
                ...state,
                overallGameScore: action.payload,
            };
        }

        case actionTypes.setConfig: {
            return {
                ...state,
                config: action.payload,
            };
        }

        case actionTypes.setAuthorized: {
			return {
				...state,
				authorized: true
			};
		}

		case actionTypes.setUsername: {
			return {
				...state,
				username: action.payload
			};
		}

		case actionTypes.setAuth0Token: {
			return {
				...state,
				token: action.payload
			};
		}

		case actionTypes.setOrganizationId: {
			return {
				...state,
				organizationId: action.payload
			};
		}

        /* ====================================== */
        /* American Football - sport specific  */

        case actionTypes.setSafety: {
            return {
                ...state,
                currentSafety: action.payload,
            };
        }

        case actionTypes.setHomeFieldGoal: {
            return {
                ...state,
                currentHomeFieldGoal: action.payload,
            };
        }

        case actionTypes.setAwayFieldGoal: {
            return {
                ...state,
                currentAwayFieldGoal: action.payload,
            };
        }

        case actionTypes.setHomeTouchdownDisabled: {
            return {
                ...state,
                currentHomeTouchdownDisabled: action.payload,
            };
        }

        case actionTypes.setAwayTouchdownDisabled: {
            return {
                ...state,
                currentAwayTouchdownDisabled: action.payload,
            };
        }

        case actionTypes.setHomeOnePointMadeMissed: {
            return {
                ...state,
                currentHomeOnePointMadeMissed: action.payload,
            };
        }

        case actionTypes.setAwayOnePointMadeMissed: {
            return {
                ...state,
                currentAwayOnePointMadeMissed: action.payload,
            };
        }

        case actionTypes.setHomeTwoPointsMadeMissed: {
            return {
                ...state,
                currentHomeTwoPointsMadeMissed: action.payload,
            };
        }

        case actionTypes.setAwayTwoPointsMadeMissed: {
            return {
                ...state,
                currentAwayTwoPointsMadeMissed: action.payload,
            };
        }

        case actionTypes.setKickOff: {
            return {
                ...state,
                currentKickOff: action.payload,
            };
        }

        case actionTypes.setHomeActiveTeam: {
            return {
                ...state,
                currentHomeActiveTeam: action.payload,
            };
        }

        case actionTypes.setAwayActiveTeam: {
            return {
                ...state,
                currentAwayActiveTeam: action.payload,
            };
        }

        case actionTypes.setHomeDown: {
            return {
                ...state,
                currentHomeDown: action.payload,
            };
        }

        case actionTypes.setAwayDown: {
            return {
                ...state,
                currentAwayDown: action.payload,
            };
        }

        case actionTypes.setHomeYards: {
            return {
                ...state,
                currentHomeYards: action.payload,
            };
        }

        case actionTypes.setAwayYards: {
            return {
                ...state,
                currentAwayYards: action.payload,
            };
        }

        case actionTypes.setTeamPossesion: {
            return {
                ...state,
                currentTeamPossesion: action.payload,
            };
        }

        case actionTypes.setSnap: {
            return {
                ...state,
                currentSnap: action.payload,
            };
        }

        /* ====================================== */
        /* TableTennis - sport specific */

        case actionTypes.setFirstServeDisplay: {
            return {
                ...state,
                currentFirstServeDisplay: action.payload,
            };
        }

        case actionTypes.setTeamButtonsDisabled: {
            return {
                ...state,
                currentTeamButtonsDisabled: action.payload,
            };
        }

        case actionTypes.setUncfPointId: {
            return {
                ...state,
                currentUncfPointId: action.payload,
            };
        }

        case actionTypes.setGameScoreToStatusId: {
            return {
                ...state,
                currentGameScoreToStatusId: action.payload,
            };
        }

        case actionTypes.setLastCnfPointId: {
            return {
                ...state,
                currentLastCnfPointId: action.payload,
            };
        }

        default:
            return state;
    }
};
