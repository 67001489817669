import { makeStyles } from "@material-ui/core";

export const useAppBarStyle = makeStyles((theme) => ({
    root: {
        display: "block",
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        height: "100%",
        margin: 0,
        padding: 0,
        //backgroundColor : theme.palette.background.paper,
    },
    select: {
        "& ul": {
            backgroundColor: "#f7f5f5",
        },
        "& li": {
            fontSize: "0.9em",
            fontStyle: "oblique",
        },
    },
    updatesEditCancelGameInfo_appbar: {
        // height: "10%",
        //borderRadius: '15px',
        //zIndex: 1001,
        //position: 'relative' as 'relative',
    },
    everyUpTab: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        backgroundImage:
            "linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)",
        height: "90%",
        //borderRadius: '15px',
    },
    gameInfoTabField: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        backgroundImage:
            "linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)",
        height: "40%",
        //borderRadius: '15px',
    },
    formControl: {
        minWidth: "50%",
        //margin: theme.spacing(1),
    },
}));
