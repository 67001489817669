import React, { useState, useEffect } from 'react'; // we need this to make JSX compile
import TennisTable from './TableTennis/TennisTable';
import AmericanFootball from './AmericanFootball/AmericanFootball';
import ReactLoading from "react-loading";
import { useCollectionToolStyles } from './CollectionToolStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionStatus, setLock } from '../store/actions';
import cloneDeep from 'lodash.clonedeep';
import { selectEventInfo, selectAuthorized } from '../store/selectors';
import { connect, getData } from './CollectionTool.utils'
import ErrorPage from './ErrorPage';

const CollectionTool = ({ sport, event_id, page, url } : any) => {

    const classes = useCollectionToolStyles();

    const [db_data, setDbData] = useState([]);

    const dispatch = useDispatch();

    const [newCommand, setNewCommand] = useState([]);
    const event_info = useSelector(selectEventInfo);
    const isAuthorized = cloneDeep(useSelector(selectAuthorized));
    const [message, setMessage] = useState({ type: "load", value: "" });
    const [isControlTaken, setIsControlTaken] = useState(false);

    const setOnline = () => dispatch(setConnectionStatus(true));
    const setOffline = () => dispatch(setConnectionStatus(false));

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isAuthorized){
            /* get test data from the json file for TEST event */
            if (event_id.startsWith('test')) { /* this is for unit testing */
                getData(sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand);
            } else {
                connect(sport, event_id, page, setMessage,setDbData, setIsControlTaken, setNewCommand);
            }
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, [isAuthorized]);

    useEffect(() => {
        if (!isControlTaken) {
            dispatch(setLock({
                display: 'block',
                key: 'takeControl',
                text: 'TAKE CONTROL',
                color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
                user: (newCommand['user'] || '')
            }));
        }
        // eslint-disable-next-line	
    }, [isControlTaken]);

    if (JSON.stringify(event_info) !== '{}' && typeof event_info.error === 'undefined' && page === 'desktop' && message.type !== 'error') {
        return (
            <div data-testid="sport-page" data-cy="sport-page" className={classes.collection_tool}>
                {
                    {
                        'tabletennis': <TennisTable db_data={db_data} newCommand={newCommand} isControlTaken={isControlTaken} />,
                        'americanfootball': <AmericanFootball data={event_info} db_data={db_data} newCommand={newCommand} isControlTaken={isControlTaken} />
                    }[sport]
                }
            </div>
        );
    } else if (JSON.stringify(event_info) !== '{}' && typeof event_info.error !== 'undefined') {
        return (<ErrorPage text={"No Event Info Data for event " + event_id} />);
    } else if (JSON.stringify(event_info) === '{}' && page === 'desktop' && message.type !== 'error') {
        return (
            <div data-testid="loading-screen" data-cy="loading-screen" className={classes.loading_screen}>
                <h1>Loading...</h1>
                <ReactLoading type={"spin"} color={"orange"} width={'30%'} />
            </div>
        );
    } else if (message.type !== 'error') {
        return (<ErrorPage text={"Event ID " + event_id + "does not exists or Missing/Wrong url parameters"} />);
    } else {
        return (<ErrorPage text={message.value} />);
    }

}

export default CollectionTool;
