import { AnyAction } from "redux";
import { actionTypes , Player } from "./types";

export const setGameTime = (gameTime?: number): AnyAction => ({
    type: actionTypes.setGameTime,
    payload: gameTime ? gameTime : 1,
});

export const setIsClockRunning = (value: boolean): AnyAction => ({
    type: actionTypes.setIsClockRunning,
    payload: value,
});

export const stopClock = (): AnyAction => ({
    type: actionTypes.stopClock,
    payload: false,
});

export const startClock = (): AnyAction => ({
    type: actionTypes.startClock,
    payload: true,
});

export const setWeather = (value: string): AnyAction => ({
    type: actionTypes.setWeather,
    payload: value,
});

export const setPreMatchInfo = (value: string): AnyAction => ({
    type: actionTypes.setPreMatchInfo,
    payload: value,
});

export const setWind = (value: string): AnyAction => ({
    type: actionTypes.setWind,
    payload: value,
});

export const setAttendance = (value: string): AnyAction => ({
    type: actionTypes.setAttendance,
    payload: value,
});

export const setTossWinner = (value: string): AnyAction => ({
    type: actionTypes.setTossWinner,
    payload: value,
});

export const setHomeChoice = (value: string): AnyAction => ({
    type: actionTypes.setHomeChoice,
    payload: value,
});

export const setAwayChoice = (value: string): AnyAction => ({
    type: actionTypes.setAwayChoice,
    payload: value,
});

export const setHomeColor1 = (value: string): AnyAction => ({
    type: actionTypes.setHomeColor1,
    payload: value,
});

export const setHomeColor2 = (value: string): AnyAction => ({
    type: actionTypes.setHomeColor2,
    payload: value,
});

export const setAwayColor1 = (value: string): AnyAction => ({
    type: actionTypes.setAwayColor1,
    payload: value,
});

export const setAwayColor2 = (value: string): AnyAction => ({
    type: actionTypes.setAwayColor2,
    payload: value,
});

export const setPlayers = (
    team: number,
    type: string,
    index: number,
    player: Player,
): AnyAction => ({
    type: actionTypes.setPlayers,
    payload: { team, type, index, player },
});

export const setCloseOpenGame = (value: string): AnyAction => ({
    type: actionTypes.setCloseOpenGame,
    payload: value,
});

export const setCurrentUser = (value: string): AnyAction => ({
    type: actionTypes.setCurrentUser,
    payload: value,
});


export const setSportSuggestedStatus = (value: any): AnyAction => ({
    type: actionTypes.setSportSuggestedStatus,
    payload: value,
});

export const setOpenConfirmPopUp = (value: object): AnyAction => ({
    type: actionTypes.setOpenConfirmPopUp,
    payload: value,
});

export const setPopUpCommand = (value: object): AnyAction => ({
    type: actionTypes.setPopUpCommand,
    payload: value,
});

export const setMasterCloseGame = (value: boolean): AnyAction => ({
    type: actionTypes.setMasterCloseGame,
    payload: value,
});

export const setMSFStatus = (value: string): AnyAction => ({
    type: actionTypes.setMSFStatus,
    payload: value,
});

export const setLastIdForUpdateStatus = (value: number): AnyAction => ({
    type: actionTypes.setLastIdForUpdateStatus,
    payload: value,
});

export const setIsDisabledTakenControl = (value: boolean): AnyAction => ({
    type: actionTypes.setIsDisabledTakenControl,
    payload: value,
});

export const setMapIdToActionId = (value: object): AnyAction => ({
    type: actionTypes.setMapIdToActionId,
    payload: value,
});

export const setCommandId = (value: number): AnyAction => ({
    type: actionTypes.setCommandId,
    payload: value,
});

export const setCommandInc = (value: number): AnyAction => ({
    type: actionTypes.setCommandInc,
    payload: value,
});

export const setFeedCommandInc = (value: number): AnyAction => ({
    type: actionTypes.setFeedCommandInc,
    payload: value,
});

export const setConnectionStatus = (value: boolean): AnyAction => ({
    type: actionTypes.setConnectionStatus,
    payload: value,
});

export const setHeartBeat = (value: object): AnyAction => ({
    type: actionTypes.setHeartBeat,
    payload: value,
});

export const setEventInfo = (value: object): AnyAction => ({
    type: actionTypes.setEventInfo,
    payload: value,
});

export const setScore = (value: object): AnyAction => ({
    type: actionTypes.setScore,
    payload: value,
});

export const setLock = (value: object): AnyAction => ({
    type: actionTypes.setLock,
    payload: value,
});

export const setButtonBlink = (value: string): AnyAction => ({
    type: actionTypes.setButtonBlink,
    payload: value,
});

export const setCommandLogArrays = (value: string): AnyAction => ({
    type: actionTypes.setCommandLogArrays,
    payload: value,
});

export const setOverallGameScore = (value: object): AnyAction => ({
    type: actionTypes.setOverallGameScore,
    payload: value,
});

export const setAuthorized = (): AnyAction => ({
    type: actionTypes.setAuthorized,
});

export const setUsername = ( username: string ) => ({
	type: actionTypes.setUsername,
	payload: username
});

export const setAuth0Token = ( token: string ) => ({
	type: actionTypes.setAuth0Token,
	payload: token
});

export const setOrganizationId = ( organizationId: string ) => ({
	type: actionTypes.setOrganizationId,
	payload: organizationId
});

export const setConfig = (value: object): AnyAction => ({
    type: actionTypes.setConfig,
    payload: value,
});

/* ====================================== */
/* American Football - sport specific  */

export const setSafety = (value: string): AnyAction => ({
    type: actionTypes.setSafety,
    payload: value,
});

export const setHomeFieldGoal = (value: boolean): AnyAction => ({
    type: actionTypes.setHomeFieldGoal,
    payload: value,
});

export const setAwayFieldGoal = (value: boolean): AnyAction => ({
    type: actionTypes.setAwayFieldGoal,
    payload: value,
});

export const setHomeTouchdownDisabled = (value: boolean): AnyAction => ({
    type: actionTypes.setHomeTouchdownDisabled,
    payload: value,
});

export const setAwayTouchdownDisabled = (value: boolean): AnyAction => ({
    type: actionTypes.setAwayTouchdownDisabled,
    payload: value,
});

export const setHomeOnePointMadeMissed = (value: boolean): AnyAction => ({
    type: actionTypes.setHomeOnePointMadeMissed,
    payload: value,
});

export const setAwayOnePointMadeMissed = (value: boolean): AnyAction => ({
    type: actionTypes.setAwayOnePointMadeMissed,
    payload: value,
});

export const setHomeTwoPointsMadeMissed = (value: boolean): AnyAction => ({
    type: actionTypes.setHomeTwoPointsMadeMissed,
    payload: value,
});

export const setAwayTwoPointsMadeMissed = (value: boolean): AnyAction => ({
    type: actionTypes.setAwayTwoPointsMadeMissed,
    payload: value,
});

export const setKickOff = (value: string): AnyAction => ({
    type: actionTypes.setKickOff,
    payload: value,
});

export const setHomeActiveTeam = (value: string): AnyAction => ({
    type: actionTypes.setHomeActiveTeam,
    payload: value,
});

export const setAwayActiveTeam = (value: string): AnyAction => ({
    type: actionTypes.setAwayActiveTeam,
    payload: value,
});

export const setOverlayDisplay = (value: string): AnyAction => ({
    type: actionTypes.setOverlayDisplay,
    payload: value,
});

export const setStatus = (value: number): AnyAction => ({
    type: actionTypes.setStatus,
    payload: value,
});

export const setHomeDown = (value: string): AnyAction => ({
    type: actionTypes.setHomeDown,
    payload: value,
});

export const setAwayDown = (value: string): AnyAction => ({
    type: actionTypes.setAwayDown,
    payload: value,
});

export const setHomeYards = (value: string): AnyAction => ({
    type: actionTypes.setHomeYards,
    payload: value,
});

export const setAwayYards = (value: string): AnyAction => ({
    type: actionTypes.setAwayYards,
    payload: value,
});

export const setTeamPossesion = (value: string): AnyAction => ({
    type: actionTypes.setTeamPossesion,
    payload: value,
});

export const setSnap = (value: string): AnyAction => ({
    type: actionTypes.setSnap,
    payload: value,
});

/* ====================================== */
/* Table Tennis - sport specific */

export const setFirstServeDisplay = (value: string): AnyAction => ({
    type: actionTypes.setFirstServeDisplay,
    payload: value,
});

export const setTeamButtonsDisabled = (value: object): AnyAction => ({
    type: actionTypes.setTeamButtonsDisabled,
    payload: value,
});

export const setUncfPointId = (value: number): AnyAction => ({
    type: actionTypes.setUncfPointId,
    payload: value,
});

export const setGameScoreToStatusId = (value: object): AnyAction => ({
    type: actionTypes.setGameScoreToStatusId,
    payload: value,
});

export const setLastCnfPointId = (value: number): AnyAction => ({
    type: actionTypes.setLastCnfPointId,
    payload: value,
});


