import { makeStyles } from "@material-ui/core";

export const useSimpleTabsStyles = makeStyles((theme) => ({
	form_root: {
        width: '60%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    predefined_field: {
        display: 'flex',
        width: '33%',
        justifyContent: 'center',
        //justifyContent: 'flex-start',
    },
    contolled_by: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    all_texts: {
        display: 'flex',
        width: '33%',
        justifyContent: 'center',
    },
    home_away_participants_field: {
        margin: '0% 1%',
        height: '11%',
        //width: '100%',    
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        //borderBottom: '1px solid black',
    },
    home_away_buttons: {
        height: '76%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    comments: {
        //margin: '1%',
        height: '12%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        //flexDirection: 'column',
    },
    field_buttons: {
        //pointerEvents: 'none',
        height: '100%',
        width: '38%',
        display: 'flex',
        //gap: '8%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
    },
    selectActiveTeamField: {
        paddingTop: '0%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        //height: '10%',
        width: '99%',
        //flexDirection: 'column',
    },
    teamAbbreviation: {
        fontSize: '1.7vw',
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    selectActiveTeamGrey: {
        backgroundColor: 'grey',
        opacity: '0.4',
        width: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        borderRadius: '5px',
        fontSize: '1.3vw',
        fontStyle: 'bold',
        color: 'black',
    },
    selectActiveTeamGreen: {
        backgroundColor: 'lightgreen',
        opacity: '1',
        width: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        borderRadius: '5px',
        fontSize: '1.3vw',
        fontStyle: 'bold',
        color: 'black',
    },
    lock_overlay_field_none: {
        display: 'none', 
        //pointerEvents: 'none',
        //pointerEvents: 'all' ,
        position: 'absolute',
        //height : '15%',
        backgroundColor: 'rgb(0 0 0)',
        //width: '44%',
        //borderRadius: '10px',
        zIndex: 1000,
        opacity: 0.4,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    lock_overlay_field_block: {
        display: 'block',
        position: 'absolute',
        //height : '15%',
        backgroundColor: 'rgb(0 0 0)',
        //width: '44%',
        //borderRadius: '10px',
        zIndex: 1000,
        opacity: 0.4,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    upper_team_buttons: {
        //display: 'flex',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        width: '95%',
        minHeight: '45%',
        //gap: '1%',
    },
    /* This class is used as we need the row (tochdown button row) to be fixed when hidden , and to not shrink */
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        height: '30%',
    },
    down_team_buttons: {
        width: '96%',
    },
    gap: {
        border: '1px solid black',
        width: '99%'
    },
    both_team_buttons: {
        height: '100%',
        width: '22%',
        gap: '2.5%', /* It maskes space between elements in Flexbox field */
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '1%',
        //zIndex: 1001,
    },
    fieldSliderField: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',

    },
    fieldImage: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '88%',
        height: '100%',
    },
    fieldImageDirection: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        opacity: '0.3',
    },
    fieldSlider: {
        position: 'absolute',
        width: '3%',
        //height: '17.5%',
        height: '100%',
        opacity: '0.5',
        backgroundColor: 'blue',
    },
    yardSlider: {
        width: '88%',
    },
    yardsInputField: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    yardsButton: {
        width: '80%',
        backgroundColor: '#ffffa8'
    },
    game_info_field: {
        padding: '0.5% 0 0 0.5%',
        //height: '40%',
        width: '99%',
        display: 'flex',
        //justifyContent: 'space-around',
        //alignItems: 'center',
        flexDirection: 'column',
        //backgroundColor: 'lightblue'
    },
    game_info: {
        //padding: '1%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-between',
        width: '100%',
    },
    game_info_child_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '30%',
        //height: '9%',
    },
    game_info_colors_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '32%',
    },
    game_info_header: {
        borderRadius: '15px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'grey'
    },
    formControl: {
        margin: 2,
        width: '90%',
        maxWidth: 500,
        textTransform: 'capitalize'
    },
    participant_field: {
        fontWeight: 'bold',
        fontSize: '2.0vw',
        fontFamily: '"Times New Roman", Times, serif'
    },
    set_status_wrapper: {
        height: '90%',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
    },
    last_command_field: {
        width: '65%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    last_command_button: {
        height: '30%'
    },
    set_status_field: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '99%',
        //flexDirection: 'column',
    },
    set_status: {
        width: '50%',
        display: 'flex',
        flexGrow: 1,
    },
    setStatus_standby_field: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    close_game: {
        height: '100%',
        backgroundImage: 'linear-gradient(to top , #ff9a9e 75%, #fad0c4 99%, #fad0c4 100%)',
    },
    open_game: {
        height: '100%',
        backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
    },
    confirmScore: {
        backgroundImage: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)',
    },
    open_close_field: {
        height: '50%',
        //width: '50%'
    },
    timeouts: {
        backgroundImage: 'linear-gradient(to right, #ffecd2 0%, #fcb69f 100%)',
    },
    paddingAndHeight: {
        padding: '0px',
        height: '100%',
        //backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',

    },
    hide: {
        display: 'none'
    },
    TypographyDiv: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    lineUpWrapperField: {
        display: 'flex',
        height: '100%',
    },
    homeAwayLineupField: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '49%',
        height: '100%',
        padding: '0.3%',
        border: '1px solid'
    },
    homeAwayPlayersField: {
        paddingTop: '2%',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '84%',
    },
    every_overlay: {
        //margin: '1%',
        // border: '0.5px solid' ,
        // borderRadius: '15px',
        // height : '100%',
        // width: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        // backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        //overflowY: 'auto',
    },
    fullWidth: {
        width: '100%'
    },
    specialButtons: {
        color: 'white',
        backgroundColor: '#CD5C5C',
    },
    sliderThumb: {
        "& .MuiSlider-thumb": {
            marginRight: -6
        }
    },
    root: {
        display: 'block',
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        height: '100%',
        margin: 0,
        padding: 0,
        //backgroundColor : theme.palette.background.paper,
    },
    select: {
        "& ul": {
            backgroundColor: "#f7f5f5",
        },
        "& li": {
            fontSize: '1vw',
            fontStyle: 'oblique',
        },
    },
    updatesEditCancelGameInfo_appbar: {
        //marginLeft: '0.5%',
        height: '8%',
        //opacity: '0.9',
        backgroundColor: '#5c6bc0',
        //borderRadius: '15px',
        //zIndex: 1001,
        //position: 'relative',
    },
    everyUpTab: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        //backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        height: '90%',
        //borderRadius: '15px',
    },
    gameInfoTabField: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        //backgroundImage: 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 50%, #f5f7fa 50%, rgb(252 255 148) 100%)',
        height: '40%',
        //borderRadius: '15px',
    },
    otherFormControl: {
        minWidth: '50%',
        //margin: theme.spacing(1),
    },
    colorsField: {
        width: '47%',
        //margin: theme.spacing(1),
    },
    maxHeight: {
        height: '90%',
        maxHeight: '2.3vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: 45,
    },
    closeOpenGame: {

    },
    paper: {
        width: '30%',
        textAlign: 'center',
        borderRadius: '5px',
    },
    inputProps: {
        textAlign: 'center'
    }
}));