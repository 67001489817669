import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from '@material-ui/core/Button';
import { FormControl, TextField } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDispatch, useSelector } from "react-redux";
import { selectStatus, selectTeams, selectSportSuggestedStatus, selectOpenConfirmPopUp } from "../../store/selectors";
import { allStatusesAvailable, statusesHaveBeenThrough } from "./AmericanFootball";
import { useDialogConfirmPopupStyles } from './DialogConfirmPopupStyles';
import { setOpenConfirmPopUp } from '../../store/actions';

/* Import statuses for status dropdown in pop up for which we have been it */
export const statusesNeeded = () => {
    var arr = [];
    /* Sort statuses in Array and foreach to input them as a MenuItem to the dropdown */
    statusesHaveBeenThrough.sort(function (a, b) { return a - b }).forEach(element => {
        arr.push(<MenuItem key={element} value={element}>{allStatusesAvailable[element].title}</MenuItem>);

    });
    return arr;
}

function switchLabel(type, popUpCommand, suggestedStatus) {
    let result = '';

    switch (type) {
        case 'player':
            result = (popUpCommand.team === 1 ? 'Home ' : 'Away ') + 'Team ' + popUpCommand.team;
            break;

        case 'setStatus':
            result = 'Are you sure you want to change game status to ' + allStatusesAvailable[suggestedStatus].title;
            break;

        default:
            result = (popUpCommand.team === 0 ? '' : popUpCommand.team === 1 ? 'Home ' : 'Away ') + popUpCommand.key.charAt(0).toUpperCase() + popUpCommand.key.slice(1);
            break;
    }
    return result;
}

function renderHtml(currentStatus, statusesNeeded, handleChangeProp, type, playerDetails, popUpCommand, classes) {
    let result;

    switch (type) {

        case 'touchdown':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl disabled={true} id="touchdown_player_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                            <Select
                                id="select_touchdown_player"
                                defaultValue=''
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp('name')}
                            >
                                <MenuItem value="Doba">Doba</MenuItem>
                                <MenuItem value="Vaso">Vaso</MenuItem>
                                <MenuItem value="Ivan">Ivan</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                    <FormControl id="touchdown_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                </div>
            break;

        case 'safety':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl disabled={true} id="safety_player_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                            <Select
                                id="select_safety_player"
                                defaultValue=''
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp(type)}
                            >
                                <MenuItem value="Doba">Doba</MenuItem>
                                <MenuItem value="Vaso">Vaso</MenuItem>
                                <MenuItem value="Ivan">Ivan</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                    <FormControl id="safety_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                </div>
            break;

        case 'fieldGoalAttempt':
            //empty
            break;

        case 'fieldGoalMade':
        case 'fieldGoalMissed':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl disabled={true} id="fieldGoal_player_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                            <Select
                                id="select_fieldGoal_player"
                                defaultValue=''
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp(type)}
                            >
                                <MenuItem value="Doba">Doba</MenuItem>
                                <MenuItem value="Vaso">Vaso</MenuItem>
                                <MenuItem value="Ivan">Ivan</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </div>
            break;

        case 'onePointConversionMade':
        case 'onePointConversionMissed':
        case 'twoPointsConversionMade':
        case 'twoPointsConversionMissed':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl disabled={true} id={type + "_player_formControl"} className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                            <Select
                                id={"select_" + type + "_player"}
                                defaultValue=''
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp(type)}
                            >
                                <MenuItem value="Doba">Doba</MenuItem>
                                <MenuItem value="Vaso">Vaso</MenuItem>
                                <MenuItem value="Ivan">Ivan</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </div>
            break;

        case 'down':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="down_team_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Down Number')}</InputLabel>
                            <Select
                                id="select_down_number"
                                defaultValue=""
                                onChange={handleChangeProp(type)}
                            >
                                <MenuItem value={1}>1st</MenuItem>
                                <MenuItem value={2}>2nd</MenuItem>
                                <MenuItem value={3}>3rd</MenuItem>
                                <MenuItem value={4}>4th</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="down_team_formControl" className={classes.formControlYards}>
                            <InputLabel htmlFor="input_yards">{('Yards to scrimmage line')}</InputLabel>
                            <Input
                                id="down_input_yards"
                                className={classes.form_root}
                                //className={classes.input}
                                defaultValue=""
                                //margin="dense"
                                onChange={handleChangeProp('scrimmage_yards')}
                                //onChange={handleInputChange}
                                //onBlur={handleBlur}
                                inputProps={{
                                    //step: 10,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </FormControl>
                    </form>

                    <FormControl id="text_field_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                </div>
            break;

        case 'activeTeam':
            result =
                <div style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="activeTeam_formControl_team_type" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Team Type')}</InputLabel>
                            <Select
                                id="select_activeTeam_type"
                                defaultValue=''
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp('type')}
                            >
                                <MenuItem value="offensive">Offensive</MenuItem>
                                <MenuItem value="defensive">Defensive</MenuItem>
                                <MenuItem value="special">Special</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                    <FormControl id="activeTeam_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //className={classes.textField}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="activeTeam_formControl_status" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Status')}</InputLabel>
                            <Select
                                id="select_activeTeam_status"
                                defaultValue={currentStatus || ''}
                            //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                            //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                            onChange={handleChangeProp('timeoutStatus')}
                            >
                                {statusesNeeded()}
                            </Select>
                        </FormControl>
                    </form>
                </div>
            break;

        case 'penalty':
            result =
                <div style={{}}>
                    {/* <form autoComplete="off" className={classes.form_root}> */}
                    <FormControl disabled={true} id="penalty_player_formControl" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                        <Select
                            id="select_penalty_player"
                            defaultValue=''
                            //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                            //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                            onChange={handleChangeProp('name')}
                        >
                            <MenuItem value="Doba">Doba</MenuItem>
                            <MenuItem value="Vaso">Vaso</MenuItem>
                            <MenuItem value="Ivan">Ivan</MenuItem>
                        </Select>
                    </FormControl>
                    {/* </form> */}

                    {/* <form autoComplete="off" className={classes.form_root}> */}
                    <FormControl id="penalty_type_formControl" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Type')}</InputLabel>
                        <Select
                            id="select_penalty_type"
                            defaultValue=''
                            //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                            //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                            onChange={handleChangeProp('type')}
                        >
                            <MenuItem value="aaaa">aaaa</MenuItem>
                            <MenuItem value="bbbb">bbbb</MenuItem>
                            <MenuItem value="ccccc">ccccc</MenuItem>
                        </Select>
                    </FormControl>
                    {/* </form> */}

                    {/* <form autoComplete="off" className={classes.form_root}> */}
                    <FormControl id="penalty_yards_formControl" className={classes.formControlYards}>
                        <InputLabel htmlFor="penalty_input_yards">{('Yards to end zone')}</InputLabel>
                        <Input
                            id="penalty_input_yards"
                            className={classes.form_root}
                            //className={classes.input}
                            defaultValue=""
                            //margin="dense"
                            onChange={handleChangeProp('yards')}
                            //onBlur={handleBlur}
                            inputProps={{
                                //step: 10,
                                min: 0,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </FormControl>
                    {/* </form> */}

                    <FormControl id="penalty_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //className={classes.textField}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                    {/* <form autoComplete="off" className={classes.form_root}> */}
                    <FormControl id="penalty_formControl_status" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Status')}</InputLabel>
                        <Select
                            id="select_penalty_status"
                            defaultValue={currentStatus || ''}
                        //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                        //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                        onChange={handleChangeProp('timeoutStatus')}
                        >
                            {statusesNeeded()}
                        </Select>
                    </FormControl>
                    {/* </form> */}

                </div>
            break;

        case 'timeout':
            result =
                <div style={{}}>
                    <FormControl id="timeout_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //className={classes.textField}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                    {/* <form autoComplete="off" className={classes.form_root}> */}
                    <FormControl id="timeout_formControl_status" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Status')}</InputLabel>
                        <Select
                            id="select_timeout_status"
                            defaultValue={currentStatus || ''}
                        //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                        //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                        onChange={handleChangeProp('timeoutStatus')}
                        >
                            {statusesNeeded()}
                        </Select>
                    </FormControl>
                    {/* </form> */}
                </div>
            break;

        case 'challenge':
            result =
                <div style={{}}>
                    <FormControl id="challenge_formControl_outcome" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Outcome')}</InputLabel>
                        <Select
                            id="select_challenge_outcome"
                            defaultValue=''
                            //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                            //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                            onChange={handleChangeProp('outcome')}
                        >
                            <MenuItem value="Won">Won</MenuItem>
                            <MenuItem value="Lost">Lost</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl id="challenge_game_time" className={classes.formControl}>
                        <TextField
                            label={('Game time (mm:ss)')}
                            //className={classes.textField}
                            //value={"auto"}
                            //onChange={handleChangeCustomText}
                            inputProps={{
                                name: 'game_time'
                            }} />
                    </FormControl>

                    {/* <form className="form_root" autoComplete="off" className={classes.form_root}> */}
                    <FormControl id="challenge_formControl_status" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Status')}</InputLabel>
                        <Select
                            id="select_challenge_status"
                            defaultValue={currentStatus || ''}
                        //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                        //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                        onChange={handleChangeProp('timeoutStatus')}
                        >
                            {statusesNeeded()}
                        </Select>
                    </FormControl>
                    {/* </form> */}
                </div>
            break;

        case 'replayReview':
            result =
                <div style={{}}>
                    <FormControl id="replayReview_formControl_outcome" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Outcome')}</InputLabel>
                        <Select
                            id="select_replayReview_outcome"
                            defaultValue=''
                        //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                        //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                        //onChange={handleChangeValue}
                        >
                            <MenuItem value="Confirmed">Confirmed</MenuItem>
                            <MenuItem value="Overturned">Overturned</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            break;

        case 'player':

            result =
                <div className="renderHtmlPlayerDiv" style={{}}>
                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="lineup_player_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                            <Select
                                id="select_lineup_player"
                                defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.valueObj.type][popUpCommand.valueObj.order - 1]?.name || ''}
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                                //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                                onChange={handleChangeProp('name')}
                            >
                                <MenuItem value="Doba">Doba</MenuItem>
                                <MenuItem value="Vaso">Vaso</MenuItem>
                                <MenuItem value="Ivan">Ivan</MenuItem>
                            </Select>
                        </FormControl>
                    </form>

                    <form autoComplete="off" className={classes.form_root}>
                        <FormControl id="position_formControl" className={classes.formControl}>
                            <InputLabel htmlFor="select_down_number">{('Position')}</InputLabel>
                            <Select
                                id="select_positon"
                                defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.valueObj.type][popUpCommand.valueObj.order - 1]?.position || ''}
                                //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.position || ''}
                                onChange={handleChangeProp('position')}
                            >
                                <MenuItem value="aaa">aaa</MenuItem>
                                <MenuItem value="bbb">bbb</MenuItem>
                                <MenuItem value="ccc">ccc</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </div>

            break;

        case 'kickoff':
            result =
                <form autoComplete="off" className={classes.form_root}>
                    <FormControl disabled={true} id="kickoff_player_formControl" className={classes.formControl}>
                        <InputLabel htmlFor="select_down_number">{('Player')}</InputLabel>
                        <Select
                            id="select_kickoff_player"
                            //defaultValue={playerDetails[popUpCommand.team - 1][popUpCommand.lineUpType][popUpCommand.order - 1]?.name || ''}
                            defaultValue={''}
                            //value={playerDetails[team - 1][type][order - 1].name || 'Please Select'}
                            onChange={handleChangeProp('name')}
                        >
                            <MenuItem value="Doba">Doba</MenuItem>
                            <MenuItem value="Vaso">Vaso</MenuItem>
                            <MenuItem value="Ivan">Ivan</MenuItem>
                        </Select>
                    </FormControl>
                </form>
            break;


        default:
            break;
    }

    return result;
}

/* Dialog pop up which will be open for command pop ups */
export default function DialogConfirmPopup({ data, handleClosePopUp, popUpCommand, setPopUpCommand, handleSendStatus }: any) {

    const playerDetails = useSelector(selectTeams);
    const suggestedStatusObject = useSelector(selectSportSuggestedStatus);
    const suggestedStatus = suggestedStatusObject.americanfootball;
    const currentStatus = useSelector(selectStatus);
    const classes = useDialogConfirmPopupStyles();
    const openConfirmPopUp = useSelector(selectOpenConfirmPopUp)
    const dispatch = useDispatch();

    /* Change POP up hook props */
    const handleChangeProp = prop => (event) => {

        const valueToBeNumber = ['scrimmage_yards'];
        let value = event.target.value;

        /* convert to number */
        if (valueToBeNumber.includes(prop)) {
            value *= 1;
        }

        setPopUpCommand({
            ...popUpCommand,
            valueObj: {
                ...popUpCommand.valueObj,
                [prop]: value
            }
        })
    }

    return (
        <div className="wrapperDialog">
            <Dialog
                className="dialogClass"
                open={openConfirmPopUp['open']}
                BackdropProps={{
                    style: { opacity: 0.5, whiteSpace: "pre-line" },
                }}
                maxWidth={false}
                //fullWidth={true}
                //disableBackdropClick={true}
                //PaperProps={{ classes: { paperWidthFalse: classes.dialogPaper } }}
                PaperProps={{
                    style: {
                        width: "22%",
                        maxWidth: "50%",
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText component={'div'} id="alert-dialog-description" style={{ textAlign: "center" }}>
                        {switchLabel(openConfirmPopUp['type'], popUpCommand, suggestedStatus)}
                        {renderHtml(currentStatus, statusesNeeded, handleChangeProp, openConfirmPopUp['type'], playerDetails, popUpCommand, classes)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={classes.buttonField}>
                        <Button
                            style={Object.assign({
                                display: openConfirmPopUp['cancel_yes'],
                                backgroundColor: '#CD5C5C',
                                color: 'white',
                            })}
                            variant="contained"
                            //color="primary"
                            size='small'
                            onClick={() => {
                                /* For status pop up on NO , we need to close the pop up only */
                                if (openConfirmPopUp['type'] === 'setStatus') {
                                    dispatch(setOpenConfirmPopUp({ type: 'confirm', open: false, cancel_yes: 'none', ok: 'block' }));
                                }
                                else {
                                    /* delete the initial command and close the pop up */
                                    handleClosePopUp(true, popUpCommand.key, 'cancel');
                                }
                            }}
                        >
                            {openConfirmPopUp['type'] === 'setStatus' ? 'No' : 'Cancel'} {/* for statuses button became NO */}
                        </Button>

                        <Button
                            //color="primary"
                            autoFocus
                            variant="contained"
                            style={Object.assign({ display: openConfirmPopUp['ok'] })}
                            onClick={() => {
                                dispatch(setOpenConfirmPopUp({
                                    open: false,
                                    type: openConfirmPopUp['type'],
                                    cancel_yes: "none",
                                    ok: "block",
                                }));
                            }}
                        >
                            Uncf
                        </Button>

                        <Button
                            style={Object.assign({
                                display: openConfirmPopUp['cancel_yes'],
                                backgroundColor: 'lightgreen',
                                color: 'white',
                            })}
                            variant="contained"
                            size='small'
                            //color="primary"
                            autoFocus
                            onClick={() => {
                                /* on YES we need to send status command and close the pop up  */
                                if (openConfirmPopUp['type'] === 'setStatus') {
                                    handleSendStatus();
                                    dispatch(setOpenConfirmPopUp({ type: 'confirm', open: false, cancel_yes: 'none', ok: 'block' }));
                                }
                                else {
                                    /* send every other command (except status) and close pop up */
                                    handleClosePopUp(false, popUpCommand.key, 'confirm');
                                }
                            }}
                        >
                            {openConfirmPopUp['type'] === 'setStatus' ? 'Yes' : 'Confirm'} {/* for statuses button became YES */}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};
