import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { allStatusesAvailable } from '.././common/constants';
import { selectOpenConfirmPopUp, selectSportSuggestedStatus, selectCommandId, selectPopUpCommand, selectStatus, selectUncfPointId } from '../../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenConfirmPopUp, setButtonBlink} from '../../../store/actions';
import { handleSendMainCommands, handleSendStatus } from '../utils';

export default function SanityCheckDialog() {
	const openConfirmPopUp = useSelector(selectOpenConfirmPopUp);
	const suggestedStatusObject = useSelector(selectSportSuggestedStatus);
    const suggestedStatus = suggestedStatusObject.tabletennis;
    const uncfCommandId = useSelector(selectUncfPointId);
    let commandId = useSelector(selectCommandId);
    if (uncfCommandId){
        commandId = uncfCommandId;
    }
    
    /* Keep last added point details when opening the CONFIRMATION pop up */
    const popUpcommandObject = useSelector(selectPopUpCommand);
    const popUpcommand = popUpcommandObject['tabletennis'];
    const currentStatus = useSelector(selectStatus);
    const dispatch = useDispatch();

    /* With this func , as we are in the POP UP , we confirm changing status or not */
    const handleCloseStatus = (confirmed: boolean) => {
        dispatch(setOpenConfirmPopUp({ type: 'setStatus', open: false, cancel_yes: 'block', ok: 'none' }));

        if (confirmed) {
            handleSendStatus(suggestedStatus);
            dispatch(setButtonBlink('send_button_field'));
        }
    };

    /* With this func , as we are in the POP UP , we send cancelled or confirmed point */
    const handleClose = (canceled_or_confirmed: boolean) => {
        dispatch(setOpenConfirmPopUp({ type: 'confirm', open: false, cancel_yes: 'none', ok: 'block' }));

        if (canceled_or_confirmed) {
            handleSendMainCommands(commandId, 'command', popUpcommand['key'], popUpcommand['team'], Math.abs(currentStatus * 1), popUpcommand['confirmed'], canceled_or_confirmed, popUpcommand['modified'], false, '', '', false);
        }
        else {
            handleSendMainCommands(commandId, 'command', popUpcommand['key'], popUpcommand['team'], Math.abs(currentStatus * 1), true, canceled_or_confirmed, popUpcommand['modified'], false, '', '', false);
        }
    };

	return (
		<Dialog
            data-testid="alert-dialog"
            open={openConfirmPopUp['open']}
            BackdropProps={{ style: { opacity: 0.5, whiteSpace: 'pre-line' } }}
            // disableBackdropClick={true}
            // onClose={(event, reason) => {
            //     if (reason !== 'backdropClick') {
            //         onClose(event, reason);
            //     }
            // }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent>
                <DialogContentText data-testid="alert-dialog-description" id="alert-dialog-description" data-cy="popup-confirm">
                    {/* Are you sure you want to Confirm #{popUpcommand['id']} {popUpcommand['team'] === 1 ? 'Home ' : 'Away '} {popUpcommand['key']} */}
                    {
                        openConfirmPopUp['type'] === 'notStarted' ? 'You can not add commands in Not Started status' :
                            openConfirmPopUp['type'] === 'uncf' ? 'YOU HAVE UNCF POINT AND CAN NOT ADD A NEW ONE!' :
                                openConfirmPopUp['type'] === 'confirmed' ? 'Are you sure you want to Confirm' :
                                    openConfirmPopUp['type'] === 'noFirstServe' ? 'Please select First Serve!' :
                                        openConfirmPopUp['type'] === 'setStatus' ? 'Are you sure you want to change game status to ' + allStatusesAvailable[suggestedStatus].title + '?' : ''
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    data-testid="dialog-cancel-button"
                    style={Object.assign({ display: openConfirmPopUp['cancel_yes'] })}
                    onClick={() => {
                        if (openConfirmPopUp['type'] === 'setStatus') {
                            handleCloseStatus(false);
                        } else {
                            handleClose(true);
                        }
                    }}
                    data-cy="popup-confirm-cancel"
                    color="primary">
                    Cancel
                </Button>
                <Button
                    data-testid="dialog-yes-button"
                    style={Object.assign({ display: openConfirmPopUp['cancel_yes'] })}
                    color="primary" autoFocus
                    onClick={() => {
                        if (openConfirmPopUp['type'] === 'setStatus') {
                            handleCloseStatus(true);
                        } else {
                            handleClose(false);
                        }
                    }}
                    data-cy="popup-confirm-yes"
                >
                    Yes
                </Button>

                <Button
                    data-testid="dialog-ok-button"
                    color="primary" autoFocus
                    style={Object.assign({ display: openConfirmPopUp['ok'] })}
                    onClick={() => {
                        dispatch(setOpenConfirmPopUp({ open: false, type: openConfirmPopUp['type'], cancel_yes: 'none', ok: 'block' }));
                    }}
                    data-cy="popup-confirm-ok"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
	);
}