import { RootState } from "./types";

export const selectGameTimeState = (state: RootState) => state.gameTime;
export const selectIsClockRunning = (state: RootState) => state.isClockRunning;
export const selectCurrentGameInfoState = (state: RootState) => {
    return {
        currentWeather: state.currentWeather,
        currentPreMatchInfo: state.currentPreMatchInfo,
        currentWind: state.currentWind,
        currentAttendance: state.currentAttendance,
        currentTossWinner: state.currentTossWinner,
        currentHomeChoice: state.currentHomeChoice,
        currentAwayChoice: state.currentAwayChoice,
        currentHomeColor1: state.currentHomeColor1,
        currentHomeColor2: state.currentHomeColor2,
        currentAwayColor1: state.currentAwayColor1,
        currentAwayColor2: state.currentAwayColor2,
    };
};
export const selectTeams = (state: RootState) => state.currentTeams;
export const selectSafety = (state: RootState) => state.currentSafety;
export const selectHomeFieldGoal = (state: RootState) => state.currentHomeFieldGoal;
export const selectAwayFieldGoal = (state: RootState) => state.currentAwayFieldGoal;
export const selectHomeTouchdownDisabled = (state: RootState) => state.currentHomeTouchdownDisabled;
export const selectAwayTouchdownDisabled = (state: RootState) => state.currentAwayTouchdownDisabled;
export const selectHomeOnePointMadeMissed = (state: RootState) => state.currentHomeOnePointMadeMissed;
export const selectAwayOnePointMadeMissed = (state: RootState) => state.currentAwayOnePointMadeMissed;
export const selectHomeTwoPointsMadeMissed = (state: RootState) => state.currentHomeTwoPointsMadeMissed;
export const selectAwayTwoPointsMadeMissed = (state: RootState) => state.currentAwayTwoPointsMadeMissed;
export const selectKickOff = (state: RootState) => state.currentKickOff;
export const selectCloseOpenGame = (state: RootState) => state.currentCloseOpenGame;
export const selectHomeActiveTeam = (state: RootState) => state.currentHomeActiveTeam;
export const selectAwayActiveTeam = (state: RootState) => state.currentAwayActiveTeam;
export const selectOverlayDisplay = (state: RootState) => state.currentOverlayDisplay;
export const selectStatus = (state: RootState) => state.currentStatus;
export const selectHomeDown = (state: RootState) => state.currentHomeDown;
export const selectAwayDown = (state: RootState) => state.currentAwayDown;
export const selectHomeYards = (state: RootState) => state.currentHomeYards;
export const selectAwayYards = (state: RootState) => state.currentAwayYards;
export const selectTeamPossesion = (state: RootState) => state.currentTeamPossesion;
export const selectSnap = (state: RootState) => state.currentSnap;
export const selectFirstServeDisplay = (state: RootState) => state.currentFirstServeDisplay;
export const selectUser = (state: RootState) => state.currentUser;
export const selectSportSuggestedStatus = (state: RootState) => state.currentSportSuggestedStatus;
export const selectTeamButtonsDisabled = (state: RootState) => state.currentTeamButtonsDisabled;
export const selectOpenConfirmPopUp = (state: RootState) => state.currentOpenConfirmPopUp;
export const selectUncfPointId = (state: RootState) => state.currentUncfPointId;
export const selectPopUpCommand = (state: RootState) => state.currentPopUpCommand;
export const selectGameScoreToStatusId = (state: RootState) => state.currentGameScoreToStatusId;
export const selectMasterCloseGame = (state: RootState) => state.currentMasterCloseGame;
export const selectMSFStatus = (state: RootState) => state.currentMSFStatus;
export const selectLastIdForUpdateStatus = (state: RootState) => state.currentLastIdForUpdateStatus;
export const selectLastCnfPointId = (state: RootState) => state.currentLastCnfPointId;
export const selectIsDisabledTakenControl = (state: RootState) => state.currentIsDisabledTakenControl;
export const selectMapIdToActionId = (state: RootState) => state.mapIdToActionId;
export const selectCommandId = (state: RootState) => state.commandId;
export const selectCommandInc = (state: RootState) => state.commandInc;
export const selectFeedCommandInc = (state: RootState) => state.feedCommandInc;
export const selectConnectionStatus = (state: RootState) => state.connectionStatus;
export const selectHeartBeat = (state: RootState) => state.heartBeat;
export const selectEventInfo = (state: RootState) => state.eventInfo;
export const selectScore = (state: RootState) => state.score;
export const selectOverallGameScore = (state: RootState) => state.overallGameScore;
export const selectLock = (state: RootState) => state.lock;
export const selectButtonBlink = (state: RootState) => state.buttonBlink;
export const selectCommandLogArrays = (state: RootState) => state.commandLogArrays;
export const selectAuthorized = (state: RootState) => state.authorized;
export const selectConfig = (state: RootState) => state.config;
export const selectUsername = (state: RootState) => state.username;
export const selectToken = (state: RootState) => state.token;


