import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { allStatusesAvailable } from './AmericanFootball';
import { setClockValue } from "./utils";


export default function CommandRow({ id, isConfirmed, isCancelled, home_score, away_score, sequence, mapCommandLog, tab }: any) {
    /* TODO - Probably we gonna need user to be display for every command !?! */
    const command = mapCommandLog[sequence];
    const allUncfCommands = ['safety', 'touchdown', 'fieldGoalAttempt', 'fieldGoalMade', 'fieldGoalMissed', 'down',
        'activeTeam', 'timeOut', 'penalty', 'challenge', 'onePointConversionMade', 'onePointConversionMissed',
        'twoPointsConversionMade', 'twoPointsConversionMissed' , 'kickoff',
    ];
    const noTeamCommands = ['gamestart', 'gamefinish', 'matchstatus', 'status_ok', 'status_unsure', 'comment',
        'preMatchInfo', 'attendance', 'pitch', 'confirmScore', 'snap',
    ];
    const valueAndTextCommands = ['comment', 'preMatchInfo', 'weather', 'wind', 'attendance', 'tossWinner', 'pitch',
        'awayChoice', 'homeChoice', 'yards', 'home_color_1', 'home_color_2', 'away_color_1', 'away_color_2',
    ];
    const statuses = ['status'];

    /* this variable contain KEYS from command.value object */
    /* Related to touchdown,safety,1P made and missed,2P made and missed,FGoal made and missed,down,timeout,challenge,penalty,activeTeam commands */
    let commandValue = '';
    if (command.value) {
        let result = '';
        switch (command.key) {
            case 'touchdown':
            case 'onePointConversionMade':
            case 'onePointConversionMissed':
            case 'twoPointsConversionMade':
            case 'twoPointsConversionMissed':
            case 'fieldGoalMade':
            case 'fieldGoalMissed':
            case 'kickoff':
                result = command.value?.playerId !== undefined ? (' ' + command.value.playerId) : '' + (command.value?.name !== undefined ? (' ' + command.value.name) : '');
                break;
            case 'down':
                result += command.value?.number !== undefined ? (' ' + command.value.number + ['st', 'nd', 'rd', 'th'][command.value?.number - 1]) : '';
                result += command.value?.scrimmage_yards !== undefined ? (' and ' + command.value?.scrimmage_yards) : '';
                break;
            case 'activeTeam':
                result = command.value?.type !== undefined ? (' ' + command.value?.type) : '';
                break;
            case 'challenge':
                result = command.value?.outcome !== undefined ? (' ' + command.value?.outcome) : '';
                break;
            case 'penalty':
                result += command.value?.type !== undefined ? (' type: ' + command.value?.type + ',') : '';
                result += command.value?.playerId !== undefined ? (' ' + command.value.playerId) : '' + (command.value?.name !== undefined ? (' ' + command.value?.name + ',') : '');
                result += command.value?.yards !== undefined ? (' yards to end zone:' + command.value?.yards) : '';
                break;

            default:
                break;
        }
        commandValue = result;
    }

    const commandText = valueAndTextCommands.includes(command.key) ? (command.key === 'yards' ? 'Yards to Endzone ' : command.key) + ': ' + command.value?.text
        :
        statuses.includes(command.key) ? 'Status' : command.key.charAt(0).toUpperCase() + command.key.slice(1);

    const statusPrefix = command.key === 'gamefinish' ? 'End ' : '';
    const game_number = statuses.includes(command.key) ? statusPrefix + allStatusesAvailable[command.gameNumber].title : '';
    //const commandTeam = noTeamCommands.includes(command.key) ? (command.key === 'snap' ? (command.snapTeam === 1 ? "Home" : 'Away') : '') : command.team === 1 ? "Home" : command.team === 0 ? '' : "Away";
    const commandTeam = noTeamCommands.includes(command.key) ? '' : command.team === 1 ? "Home" : command.team === 0 ? '' : "Away";

    const uncf_conf_canceled = allUncfCommands.includes(command.key) ? isConfirmed ? '(CNF)' : command.isCancelled ? '(CANCELLED)' : '(UNCF)' : '';
    const isModified = command.isModified ? '(MODIFIED)' : '';
    const time = command.UTCtimestamp.substring(command.UTCtimestamp.indexOf("T") + 1, command.UTCtimestamp.indexOf("."));

    return (
        <ListItem
            key={command.id}
        >
            {<span>{time}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            {<span>[{setClockValue(command.gameTime)}]</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            {<span>{'#' + command.id}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            <ListItemText
                disableTypography
                primary={commandTeam + ' ' + commandText + ' ' + game_number + uncf_conf_canceled + isModified + commandValue}
                id={command.id}
                style={isCancelled ? styles.deleted_command : styles.command}
            />
            {tab !== 'editCancel' && (command.key === 'point' || command.key === 'confirmScore') && isConfirmed && <span>{home_score + ' : ' + away_score}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            {<span style={{ fontSize: '0.5em' }}>{'(' + command?.user + ')'}</span>}
            {/* if we are not in editCancel tab and it is a conf point -> add <span> with home/away score to the row command  */}
        </ListItem>
    )
}

const styles = {
    command: {
        borderRadius: '15px',
        //backgroundColor : 'white',
        display: 'flex',
        paddingLeft: '1%',
        //fontSize: '1vw',
        //justifyContent: 'center',
    },
    deleted_command: {
        textDecoration: 'line-through',
        paddingLeft: '1%',
        display: 'flex',
        //fontSize: '1vw',
    },
}
