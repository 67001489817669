import React from 'react';
import { useLockOverlayStyles } from './LockOverlayStyles';
import { useSelector } from 'react-redux';
import { selectLock } from '../../../store/selectors';
import cloneDeep from 'lodash.clonedeep';

const LockOverlay = () => {

    const classes = useLockOverlayStyles();
    const lock = cloneDeep(useSelector(selectLock));
    return (
        <div
            className={lock.display === 'none' ? classes.lock_overlay_field_none : classes.lock_overlay_field_block}
            data-testid='lock-overlay'
            data-cy='lock-overlay'>

        </div>
    )
}

export default LockOverlay;