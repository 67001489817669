import React, { useState, useEffect } from 'react'; // we need this to make JSX compile
import NavBar from './Navbar/NavBar';
import SimpleTabs from './SimpleTabs/SimpleTabs';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { recalculateScore, checkIsFinishedStatus, calculateOverallGameScore, getInitialTTScoreState, checkMatchIsFinished } from './utils';
import { allStatusesAvailable, settings, allPopUpCommands, feedSequenceIdCommands, } from './common/constants';
import EventInfo from './EventInfo/EventInfo';
import OverlayList from './OverlayList';
import CommandLog from './CommandLog/CommandLog';
import Statistics from './Statistics';
import Reset from './Reset';
import { useDispatch, useSelector } from 'react-redux';
import { 
    setAttendance, setAwayColor1, setAwayColor2, setCloseOpenGame, setFirstServeDisplay, 
    setHomeColor1, setHomeColor2, setPreMatchInfo, setStatus, setOverlayDisplay, setSportSuggestedStatus, 
    setTeamButtonsDisabled, setUncfPointId, setGameScoreToStatusId, setMasterCloseGame, setMSFStatus, 
    setLastIdForUpdateStatus, setLastCnfPointId, setIsDisabledTakenControl, setMapIdToActionId, setCommandId, 
    setCommandInc, setFeedCommandInc, setScore, setOverallGameScore, setLock, setButtonBlink, setCommandLogArrays
} from '../../store/actions';
import { useTableTennisStyles } from './TennisTableStyles';
import { 
    selectStatus, selectGameScoreToStatusId, selectUncfPointId, selectMasterCloseGame, selectLock,
    selectMSFStatus, selectLastIdForUpdateStatus, selectLastCnfPointId, selectUser, selectMapIdToActionId,
    selectCommandId, selectCommandInc, selectFeedCommandInc, selectEventInfo, selectScore, selectOverallGameScore, selectCommandLogArrays
} from '../../store/selectors';
import cloneDeep from 'lodash.clonedeep';

export default function TennisTable({ db_data, newCommand, isControlTaken }: any) {

    const eventInfo = useSelector(selectEventInfo);
    const sets = eventInfo?.sets || 9;
    const svEmail = eventInfo?.supervisor?.email || '';
    const coEmail = eventInfo?.callcenter?.email || '';

    const score = useSelector(selectScore);
    
    const [previousManualScore, setPreviousManualScore] = useState(getInitialTTScoreState(sets));

    const dispatch = useDispatch();
    const classes = useTableTennisStyles();

    const [finishedStatus, setFinishedStatus] = useState({});
    
    /* Store item to show/hode OVERLAY div which will prevent adding commands before previous ones have been processed */
    //const overlayDisplay = useSelector(selectOverlayDisplay);

    const lock = useSelector(selectLock);
    
    const overallGameScore = useSelector(selectOverallGameScore);

    const currentStatus = useSelector(selectStatus);

    const uncfPointId = useSelector(selectUncfPointId);

    const lastCnfPointId = useSelector(selectLastCnfPointId);

    const gameScoreToStatusId = useSelector(selectGameScoreToStatusId);

    const masterCloseGame = useSelector(selectMasterCloseGame);

    const currentMSFStatus = useSelector(selectMSFStatus);

    const lastIdForUpdateStatus = useSelector(selectLastIdForUpdateStatus);

    const currentUser = useSelector(selectUser);

    const mapIdToActionId = useSelector(selectMapIdToActionId);

    const commandId = useSelector(selectCommandId);

    const commandInc = useSelector(selectCommandInc);

    const feedCommandInc = useSelector(selectFeedCommandInc);

    /* Keep all commands and filtered command by type as we need to display different stuff for different TABS (SHOW ALL , POINT ...) */
    const commandLogArrays = cloneDeep((useSelector(selectCommandLogArrays)));

    const [lastGameScoreCommandInc, setLastGameScoreCommandInc] = useState(0);

    /* Hook to keep the team of the FirstSer */
    const [firstServeTeam, setFirstServeTeam] = useState(0);

    /* Hook to keep if the match is notStarted (gameNumber 0) , started (gameNumber 1) , inProgress ( > gameNumber 1) or halfTime( end of ... game) */
    const [gameStarted, setGameStarted] = useState('notStarted');
    
    /* Hook for counting the close/open game which are coming from MT4 tool (it is needed because both commands haven't seq and id)*/
    const [noSequenceCounter, setNoSequenceCounter] = useState(1);

    /* hook to keep all points ID and TEAM values */
    const [allPoints, setAllPoints] = useState([]);
    /*    
    - Update hooks 
    - increment ID and INC
    - goes through all commands and filter to different arrays */

    const populateData = (commandLog) => {
        /* Hide OVERLAY and user will be able to add commands */
        dispatch(setOverlayDisplay('none'));

        if (commandLog.length === 0) {
            return;
        }

        /* variables used for updating the hook after the loop */
        let temp_first_serve_team = firstServeTeam;
        let temp_all_points = allPoints;
        let temp_status = currentStatus;
        let temp_overall_score = overallGameScore;
        let temp_finished_status = cloneDeep(finishedStatus);
        let temp_lock = cloneDeep(lock);
        let temp_game_started = gameStarted;
        let temp_uncf_point_id = uncfPointId;
        let temp_gamescore_to_status_id = cloneDeep(gameScoreToStatusId);
        let temp_previous_manual_score = cloneDeep(previousManualScore);
        let temp_last_gamescore_command_inc = lastGameScoreCommandInc;
        let temp_no_sequence_counter = noSequenceCounter;
        let temp_master_close_game = masterCloseGame;
        let temp_current_msf_status = currentMSFStatus;
        let temp_last_id_for_update_status = lastIdForUpdateStatus;
        let temp_last_cnf_point_id = lastCnfPointId;
        let temp_is_SV_taken_control = false;
        let temp_is_CO_taken_control = false;
        let temp_is_disabled_taken_control = false;
        let temp_map_id_to_action_id = cloneDeep(mapIdToActionId);
        let temp_command_id = commandId;
        let temp_command_inc = commandInc;
        let temp_feed_command_inc = feedCommandInc;
        
        if (commandLog && commandLog.length !== 0){
            commandLog.forEach(element => {
                if (element !== "") {
                    let command = JSON.parse(element);
                    /* Assign the user to the every command value */
                    command.value['user'] = command.user;

                    /* Use this temp variable as populating point to this/current status */
                    let command_status = command.value.gameNumber;

                    switch (command.value.key) {
                        case 'status_unsure':
                            dispatch(setCloseOpenGame('status_ok'));
                            // the close game command which coming from mt4
                            if (command?.page === 'monitor') {
                                temp_master_close_game = true;
                            }

                            break;

                        case 'status_ok':
                            dispatch(setCloseOpenGame('status_unsure'));
                            // the open game command which coming from mt4
                            if (command?.page === 'monitor') {
                                temp_master_close_game = false;
                            }
                            break;

                        case 'firstServe':
                            /* update our temp variable with the correct value */
                            temp_first_serve_team = command.value.team;

                            /* Update store to hide firstServe buttons */
                            dispatch(setFirstServeDisplay('none'));

                            break;

                        case 'timeout':
                            if (temp_last_id_for_update_status < parseInt(command.value.id)) {
                                temp_current_msf_status = command.value.feed.matchStatus;
                            }

                            if (!command.value.isModified) {
                                temp_last_id_for_update_status = parseInt(command.value.id);
                            }
                            break;

                        case 'serve':
                            temp_game_started = 'inProgress';
                            
                            if (temp_last_id_for_update_status < parseInt(command.value.id)) {
                                temp_current_msf_status = 'PointInProgress';
                            }

                            if (!command.value.isModified) {
                                temp_last_id_for_update_status = parseInt(command.value.id);
                            }
                            break;

                        case 'point':
                            /* not confirmed  */

                            if (!command.value.isConfirmed) {
                                if (temp_uncf_point_id && temp_uncf_point_id !== command.value.id) {
                                    return;
                                }
                                /* if point is cancelled so we need to remove it from the array */
                                if (command.value.isCancelled) {
                                    temp_uncf_point_id = 0;
                                    //return;
                                }
                                /* push it to the array and keep it for further checks */
                                else {
                                    temp_uncf_point_id = command.value.id;
                                    temp_current_msf_status = 'PointInProgress';
                                }

                            }

                            /* Confirmed */
                            else {
                                /* TODO make it globally for all uncf commands */
                                if (temp_uncf_point_id === command.value.id) {
                                    temp_uncf_point_id = 0;
                                }

                                if (!command.value.isCancelled) {
                                    temp_current_msf_status = 'BetweenPoints';
                                    
                                } else {
                                    if (temp_last_cnf_point_id <= command.value.id) {
                                        temp_current_msf_status = 'PointInProgress';
                                    }
                                }

                                /* Not Cancelled and Not Modified*/
                                if (!command.value.isCancelled && !command.value.isModified) {
                                    temp_last_cnf_point_id = command.value.id;
                                }

                                /* CALCULATE SCORE POINTS  */
                                /* Cancelled & Confirmed */
                                if (command.value.isCancelled && command.value.isConfirmed) {
                                    if (command_status === Math.abs(temp_status)/* currentStatus */) {
                                        score.current_score[command.value.team - 1]--;
                                    }
                                    score['game_' + command_status][command.value.team - 1]--;
                                    temp_previous_manual_score['game_' + command_status][command.value.team - 1]--;
                                    
                                    if (temp_finished_status[command_status] !== undefined && temp_finished_status[command_status]) {
                                        let winning_team = score['game_' + command_status][0] > score['game_' + command_status][1] ? 0 : 1;
                                        temp_overall_score[winning_team]--;
                                        temp_finished_status[command_status] = false;
                                    }
                                }

                                /* Modified & Not Cancelled */
                                if (command.value.isModified && !command.value.isCancelled) {

                                    /* if we have such id in the temp_all_points && team was changed then we need to update the score with updating the hook setScore */
                                    if (temp_all_points[command.value.id] !== undefined && command.value.team !== temp_all_points[command.value.id]) {
                                        /* Update score */
                                        if (command_status === Math.abs(temp_status)/* currentStatus */) {
                                            score.current_score[temp_all_points[command.value.id] - 1]--;
                                        }
                                        score['game_' + command_status][temp_all_points[command.value.id] - 1]--;

                                        /* If we were in finished status and modify , we need to decrease temp_overall_score and set temp_finished_status[command_status] = false */
                                        if (temp_finished_status[command_status] !== undefined && temp_finished_status[command_status]) {
                                            let winning_team = score['game_' + command_status][0] > score['game_' + command_status][1] ? 0 : 1;
                                            if (temp_overall_score[winning_team] !== 0) {
                                                temp_overall_score[winning_team]--;
                                            }
                                            temp_finished_status[command_status] = false;
                                        }
                                    }
                                }


                                /* Not Cancelled */
                                if (!command.value.isCancelled &&
                                    (!command.value.isModified ||
                                        (command.value.isModified && temp_all_points[command.value.id] === undefined) ||
                                        (command.value.isModified && temp_all_points[command.value.id] !== undefined && command.value.team !== temp_all_points[command.value.id]))) {

                                    if (command_status === Math.abs(temp_status)/* currentStatus */) {
                                        score.current_score[command.value.team - 1]++;
                                    }
                                    score['game_' + command_status][command.value.team - 1]++;
                                    temp_previous_manual_score['game_' + command_status][command.value.team - 1]++;

                                    //should decrease the prev manual score when the team is changed
                                    if (command.value.isModified && temp_all_points[command.value.id] !== undefined && temp_previous_manual_score['game_' + command_status][temp_all_points[command.value.id] - 1] > 0) {
                                        temp_previous_manual_score['game_' + command_status][temp_all_points[command.value.id] - 1]--;
                                    }

                                }

                                /* Check if we are in Winning Game */
                                if (score['game_' + command_status][0] >= 11 || score['game_' + command_status][1] >= 11) {
                                    if (command_status > 0 && Math.abs(score['game_' + command_status][0] - score['game_' + command_status][1]) >= 2) {
                                        let winning_team = score['game_' + command_status][0] > score['game_' + command_status][1] ? 0 : 1;
                                        if (temp_finished_status[command_status] === undefined || !temp_finished_status[command_status]) {
                                            temp_overall_score[winning_team]++;
                                            temp_finished_status[command_status] = true;
                                        }
                                    }
                                }
                            }
                            /* update the team of the point command to this id */
                            temp_all_points[command.value.id] = command.value.team;

                            commandLogArrays.points.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, score_home: score.current_score[0], score_away: score.current_score[1], team: command.value.team, sequence: command.value.sequence })
                            break;

                        case 'let':
                            if (temp_last_id_for_update_status < parseInt(command.value.id)) {
                                temp_current_msf_status = 'BetweenPoints';
                            }

                            if (!command.value.isModified) {
                                temp_last_id_for_update_status = parseInt(command.value.id);
                            }
                            break;
                        case 'comment':
                            commandLogArrays.comments.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            
                            if (command.value.value.text.startsWith('Home medical timeout') || command.value.value.text.startsWith('Away medical timeout')) {
                                temp_current_msf_status = command.value?.feed.matchStatus;
                                temp_last_id_for_update_status = parseInt(command.value.id);
                            }
                            break;
                        case 'preMatchInfo':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setPreMatchInfo(command.value.value.text));
                            temp_current_msf_status = command.value.feed.matchStatus;
                            temp_last_id_for_update_status = parseInt(command.value.id);
                            break;
                        case 'attendance':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setAttendance(command.value.value.text))
                            break;
                        case 'homeColor1':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setHomeColor1(command.value.value.text))
                            break;
                        case 'homeColor2':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setHomeColor2(command.value.value.text))
                            break;
                        case 'awayColor1':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setAwayColor1(command.value.value.text))
                            break;
                        case 'awayColor2':
                            commandLogArrays.info.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, sequence: command.value.sequence })
                            dispatch(setAwayColor2(command.value.value.text))
                            break;
                        case 'gamestart':
                            /* not deleted */
                            if (!command.value.isCancelled) {
                                temp_status = command_status;

                                /* Take score for the current status if we have such set previosly and update the hook */
                                score.current_score = score['game_' + temp_status].slice(0);

                                /* Change the hook to started if we just start game_1 */
                                if (command_status === 1) {
                                    temp_game_started = 'started';
                                }

                                /* If the match is in game_2 or more and not Interrupted/Finished/Abondoned we change the hook */
                                else if (command_status > 1 && command_status < 10) {
                                    temp_game_started = 'inProgress';
                                }
                            }

                            temp_current_msf_status = 'BetweenPoints';
                            temp_last_id_for_update_status = parseInt(command.value.id);
                            break;
                        case 'gamefinish':
                            /* not deleted */
                            if (!command.value.isCancelled) {

                                /* If it is gamefinish we need to hide all butons in that case */
                                if (command_status > 0) {
                                    temp_game_started = 'endOfSomeGame';
                                }
                                //setCurrentStatus(command_status * (-1));
                                temp_status = command_status * (-1);

                            }
                            temp_current_msf_status = 'BetweenGames';
                            temp_last_id_for_update_status = parseInt(command.value.id);
                            break;
                        case 'matchstatus':
                            if (!command.value.isCancelled) {
                                temp_status = command_status;

                                /* If it is matchstatus(Interrupted , Abandoned) we need to hide all butons in that case */
                                if (command_status > 0) {
                                    temp_game_started = 'endOfSomeGame';
                                }
                            }

                            if (command_status === 100) {
                                temp_current_msf_status = 'PlaySuspended';
                            } else if (command_status === 101) {
                                temp_current_msf_status = 'Abandoned';
                            } else if (command_status === 102) {
                                temp_current_msf_status = 'Cancelled';
                            }
                            temp_last_id_for_update_status = parseInt(command.value.id);
                            break;

                        case 'takeControl':
                            if (isControlTaken) {
                                temp_lock = {
                                    display: 'none',
                                    key: 'releaseControl',
                                    text: 'RELEASE CONTROL',
                                    color: 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
                                    user: command.value.user
                                }
                            }
                            /* If it is for other CC which is open , just update the user taken control to be seen */
                            else {
                                temp_lock.user = command.value.user  
                            }

                            /* Functionality which sets the current user who is taken the control*/
                            if (command.value.user.toLowerCase() === svEmail.toLowerCase()) {
                                temp_is_SV_taken_control = true;
                                temp_is_CO_taken_control = false;
                            } else if (command.value.user.toLowerCase() === coEmail.toLowerCase()) {
                                temp_is_CO_taken_control = true;
                            }

                            break;

                        case 'releaseControl':
                            temp_lock = {
                                display: 'block',
                                key: 'takeControl',
                                text: 'TAKE CONTROL',
                                color: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)',
                                user: ''
                            }

                            /* Functionality which sets the current user who is taken the control*/
                            temp_is_SV_taken_control = false;
                            temp_is_CO_taken_control = false;

                            break;
                        case 'gamescore':
                            temp_last_gamescore_command_inc = command.value.sequence; 
                            temp_gamescore_to_status_id[command_status] = command.value.id;
                            if (command_status === Math.abs(temp_status)) {
                                score.current_score[0] += command.value.value.gamescore[0];
                                score.current_score[1] += command.value.value.gamescore[1];
                            }
                            score['game_' + command_status][0] += command.value.value.gamescore[0];
                            score['game_' + command_status][1] += command.value.value.gamescore[1];
                            
                            /* We decrease the score with the previous manual set score */
                            if (command_status === Math.abs(temp_status)) {
                                score.current_score[0] -= temp_previous_manual_score['game_' + command_status][0];
                                score.current_score[1] -= temp_previous_manual_score['game_' + command_status][1];
                            }
                            score['game_' + command_status][0] -= temp_previous_manual_score['game_' + command_status][0];
                            score['game_' + command_status][1] -= temp_previous_manual_score['game_' + command_status][1];

                            let previous_overall_score = cloneDeep(calculateOverallGameScore(temp_previous_manual_score['game_' + command_status]));
                            let current_overall_score = cloneDeep(calculateOverallGameScore(score['game_' + command_status]));

                            //We decrease the overall game score with the previous game score
                            temp_overall_score[0] -= previous_overall_score[0];
                            temp_overall_score[1] -= previous_overall_score[1];

                            temp_overall_score[0] += current_overall_score[0];
                            temp_overall_score[1] += current_overall_score[1];

                            temp_finished_status[command_status] = checkIsFinishedStatus(score['game_' + command_status]);

                            temp_previous_manual_score['game_' + command_status] = cloneDeep(command.value.value.gamescore);

                            temp_current_msf_status = 'BetweenPoints';
                            temp_last_id_for_update_status = parseInt(command.value.id);

                            commandLogArrays.points.unshift({ id: command.value.id, key: command.value.key, isConfirmed: command.value.isConfirmed, score_home: score.current_score[0], score_away: score.current_score[1], team: command.value.team, sequence: command.value.sequence })
                            break;
                        default:
                            break;
                    }

                    /* Increment command ID */
                    if (!command.value.isDeleted &&
                        !settings.includes(command.value.key) &&
                        !command.value.isModified &&
                        ((command.value.edit === true && allPopUpCommands.includes(command.value.key) && (!command.value.isCancelled || (command.value.isCancelled && !command.value.isConfirmed))) || command.value.edit === undefined) &&
                        (!(!command.value.isConfirmed && allPopUpCommands.includes(command.value.key)) || !allPopUpCommands.includes(command.value.key) || command.value.isCancelled)) {
                        temp_command_id++;
                    }

                    if (command.value.actionId !== "") {
                        temp_map_id_to_action_id[command.value.id] = command.actionId;
                    }

                    /* We want to see uncf points in the EDIT/CANCEL tab which is displaying 'commandLogArrays.edit_cancel_commands' */
                    if (!command.value.isCancelled && !command.value.isDeleted) {
                        if (temp_uncf_point_id === command.value.id && !command.value.isCancelled) {
                            commandLogArrays.edit_cancel_commands.unshift({ feed: command.value.feed, id: command.value?.id, key: command.value.key, isConfirmed: command.value.isConfirmed, team: command.value.team, status: command_status, text: command.value.value && command.value.value.text ? command.value.value.text : {}, score_home: score.current_score[0], score_away: score.current_score[1], sequence: command.value?.sequence });
                        }

                        // if (command.value.isConfirmed /* || (temp_uncf_point_id === command.value.id && !command.value.isConfirmed) */) {	

                        // 	let index: number;
                        // 	commandLogArrays.edit_cancel_commands.some(function (elem, i) {
                        //     return elem.id === command.value.id  ? (index = i, true) : false;
                        // 	});
                        // 	if (index >= 0) {
                        // 		commandLogArrays.edit_cancel_commands.splice(index, 1);
                        // 	}

                        // 	commandLogArrays.edit_cancel_commands.unshift({id:command.value.id, key: command.value.key, isConfirmed:command.value.isConfirmed , team: command.value.team, status: command_status, text: command.value.value && command.value.value.text ? command.value.value.text : {} , score_home:score.current_score[0], score_away:score.current_score[1]});
                        // }
                    }
                    else {
                        commandLogArrays.deleted_commands_ids.push(command.value.id);
                    }

                    /* As we have the uncf and cnf point command in commandLogArrays.edit_cancel_commands , we need to remove the uncf one before pushing the confirmed to the ARRAY */
                    if (commandLogArrays.deleted_commands_ids.includes(command.value.id) || (!command.value.isCancelled && command.value.isConfirmed)) {
                        let index: number;
                        commandLogArrays.edit_cancel_commands.some(function (elem, i) {

                            return elem.id === command.value.id ? (index = i, true) : false;
                        });
                        if (index >= 0) {
                            commandLogArrays.edit_cancel_commands.splice(index, 1);
                            commandLogArrays.edit_cancel_commands = recalculateScore(commandLogArrays.edit_cancel_commands, temp_last_gamescore_command_inc).slice(0);
                        }

                        /* Push the confirmed and not canceled command only to commandLogArrays.edit_cancel_commands */
                        if (!commandLogArrays.deleted_commands_ids.includes(command.value.id) && !settings.includes(command.value.key)) {
                            /* Add this command object to the index position as we want commands to be displayed in Edit/Cancel tab consistently */
                            commandLogArrays.edit_cancel_commands.splice(index, 0, { feed: command.value.feed, id: command.value?.id, key: command.value.key, isConfirmed: command.value.isConfirmed, team: command.value.team, status: command_status, text: command.value.value && command.value.value.text ? command.value.value.text : {}, score_home: score.current_score[0], score_away: score.current_score[1], sequence: command.value?.sequence });
                            commandLogArrays.edit_cancel_commands = recalculateScore(commandLogArrays.edit_cancel_commands, temp_last_gamescore_command_inc).slice(0);
                            //commandLogArrays.edit_cancel_commands.unshift({id:command.value.id, key: command.value.key, isConfirmed:command.value.isConfirmed , team: command.value.team, status: command_status, text: command.value.value && command.value.value.text ? command.value.value.text : {} , score_home:score.current_score[0], score_away:score.current_score[1] , sequence: command.value.sequence});
                        }
                    }

                    if (!settings.includes(command.value.key)) {
                        commandLogArrays.all.unshift({ id: command?.value?.id, key: command.value.key, isConfirmed: command.value.isConfirmed, isCancelled: command.value.isCancelled, isModified: command.value.isModified, score_home: score.current_score[0], score_away: score.current_score[1], team: command.value.team, sequence: command?.value?.sequence, status: command_status })
                        /* Update this object with all the state of commansd and display/ue it in CommandRow.tsx */
                        commandLogArrays.map_command_log[command.value.sequence] = JSON.parse(JSON.stringify(command.value));
                        temp_command_inc++;
                    }

                    /* Increase feed_command_inc for some commands only */
                    if (command.value.feedAction && feedSequenceIdCommands.includes(command.value.feedAction)) {
                        temp_feed_command_inc++;
                    }
                }
            });

        }
        /*Update the hook for is game started*/
        setGameStarted(temp_game_started);
        /* Update the hook after all changes in the for loop and this will trigger the useEffect to show/hide buttons */
        setFirstServeTeam(temp_first_serve_team);
        /* Update the point hook with latest TEAM changes after the loop */
        setAllPoints(temp_all_points);
        /*Update the hook for finished status*/
        setFinishedStatus(temp_finished_status);
        /* Update overall score hook */
        dispatch(setOverallGameScore(temp_overall_score));
        /* Update score hook*/
        dispatch(setScore(score));
        /* Update manual game score hook*/
        setPreviousManualScore(temp_previous_manual_score);
        /* Update hook for last gamescore command sequence*/
        setLastGameScoreCommandInc(temp_last_gamescore_command_inc);
        /* Update no sequence counter hook*/
        setNoSequenceCounter(temp_no_sequence_counter);
        /* Update status store item to keep updated after the loop */
        dispatch(setStatus(temp_status));
        /* Update uncfPointId store item*/
        dispatch(setUncfPointId(temp_uncf_point_id));
        /* Update gameScoreStatusId store item*/
        dispatch(setGameScoreToStatusId(temp_gamescore_to_status_id));
        /* Update masterCloseGame store item*/
        dispatch(setMasterCloseGame(temp_master_close_game));
        /* Update the current MSF status*/
        dispatch(setMSFStatus(temp_current_msf_status));
        /* Update last command id for updating status */
        dispatch(setLastIdForUpdateStatus(temp_last_id_for_update_status));
        /* Update last confirmed point id in store*/
        dispatch(setLastCnfPointId(temp_last_cnf_point_id));
        /* Update mapIdToActionId object*/
        dispatch(setMapIdToActionId(temp_map_id_to_action_id));
        /* Update command id store item*/
        dispatch(setCommandId(temp_command_id));
        /* Update command inc store item*/
        dispatch(setCommandInc(temp_command_inc));
        /* Update feed command inc store item*/
        dispatch(setFeedCommandInc(temp_feed_command_inc));
        
        if (temp_lock.text === 'TAKE CONTROL') {
            if (temp_is_SV_taken_control && currentUser.toLowerCase() !== temp_lock.user.toLowerCase() && svEmail.toLowerCase() === temp_lock.user.toLowerCase() && svEmail.toLowerCase() !== currentUser.toLowerCase()) {
                temp_is_disabled_taken_control = true;
            } else if (!temp_is_SV_taken_control && temp_is_CO_taken_control && currentUser.toLowerCase() !== temp_lock.user.toLowerCase() && coEmail.toLowerCase() === temp_lock.user.toLowerCase() && coEmail.toLowerCase() !== currentUser.toLowerCase() && svEmail.toLowerCase() !== currentUser.toLowerCase()) {
                temp_is_disabled_taken_control = true;
            }
            else {
                temp_is_disabled_taken_control = false;
            }
        }
        
        /* Update if SV is taken control*/
        dispatch(setIsDisabledTakenControl(temp_is_disabled_taken_control));

        /* Logic if we need to blink the SEND button and hide all the main buttons (serve, let , point , timeout) */
        if (temp_status > 0 && temp_status < 10) {
            if ((score['game_' + temp_status][0] >= 11 || score['game_' + temp_status][1] >= 11) && (Math.abs(score['game_' + temp_status][0] - score['game_' + temp_status][1]) >= 2)) {
                setGameStarted('endOfSomeGame');
                dispatch(setButtonBlink('send_button_field blink'));
            } else {
                dispatch(setButtonBlink('send_button_field'));
                /* when the game score is modified from winner to no winner we have to enable set the game in progress but
                   also we have to keep the game in started mode if the score is not changed (0:0) 
                */
                if (temp_game_started !== 'started' ||  (score['game_' + temp_status][0] !== 0 || score['game_' + temp_status][1] !== 0)) {
                    setGameStarted('inProgress');
                }
            }
        }

        dispatch(setCommandLogArrays(cloneDeep(commandLogArrays)));
        /*On load we need to update user (if we have such taken control on other CC) */
        dispatch(setLock(temp_lock));

    }

    useEffect(() => {
        /* Check on what phase is hook gameStarted and show/hide Serve buttons  */
        if (gameStarted === 'started') {
            switch (firstServeTeam) {
                case 1:
                    /* Show only home SERVE button available */
                    dispatch(setTeamButtonsDisabled({ home: { serve: false, others: true }, away: { serve: true, others: true } }));
                    break;
                case 2:
                    /* Show only away SERVE button available */
                    dispatch(setTeamButtonsDisabled({ home: { serve: true, others: true }, away: { serve: false, others: true } }));
                    break;

                default:
                    break;
            }
        }

        /* Show all buttons if we are inProgress state and match is updating */
        else if (gameStarted === 'inProgress') {
            /* If match is in progress and > game_1 , we need to show all buttons */
            dispatch(setTeamButtonsDisabled({ home: { serve: false, others: false }, away: { serve: false, others: false } }));
        }

        else if (gameStarted === 'endOfSomeGame') {
            /* If match is endOfSomeGame or we are in End of game */
            dispatch(setTeamButtonsDisabled({ home: { serve: true, others: true }, away: { serve: true, others: true } }));
        }
        // eslint-disable-next-line	
    }, [firstServeTeam, gameStarted]);


    useEffect(() => {
        if (newCommand.length) {
            populateData(newCommand);
        }
        // eslint-disable-next-line	
    }, [newCommand]);

    useEffect(() => {
        populateData(db_data);
        // eslint-disable-next-line	
    }, [db_data]);

    /* on Every update of currentStatus we need to suggest the End of this status so we update 'status' hook */
    useEffect(() => {
        /* Skip Interrupted/Abandoned/Cancelled/Finished status to not update suggestion to the status dropdown */
        if (currentStatus < 10) {
            /* For example: if played 5 sets and we goes to End of 5 Set , this mean the currentStatus = -5 , so we need to suggest the next available -> Finished */
            if (currentStatus < 0 && (Math.abs(currentStatus) === sets || checkMatchIsFinished(sets, overallGameScore))) {
                dispatch(setSportSuggestedStatus({tabletennis:111}));
                dispatch(setButtonBlink('send_button_field blink'));
            } else {
                dispatch(setSportSuggestedStatus({tabletennis:allStatusesAvailable[currentStatus].nextStatus}));
            }
        } else if ([100, 101, 102, 111].includes(currentStatus)) {
            /*if refresh the page and the current status is one of the Interrupted/Abandoned/Cancelled/Finished 
            we should set the status dropdown to the correct one */
            dispatch(setSportSuggestedStatus({tabletennis:currentStatus}));
        }
        // eslint-disable-next-line	
    }, [currentStatus])

    return (
        <div data-testid="tennis-table-wrapper" className={classes.tennisTableWrapper}>

            <OverlayList/>

            <NavBar/>

            <div className={classes.match_data}>

                <EventInfo />

                <SimpleTabs />

                <div className={classes.command_log}>
                    <CommandLog />
                    <Statistics />
                </div>

                <Reset/>

            </div>
        </div>
    );
}
