import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { selectEventInfo } from '../../../../store/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        minWidth: '50%',
    },
}));

const StartDate = () => {
    const classes = useStyles();
    const eventInfo = useSelector(selectEventInfo);
    const start_date = [eventInfo?.startdate || 'No start date Available'];

    return (
        <div className="match_start_date" style={Object.assign({}, styles.game_info_child_divs)}>
            <form className={classes.formControl} noValidate autoComplete="off">
                <TextField data-testid="start-date-textfield" className="startDateTextfield" data-cy="textfield-start-date" disabled id="standard-basic" label={start_date} helperText="Match Start Date" />
            </form>
        </div>
    )
}

let styles = {
    game_info_child_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '99%',
    },
}

export default StartDate;