import { makeStyles } from "@material-ui/core";

export const useDialogConfirmPopupStyles = makeStyles((theme) => ({
	form_root: {
        textAlign: 'center',
        width: '100%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        //textAlign: 'center',
        margin: 2,
        width: '100%',
        //maxWidth: 1500,
        textTransform: 'capitalize'
    },
    formControlYards: {
        //textAlign: 'center',
        margin: 2,
        width: '100%',
        //maxWidth: 1500,
        textTransform: 'capitalize'
    },
    buttonField: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));