/* eslint-disable no-lone-blocks */
import React from 'react';
import { useOverlayListStyles } from './OverlayListStyles';
import { selectOverlayDisplay } from '../../store/selectors';
import { useSelector } from 'react-redux';

{/* This is Global OVERLAY div! overlayDisplay is 'none' or 'block' depend on if the command is processed */ }
const OverlayList = () => {

    const classes = useOverlayListStyles();
    const overlayDisplay = useSelector(selectOverlayDisplay);
    
    return (
        <div data-testid="overlay-display" className={overlayDisplay === 'none' ? classes.overlay_sending_commands_none : classes.overlay_sending_commands_block} >
        </div>
    )
}


export default OverlayList;

