import React from 'react';
import Button from '@material-ui/core/Button';
import { allNoTeamCommands, allNoTeamCommandsKeys, allStatusesAvailable, allTeamCommands, allTeamCommandsKeys } from '../common/constants';
import { useLastCommandFieldStyles } from './LastCommandFieldStyles';
import { selectCommandLogArrays } from '../../../store/selectors';
import { useSelector } from 'react-redux';

const LastCommandField = ({ handleClickOpenCommandList }) => {

    const classes = useLastCommandFieldStyles();
    const commandLogArrays = useSelector(selectCommandLogArrays);
    const commandIdStr = typeof commandLogArrays.all[0] !== 'undefined' &&  typeof commandLogArrays.all[0].id !== 'undefined' ? '#' + commandLogArrays.all[0].id : '';

    return (
        <div data-testid="last-command-label" data-cy="last-command-label" className={commandLogArrays.all[0] ? classes.last_command_field : classes.hide}>
            Last Command:
            <h5 data-testid="last-command-text" style={commandLogArrays.all[0]?.isCancelled ? Object.assign({ textDecoration: 'line-through' }) : null}>
                {
                    commandIdStr + " " +
                    (commandLogArrays.all[0]?.team === 1 ? 'Home ' : commandLogArrays.all[0]?.team ? 'Away ' : '') +
                    (
                        (commandLogArrays.all[0]?.key === 'gamestart' || commandLogArrays.all[0]?.key === 'gamefinish' || commandLogArrays.all[0]?.key === 'matchstatus') ?
                            'status'.charAt(0).toUpperCase() + 'status'.slice(1) + ' ' + /* Make it will capital first letter */
                            allStatusesAvailable[commandLogArrays.all[0]?.key === 'gamefinish' ? (commandLogArrays.all[0]?.status * -1) : commandLogArrays.all[0]?.status]?.title /* Take correct gameNumber ( positive or negative number depending on the status) */
                            :
                            (commandLogArrays.all[0]?.key.charAt(0).toUpperCase() + commandLogArrays.all[0]?.key.slice(1))
                    )
                }
            </h5>

            <Button
                disabled={commandLogArrays.all[0]?.isCancelled ||
                    (allTeamCommandsKeys.indexOf(commandLogArrays.all[0]?.key) !== -1 ?
                        allTeamCommands[commandLogArrays.all[0]?.key].cancelDisabled
                        :
                        allNoTeamCommandsKeys.indexOf(commandLogArrays.all[0]?.key) !== -1 && allNoTeamCommands[commandLogArrays.all[0]?.key].cancelDisabled)
                }
                data-cy="button-last-command-cancel"
                data-testid="button-last-command-cancel"
                onClick={() => {
                    /* Find that index of the last edited command */
                    let index: number;
                    commandLogArrays.edit_cancel_commands.some(function (elem, i) {
                        return elem.id === commandLogArrays.all[0].id ? (index = i, true) : false;
                    });
                    handleClickOpenCommandList('cancel', index, false)
                }
                }
                className={classes.last_command_button}
                variant="contained"
                color="primary"
                size="small"
            >
                Cancel
            </Button>
        </div>
    )
}

export default LastCommandField;