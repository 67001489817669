import { makeStyles } from "@material-ui/core";

export const useEventInfoStyles = makeStyles((theme) => ({
    event_info: {
        margin: "0 0 0 0.5%",
        //borderRadius: '15px',
        height: "7%",
        //width: '100%',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundImage: "linear-gradient(to top, #dfe9f3 0%, white 100%)",
    },
    flex_divs: {
        display: "flex",
        alignItems: "center",
        width: "33%",
        justifyContent: "center",
    },
    teams_and_score_field: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "70%",
        height: "100%",
    },
    teams_and_score: {
        display: "flex",
        justifyContent: "center",
        //fontWeight: 'bold',
        fontSize: "1.1em",
        //gap: '1%',
        width: "100%",
    },
    divs_team_name_field: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "33%",
    },
    score: {
        padding: "0 2%",
        border: "1px solid",
        borderRadius: "15px",
        fontSize: "1.8em",
    },
}));
