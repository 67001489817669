import { makeStyles } from "@material-ui/core";

export const useSRTMainButtonStyles = makeStyles((theme) => ({
	 paperWrapper: {
        height: '100%',
        //display: 'flex',
        //justifyContent: 'center',
        //alignItems: 'center',
    },
    paper: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        //border: '0.5px solid grey',
        //width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
}));