import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CommentsDropdown from './CommentsDropdown';
import { useSelector } from 'react-redux';
import { selectStatus, selectCommandId } from '../../../../../store/selectors';
import { handleSendMainCommands } from '../../../utils';

const PredefinedComments = () => {

    const [premadeText, setPremadeText] = useState('');
    const currentStatus = useSelector(selectStatus);
    const commandId = useSelector(selectCommandId);

    return (
        <div className="predefined_field" style={styles.predefined_field}>

            <CommentsDropdown premadeText={premadeText} setPremadeText={setPremadeText} />

            <Button
                id="send_premade_comment"
                variant="outlined"
                color="primary"
                size="small"
                data-cy="button-predefined-comment-send"
                className="send_premade_comment"
                disabled={premadeText === '' ? true : false}
                onClick={() => {
                    handleSendMainCommands(commandId, 'command', 'comment', 0, Math.abs(currentStatus * 1), true, false, false, false, 'premade', premadeText, false);
                    setPremadeText('');
                }}
            >
                {('send')}
            </Button>
        </div>
    )
}

let styles = {
    predefined_field: {
        display: 'flex',
        width: '35%',
        justifyContent: 'center',
        //justifyContent: 'flex-start',
    },
    form_root: {
        width: '60%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        margin: 2,
        width: '90%',
        maxWidth: 500,
        //textTransform: 'capitalize' as 'capitalize'
    },
}

export default PredefinedComments;