import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { useAppBarStyle } from './AppBarStyle';
import { useSelector } from 'react-redux';
import { selectFirstServeDisplay, selectStatus, selectCommandId } from '../../../store/selectors';
import { handleSendMainCommands } from '../utils';

const ApBar = ({ value, setValue }) => {

    const classes = useAppBarStyle();
    const firstServeDisplay = useSelector(selectFirstServeDisplay);
    const currentStatus = useSelector(selectStatus);
    const commandId = useSelector(selectCommandId);

    const ButtonInTabs = ({ children }) => {
        return <Button children={children} />;
    };


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue); /* Change tabs (UPDATES , EDIT/CANDEL , GAME INFO ) with this func */
    };

    return (
        <AppBar id="updatesEditCancelGameInfo_appbar" position="static" className={classes.updatesEditCancelGameInfo_appbar}>
            <Tabs data-testid="tabs-wrapper" className="TabsWrapper" value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab data-testid="tabs-items-0" data-cy="tab-updates" label="Updates" id="simple-tab-0" aria-controls='simple-tabpanel-0' />
                <Tab data-testid="tabs-items-1" data-cy="tab-edit-cancel" label="Edit/Cancel" id="simple-tab-1" aria-controls='simple-tabpanel-1' />
                <Tab data-testid="tabs-items-2" data-cy="tab-game-info" label="Game Info" id="simple-tab-2" aria-controls='simple-tabpanel-2' />
                <ButtonInTabs>
                    <div
                        data-testid="button-first-serve-home"
                        style={Object.assign({ display: firstServeDisplay }, styles.home_away_serve)}
                        className="home_serve_button"
                        data-cy="button-first-serve-home"
                        onClick={() => {
                            /* Sanity check to forbit adding this command into Not Started & Interrupted statuses */
                            // if (!suggestedStatus || suggestedStatus === 10) {
                            //   setOpenConfirmPopUp({type: 'notStarted' , open: true, cancel_yes: 'none', ok: 'block'});
                            //   return;  
                            // }
                            handleSendMainCommands(commandId, 'command', 'firstServe', 1, Math.abs(currentStatus * 1), true, false, false, false, '', '', false);
                        }}>
                        Home SERVE
                    </div>
                </ButtonInTabs>
                <ButtonInTabs>
                    <div
                        data-testid="button-first-serve-away"
                        style={Object.assign({ display: firstServeDisplay }, styles.home_away_serve)}
                        className="away_serve_button"
                        data-cy="button-first-serve-away"
                        onClick={() => {
                            /* Sanity check to forbit adding this command into Not Started & Interrupted statuses */
                            // if (!suggestedStatus || suggestedStatus === 10) {
                            //   setOpenConfirmPopUp({type: 'notStarted' , open: true, cancel_yes: 'none', ok: 'block'});
                            //   return;  
                            // }
                            handleSendMainCommands(commandId, 'command', 'firstServe', 2, Math.abs(currentStatus * 1), true, false, false, false, '', '', false);
                        }}>
                        Away SERVE
                    </div>
                </ButtonInTabs>
            </Tabs>
        </AppBar>
    )
}

let styles = {
    home_away_serve: {
        borderRadius: '5px',
        border: '1px solid',
        padding: '0 2%',
        backgroundImage: 'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)',
    },
}

export default ApBar;