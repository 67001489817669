import React from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatus, selectTeamButtonsDisabled, selectUncfPointId, selectCommandId } from '../../../store/selectors';
import { setOpenConfirmPopUp, setPopUpCommand } from '../../../store/actions';
import { handleSendMainCommands } from '../utils';

const TeamButtons = ({ team }) => {

    let teamAsInteger = team === 'home' ? 1 : 2;
    const currentStatus = useSelector(selectStatus);
    const buttonsDisabled = useSelector(selectTeamButtonsDisabled);
    const uncfPointId = useSelector(selectUncfPointId);
    const commandId = useSelector(selectCommandId);
    const dispatch = useDispatch();

    return (
        <div className="team_buttons" style={styles.team_buttons}>
            <Button
                disabled={buttonsDisabled[team].serve}
                onClick={() => {
                    if (currentStatus === 0) {
                        dispatch(setOpenConfirmPopUp({ type: 'notStarted', open: true, cancel_yes: 'none', ok: 'block' }));
                        return;
                    }
                    handleSendMainCommands(commandId, 'command', 'serve', teamAsInteger, Math.abs(currentStatus * 1), true, false, false, false, '', '', false);
                }}
                data-cy={"button-serve-" + team}
                data-testid={"button-serve-" + team}
                variant="outlined"
                size="large"
                style={Object.assign({ backgroundImage: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)' }, styles.buttons_border_radius)}
            >
                Serve
            </Button>

            <Button
                disabled={buttonsDisabled[team].others}
                onClick={() => {
                    if (currentStatus === 0) {
                        dispatch(setOpenConfirmPopUp({ type: 'notStarted', open: true, cancel_yes: 'none', ok: 'block' }));
                        return;
                    }
                    handleSendMainCommands(commandId, 'command', 'let', teamAsInteger, Math.abs(currentStatus * 1), true, false, false, false, '', '', false);
                }}
                data-cy={"button-let-" + team}
                data-testid={"button-let-" + team}
                variant="outlined"
                size="large"
                style={Object.assign({ backgroundImage: 'linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%)' }, styles.buttons_border_radius)}
            >
                Let
            </Button>

            <Button
                disabled={buttonsDisabled[team].others}
                onClick={() => {
                    if (uncfPointId) {
                        dispatch(setOpenConfirmPopUp({ type: 'uncf', open: true, cancel_yes: 'none', ok: 'block' }));
                        return;
                    }
                    else if (currentStatus === 0) {
                        dispatch(setOpenConfirmPopUp({ type: 'notStarted', open: true, cancel_yes: 'none', ok: 'block' }));
                        return;
                    }

                    dispatch(setOpenConfirmPopUp({ type: 'confirmed', open: true, cancel_yes: 'block', ok: 'none' }));
                    dispatch(setPopUpCommand({ tabletennis: { key: 'point', team: teamAsInteger, confirmed: false, id: commandId, modified: false, value: '' } }));
                    handleSendMainCommands(commandId, 'command', 'point', teamAsInteger, Math.abs(currentStatus * 1), false, false, false, false, '', '', false);
                }}
                data-cy={"button-point-" + team}
                data-testid={"button-point-" + team}
                variant="outlined"
                size="large"
                style={Object.assign({ backgroundColor: 'lightgreen' }, styles.buttons_border_radius)}
            >
                Point
            </Button>
            <Button
                classes={{
                    //root: classes.doba,
                }}
                disabled={buttonsDisabled[team].others}
                onClick={() => {
                    if (currentStatus === 0) {
                        dispatch(setOpenConfirmPopUp({ type: 'notStarted', open: true, cancel_yes: 'none', ok: 'block' }));
                        return;
                        /* TODO add ALERT pop up */
                    }
                    handleSendMainCommands(commandId, 'command', 'timeout', teamAsInteger, Math.abs(currentStatus * 1), true, false, false, false, '', '', false);
                }}
                data-cy={"button-timeout-" + team}
                data-testid={"button-timeout-" + team}
                variant="outlined"
                size="large"
                style={Object.assign({}, styles.buttons_border_radius, styles.timeouts)}
            >
                Timeout
            </Button>
        </div>
    )
}

let styles = {
    team_buttons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        // alignItems: 'center',
        width: '70%',
        height: '100%',
        gap: '1%',
    },
    buttons_border_radius: {
        borderRadius: '5px',
        //border : 1,
    },
    timeouts: {
        backgroundImage: 'linear-gradient(to right, #ffecd2 0%, #fcb69f 100%)',
    },
}

export default TeamButtons;