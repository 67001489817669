import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import { FormControl, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectStatus, selectCommandId } from '../../../../../store/selectors';
import { handleSendMainCommands } from '../../../utils';

const CustomComment = () => {

    const [customText, setCustomText] = useState('');
    const currentStatus = useSelector(selectStatus);
    const commandId = useSelector(selectCommandId);

    const handleChangeCustomText = (event) => {
        setCustomText(event.target.value)
    }

    return (
        <div className="custom_text" style={styles.all_texts}>
            <form className="root" autoComplete="off" style={styles.form_root}>
                <FormControl id="input_custom_comment" className="formControl" style={styles.formControl}>
                    <TextField
                        className="custom-comment"
                        label={('*custom comment')}
                        data-cy="custom-comment"
                        data-testid="custom-comment"
                        //style={styles.textField}
                        value={customText}
                        onChange={handleChangeCustomText}
                        inputProps={{
                            name: 'custom_comment'
                        }} />
                </FormControl>
            </form>
            <Button
                id="send_custom_comment"
                variant="outlined"
                color="primary"
                size="small"
                //style={styles.send_button} 
                className="send_custom_comment"
                data-cy="button-custom-comment-send"
                disabled={customText === '' ? true : false}
                onClick={() => {
                    handleSendMainCommands(commandId, 'command', 'comment', 0, Math.abs(currentStatus * 1), true, false, false, false, 'custom', customText, false);
                    setCustomText('');
                }}
            >
                {('send')}
            </Button>

        </div>
    )
}

let styles = {
    all_texts: {
        display: 'flex',
        width: '35%',
        justifyContent: 'center',
    },
    form_root: {
        width: '60%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        margin: 2,
        width: '90%',
        maxWidth: 500,
        //textTransform: 'capitalize' as 'capitalize'
    },
}

export default CustomComment;