import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { selectEventInfo } from '../../../../store/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        minWidth: '50%',
    },
}));

const Venue = () => {
    const classes = useStyles();
    const eventInfo = useSelector(selectEventInfo);
    const eventInfoVenue = eventInfo?.venue?.ground || 'No Venue';
    return (
        <div className="venue_information_field" style={Object.assign({}, styles.game_info_child_divs)}>
            <form className={classes.formControl} noValidate autoComplete="off">
                <TextField data-testid="venueTextField" className="venueTextField" data-cy="textfield-venue" disabled id="standard-basic" label={eventInfoVenue} helperText="Venue information" />
            </form>
        </div>
    )
}

let styles = {
    game_info_child_divs: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '99%',
    },
}

export default Venue;