import React from "react";
import CollectionTool from './components/CollectionTool';
 // eslint-disable-next-line
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export const url = window.location.search;
const params = new URLSearchParams(url);
export let sport = params.get('sport');
export let event_id = params.get('event_id');
export let page = params.get('page');

const App = () => {

    return (
        <div className="App" id="App" data-testid="App">
            <Switch>
                <Route exact path="/" render={() => <CollectionTool sport={sport} page={page} event_id={event_id}/>} />
            </Switch>
        </div>
    );
}



export default App;
