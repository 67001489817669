import React, { useState } from 'react';
import CommandList from '.././CommandLog/CommandList';
import './SimpleTabs.css';
import ApBar from './AppBar';
import ParticipantField from './ParticipantField';
import StatusField from './StatusField';
import LastCommandField from './LastCommandField';
import LockOverlay from './LockOverlay';
import TeamButtons from './TeamButtons';
import PredefinedComments from './Comments/PredefinedComments/PredefinedComments';
import CustomComment from './Comments/CustomComment/CustomComment';
import ControlledBy from './ControlledBy';
import Venue from './GameInfo/Venue';
import GameInfo from './GameInfo/GameInfo';
import StartDate from './GameInfo/StartDate';
import OpenClose from './OpenClose';
import TakeReleaseControl from './TakeReleaseControl';
import Header from './GameInfo/Header';
import EditCancelDialog from './EditCancelDialog';
import SanityCheckDialog from './SanityCheckDialog';
import { useSimpleTabsStyle } from './SimpleTabsStyles';
import { selectCommandId, selectScore, selectCommandLogArrays } from '../../../store/selectors';
import { setPopUpCommand } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TabPanel } from '../TabPanel';
import { handleSendMainCommands } from '../utils';
import cloneDeep from 'lodash.clonedeep';

export default function SimpleTabs() {

    const score = useSelector(selectScore);
    
    const classes = useSimpleTabsStyle();
    const [value, setValue] = useState(0);

    /*Commented since MSF want to set on their own the current status*/
    //const currentMSFStatus = useSelector(selectMSFStatus);

    const commandId = useSelector(selectCommandId);
    const commandLogArrays = cloneDeep((useSelector(selectCommandLogArrays)));
    const dispatch = useDispatch();

    /* Hooks to update the previous added pre match/comments values */
    /* THIS WILL BE CHANGE TO REDUX STORE INSTEAD OF HOOKS */

    /* Hook for openning DIALOG for EDIT/CANCEL commands */
    const [open, setOpen] = useState(false);
    /* Hook to keep team value when pressing on EDIT button */
    const [team, setTeam] = useState('');
    /* Update the team HOOK when chaning from dropdown */
    const handleChangeTeam = (event) => {
        setTeam(event.target.value)
    }
    /* Hook for take the correct index(row) user click to delete */
    const [cancelEditProps, setCancelEditProps] = useState({
        type: 'edit',
        index: 0
    });
    /* This func is used when user is in CANCEL/EDIT pop up and needs action to be taken (Deleting/Editing the command or closing the pop up) */
    const handleCloseCommandList = (type: string, confirmOrDelete: boolean, edit: any, index?: any,) => {
        /* close dialog pop up */
        setOpen(false);
        /* Send the correct team value when editting */

        const temp_command = commandLogArrays.edit_cancel_commands[cancelEditProps.index];
        let isModified = false;
        
        /* if commands need to be deleted or Confirmed we send the command with needed props/values */
        if (confirmOrDelete && index !== null) {
            /* edit/confirm the command */
            if (type === 'edit') {
                const temp_team = team === 'home' ? 1 : 2;
                if (temp_command.isConfirmed) {
                    isModified = true;
                    /* Take command team which we will EDIT */
                    let commandTeam = temp_command.team;
                    let otherTeam = commandTeam === 1 ? 2 : 1;

                    /* Sanity check for Invalid score - SRT-1308 */
                    if (((score.current_score[otherTeam - 1] + 1 > 11 &&
                        Math.abs((score.current_score[otherTeam - 1] + 1) - (score.current_score[commandTeam - 1] - 1)) > 2))) {
                        /* Open dialog pop up and Update the hook for invalidScore vision*/
                        setOpen(true);
                        setCancelEditProps({ type: 'invalidScore', index: index });
                    }
                    else {
                        /*When we edit firstServe command we send a new firstServe command and not an edited one*/
                        if (temp_command.key === 'firstServe') {
                            handleSendMainCommands(
                                commandId, 
                                'command', 
                                'firstServe', 
                                temp_team, 
                                Math.abs(temp_command.status * 1), 
                                true, 
                                false, 
                                false, 
                                false,
                                '', 
                                '', 
                                false
                            );
                        } else {
                            handleSendMainCommands(
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                                'command',
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                                temp_team,
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                                true,
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled,
                                isModified,
                                false,
                                '',
                                '',
                                edit,
                                temp_command.feed.pointNumber /* take pointNumber from the sent command feed object */
                            )
                        }
                    }
                }
                else {
                    handleSendMainCommands(
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                        'command',
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                        temp_team,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                        true,
                        commandLogArrays.edit_cancel_commands[cancelEditProps.index].isCancelled,
                        isModified,
                        false,
                        '',
                        '',
                        edit,
                        temp_command.feed.pointNumber /* take pointNumber from the sent command feed object */
                    )
                }
            }

            /* Delete the command */
            else {
                let isCancelled = true;
                //special case for comments which we actually do not delete , but only send different matchstatus to MSF
                if (commandLogArrays.edit_cancel_commands[cancelEditProps.index].key === 'comment' && 
                (commandLogArrays.edit_cancel_commands[cancelEditProps.index].text.startsWith('Walkover competitor') || commandLogArrays.edit_cancel_commands[cancelEditProps.index].text.startsWith('Retirement competitor'))) 
                {
                    edit = 'delete';
                    isCancelled = false;
                }
                
                handleSendMainCommands(
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].id,
                    'command',
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].key,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].team,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].status,
                    commandLogArrays.edit_cancel_commands[cancelEditProps.index].isConfirmed,
                    isCancelled,
                    isModified,
                    false,
                    'unknown',
                    temp_command.text,
                    edit
                )
            }
        }
    };

    /* This func is called when user click at the Cancel or Edit button in EDIT/Cancel tab and we parse index of the row  */
    const handleClickOpenCommandList = (type: string, index: any, teamAvailable) => {

        /* Update store item as we need props of the command which we want to Cancel/Edit */
        dispatch(setPopUpCommand({
            tabletennis: {
                key: commandLogArrays.edit_cancel_commands[index].key,
                team: commandLogArrays.edit_cancel_commands[index].team,
                confirmed: commandLogArrays.edit_cancel_commands[index].isConfirmed,
                id: commandLogArrays.edit_cancel_commands[index].id,
                modified: commandLogArrays.edit_cancel_commands[index].isModified,
                //type: commandLogArrays.edit_cancel_commands[index].type,
                value: commandLogArrays.edit_cancel_commands[index].text
            }
        }));

        if (teamAvailable) {
            /* Take the team value when click on EDIT button + YES for the command in question */
            setTeam(commandLogArrays.edit_cancel_commands[index].team === 1 ? 'home' : 'away');
        }
        /* Open dialog pop up */
        setOpen(true);
        /* Update the hook to keep the correct props */
        setCancelEditProps({ type: type, index: index });
    };

    return (
        <div className={classes.tabs}>
            <div data-testid="simpleTabsWrapper" id="simpleTabsWrapper" className={classes.root}>

                <ApBar value={value} setValue={setValue} />

                <TabPanel uniqueLabel="updateEditCancelInfo-tab" uniqueId="updateEditCancelInfo-tabpanel" data-cy="updates-tab" className={classes.everyUpTab} value={value} index={0}>
                    <div className={classes.home_away_participants_field}>
                        <ParticipantField team='home' />

                        <div className={classes.set_status_delete_last_command_field}>
                            <StatusField />

                            <LastCommandField handleClickOpenCommandList={handleClickOpenCommandList} />
                        </div>

                        <ParticipantField team='away' />
                    </div>

                    <div className={classes.home_away_buttons}>
                        <LockOverlay />

                        <div className={classes.field_buttons}>
                            <TeamButtons team={'home'} />
                        </div>

                        <div className={classes.both_team_buttons}>
                            <OpenClose />

                            <TakeReleaseControl />
                        </div>

                        <div className={classes.field_buttons}>
                            <TeamButtons team={'away'} />
                        </div>
                    </div>

                    <div className={classes.comments}>
                        <PredefinedComments />

                        <ControlledBy />

                        <CustomComment />
                    </div>
                </TabPanel>

                <TabPanel uniqueLabel="updateEditCancelInfo-tab" uniqueId="updateEditCancelInfo-tabpanel" data-cy="edit-cancel-tab" className={classes.everyUpTab} value={value} index={1}>
                    <CommandList 
                        commandLogArray={commandLogArrays.edit_cancel_commands}
                        showButtons={true} 
                        tab={'editCancel'} 
                        handleClickOpenCommandList={handleClickOpenCommandList} 
                    />
                </TabPanel>

                <TabPanel uniqueLabel="updateEditCancelInfo-tab" uniqueId="updateEditCancelInfo-tabpanel" data-cy="game-info-tab" className={classes.everyUpTab} value={value} index={2}>
                    <div className={classes.game_info_field}>

                        <Header />

                        <div className={classes.game_info}>
                            <Venue />

                            <GameInfo type="preMatchInfo" />

                            <GameInfo type="attendance" />

                            <StartDate />
                        </div>

                        <div className={classes.game_info}>
                            <div className={classes.game_info_colors_divs}>
                                <GameInfo type="homeColor1" />

                                <GameInfo type="homeColor2" />
                            </div>

                            <div className={classes.game_info_colors_divs}>
                                <GameInfo type="awayColor1" />

                                <GameInfo type="awayColor2" />
                            </div>
                        </div>
                    </div>
                </TabPanel>

                {/* Dialog pop up which will be open for Edit or Cancel command */}
                <EditCancelDialog 
                    open={open} cancelEditProps={cancelEditProps} team={team} handleChangeTeam={handleChangeTeam}
                    handleCloseCommandList={handleCloseCommandList}
                />

                {/* Dialog pop up which will be open for notStarted/uncf points/Confirmation/noFirstServe added pop ups */}
                <SanityCheckDialog />

            </div>

        </div>
    );
}

