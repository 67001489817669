import React, { /* useEffect */} from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CommandList from './CommandList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}

let styles = {
  paddingAndHeight: {
    padding: '0px',
    height: '100%',
  },
  every_overlay: {
    //overflow: 'auto',
  },
  TypographyDiv: {
    height: '100%',
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="TabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`commandLogs-tabpanel-${index}`}
      aria-labelledby={`commandLogs-tab-${index}`}
      style={styles.every_overlay}
      {...other}
    >
      {value === index && (
        <Box p={3} className="boxP3" style={styles.paddingAndHeight}>
          <Typography className="TypographyDiv" style={styles.TypographyDiv} component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    height: '100%',
    margin: 0,
    padding: 0,
    //zIndex:1000,
    //position: 'relative' as 'relative',
  },
  command_log_appbar:{
    height: '12%',
    //opacity: '0.9',
    backgroundColor: '#5c6bc0',
    //backgroundColor: '#66bb6a'
    //backgroundColor: '#66bb6a'
    //borderRadius: '15px'
  },
  command_log_tabpanel:{
    height : '87%',
    //borderRadius: '15px',
  }
  // show_all: {
  //   height: '50%'
  // }
}));

export default function CommandFilter({ commandLogArrays} : any) {
  
  const classes = useStyles();
  /* Hook used to change tabs (SHOW ALL , POINT ... ) */
  const [tabsValue, setTabsValue] = React.useState(0);

  /* Func to update the hook for changing tabs (SHOW ALL , POINT ... ) */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };
 
  return (
    <div id="command_filter_field" className={classes.root}>

      <AppBar id="command_log_appbar" position="static" className={classes.command_log_appbar}>
        <Tabs value={tabsValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Show All" {...a11yProps(0)} />
          <Tab label="Point" {...a11yProps(1)} />
          <Tab label="Comments" {...a11yProps(2)} />
          <Tab label="Info" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.command_log_tabpanel} value={tabsValue} index={0}>
         <CommandList commandLogArray={commandLogArrays.all} mapCommandLog={commandLogArrays.map_command_log}  deletedCommandIds={commandLogArrays.deleted_commands_ids} showButtons={false} tab={'showAll'} />
      </TabPanel>
      <TabPanel value={tabsValue} index={1}>
         <CommandList commandLogArray={commandLogArrays.points} mapCommandLog={commandLogArrays.map_command_log}  deletedCommandIds={commandLogArrays.deleted_commands_ids} showButtons={false} tab={'point'} />
      </TabPanel>
      <TabPanel value={tabsValue} index={2}>
         <CommandList commandLogArray={commandLogArrays.comments} mapCommandLog={commandLogArrays.map_command_log}  deletedCommandIds={commandLogArrays.deleted_commands_ids} showButtons={false} tab={'comments'} />
      </TabPanel>
      <TabPanel value={tabsValue} index={3}>
         <CommandList commandLogArray={commandLogArrays.info} mapCommandLog={commandLogArrays.map_command_log}  deletedCommandIds={commandLogArrays.deleted_commands_ids} showButtons={false} tab={'info'}/>
      </TabPanel>
    </div>
  );
}
