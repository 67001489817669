import React from 'react';
import { useStatisticsStyles } from './StatisticsStyle';
import { useSelector } from 'react-redux';
import { selectScore, selectOverallGameScore } from '../../store/selectors';
import { store } from '../../store';

export const allStatisticsNeeded = (classes, score) => {
    let arr = [];
    const eventInfo = store.getState().eventInfo;
    const sets = eventInfo?.sets || 9;
    
    for (let index = 1; index <= sets; index++) {
        let name = "game_" + index;
        arr.push(
            <div key={index} className={classes.stat}>
                <div data-testid={"game-score-" + index + "-home"} data-cy={"game-score-" + index + "home"}>
                    {score[name] ? score[name][0] : 0}
                </div>
                <div data-testid={"game-score-" + index + "-label"} data-cy={"game-score-" + index + "label"}>
                    Game Score {index}
                </div>
                <div data-testid={"game-score-" + index + "-away"} data-cy={"game-score-" + index + "away"}>
                    {score[name] ? score[name][1] : 0}
                </div>
            </div>,
        );
    }
    return arr;
};

/* Function that populate all needed SETS Statistics taken from event_info */
const Statistics = () => {

    const classes = useStatisticsStyles();
    const score = useSelector(selectScore);
    const overallGameScore = useSelector(selectOverallGameScore);

    return (
        <div className={classes.statistics}>
            <div><b data-testid="statistics" data-cy="statistics">Statistics</b></div>

            <br></br>

            <div className={classes.stat}>
                <div data-testid="overall-game-score-home" data-cy="overall-game-score-home">{overallGameScore[0]}</div>
                <div data-testid="overall-game-score-label" data-cy="overall-game-score-label">Overall Game Score</div>
                <div data-testid="overall-game-score-away" data-cy="overall-game-score-away">{overallGameScore[1]}</div>
            </div>

            <div className={classes.stat}>
                <div data-testid="current-points-home" data-cy="current-points-home">{score.current_score ? score.current_score[0] : 0}</div>
                <div data-testid="current-points-label" data-cy="current-points-label">Current Points</div>
                <div data-testid="current-points-away" data-cy="current-points-away">{score.current_score ? score.current_score[1] : 0}</div>
            </div>

            {/* Add needed game score fields according to SETS from event_info */}
            {allStatisticsNeeded(classes, score)}

        </div>
    )
}

export default Statistics;