import { makeStyles } from "@material-ui/core";

export const useCommandFilterStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        height: "100%",
        margin: 0,
        padding: 0,
        //zIndex:1000,
        //position: 'relative' as 'relative',
    },
    command_log_appbar: {
        // height: "12%",
        //borderRadius: '15px'
    },
    command_log_tabpanel: {
        //backgroundImage: "linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)",
        //backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
        height: "85%",
        //borderRadius: '15px',
    },
    // show_all: {
    //   height: '50%'
    // }
}));
