import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { allStatusesAvailable } from '../common/constants';


export default function CommandRow({ id, isConfirmed, isCancelled, home_score, away_score, sequence, mapCommandLog, tab }: any) {
    /* TODO - Probably we gonna need user to be display for every command !?! */
    const command = mapCommandLog[sequence];
    const allUncfCommands = ['point'];
    const noTeamCommands = ['gamescore', 'gamestart', 'gamefinish', 'matchstatus', 'status_ok', 'status_unsure', 'comment', 'preMatchInfo', 'attendance', 'pitch', 'confirmScore', 'homeColor1', 'homeColor2', 'awayColor1', 'awayColor2'];
    const valueAndTextCommands = ['comment', 'preMatchInfo', 'attendance', 'pitch', 'homeColor1', 'homeColor2', 'awayColor1', 'awayColor2'];
    const statuses = ['gamestart', 'gamefinish', 'matchstatus'];
    const commandText = valueAndTextCommands.includes(command.key) ? command.key + ': ' + command.value.text
        :
        statuses.includes(command.key) ? 'Status' : command.key.charAt(0).toUpperCase() + command.key.slice(1);
    const statusPrefix = command.key === 'gamefinish' ? 'End ' : '';
    const game_number = statuses.includes(command.key) ? statusPrefix + allStatusesAvailable[command.gameNumber].title : '';
    const commandTeam = noTeamCommands.includes(command.key) ? '' : command.team === 1 ? "Home" : command.team === 0 ? '' : "Away";
    const uncf_conf_canceled = allUncfCommands.includes(command.key) ? isConfirmed ? '(CNF)' : command.isCancelled ? '(CANCELLED)' : '(UNCF)' : '';
    const isModified = command.isModified ? '(MODIFIED)' : '';
    const time = command.UTCtimestamp.substring(command.UTCtimestamp.indexOf("T") + 1, command.UTCtimestamp.indexOf("."));
    const commandAdditionalInfo = command.key === 'gamescore' ? 'Game ' + command.gameNumber : '';

    return (
        <ListItem
            key={command.id}
            data-cy={tab !== 'editCancel' ? ('command-log-' + sequence) : ('edit-cancel-log-' + sequence)}
            data-testid={'command-list-item-' + sequence}
        >
            {<span data-testid={'command-list-item-time-' + sequence} style={styles.font_size_0_9}>{time}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            {<span data-testid={'command-list-item-id-' + sequence} style={styles.font_size_0_9}>{'#' + command.id}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            <ListItemText
                disableTypography
                primary={commandTeam + ' ' + commandText + ' ' + commandAdditionalInfo + ' ' + game_number + uncf_conf_canceled + isModified}
                id={command.id}
                style={isCancelled ? Object.assign({}, styles.deleted_command, styles.font_size_0_9) : Object.assign({}, styles.command, styles.font_size_0_9)}
                data-testid={'command-list-item-text-' + sequence}
            />
            {(command.key === 'point' || command.key === 'gamescore') && isConfirmed && <span data-testid={'command-list-item-score-' + sequence} style={styles.font_size_0_9}>{home_score + ' : ' + away_score}</span>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* extra space */}
            {<span data-testid={'command-list-item-user-' + sequence} style={styles.font_size_0_5}>{'(' + command?.user + ')'}</span>}
            {/* if we are not in editCancel tab and it is a conf point -> add <span> with home/away score to the row command  */}
        </ListItem>
    )
}

const styles = {
    command: {
        borderRadius: '15px',
        //backgroundColor : 'white',
        display: 'flex',
        paddingLeft: '1%',
        //justifyContent: 'center',
        class: 'normal',
    },
    deleted_command: {
        textDecoration: 'line-through',
        paddingLeft: '1%',
        display: 'flex',
        class: 'deleted',
    },
    font_size_0_9: {
        fontSize: '0.9em',
    },
    font_size_0_5: {
        fontSize: '0.5em'
    }
}
