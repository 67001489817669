import { makeStyles } from "@material-ui/core";

export const useParticipantFieldStyles = makeStyles((theme) => ({
    participantFieldHome: {
        fontWeight: "bold",
        fontSize: "2em",
        fontFamily: '"Times New Roman", Times, serif',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "30%",
        height: "100%",
    },
    participantFieldAway: {
        fontWeight: "bold",
        fontSize: "2em",
        fontFamily: '"Times New Roman", Times, serif',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "30%",
        height: "100%",
    },
    teamNameHome: {
        width: "70%",
        //display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textAlign: "center",
    },
    teamNameAway: {
        width: "70%",
        //display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textAlign: "center",
    },
}));
