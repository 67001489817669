import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { selectLock } from '../../../store/selectors';
import cloneDeep from 'lodash.clonedeep';

const ControlledBy = () => {
    const lock = cloneDeep(useSelector(selectLock));
    return (
        <div
            data-testid="controlled-by"
            className="contolled_by"
            style={Object.assign({}, styles.contolled_by, { display: lock.user ? 'flex' : 'none' })}
            data-cy="controlled-by"
        >
            <Typography
                data-testid="typography-controlled-by"
                color={'primary'}
                align="center"
                variant='h6'
            >
                Controlled by: {lock.user}
            </Typography>
        </div>
    )
}

let styles = {
    contolled_by: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}

export default ControlledBy;