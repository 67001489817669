import { Button } from '@material-ui/core';
import React, { useEffect } from 'react'; // we need this to make JSX compile
//import Clock from 'react-live-clock';
import 'moment-timezone';
import './NavbarAF.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectOverlayDisplay, selectConnectionStatus, selectHeartBeat } from '../../store/selectors';
import { setHeartBeat } from '../../store/actions';
import { useNavBarAFStyles } from './NavBarAFStyles';


const NavBar = ({ data, currentStatus, allStatusesAvailable }: any) => {

    const overlayDisplay = useSelector(selectOverlayDisplay);
    const classes = useNavBarAFStyles();
    const connectionStatus = useSelector(selectConnectionStatus);
    const heartBeat = useSelector(selectHeartBeat);
    const dispatch = useDispatch();

    useEffect(() => {
        /* Make the heart beat to pulse when we load from back-end and have no overlay for adding commands */
        if (overlayDisplay === 'none') {
            dispatch(setHeartBeat({
                className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
                color: connectionStatus ? 'lightgreen' : 'red',
                text: connectionStatus ? 'Connected' : 'No connection'
            }));
        }
        // eslint-disable-next-line 
    }, [overlayDisplay]);

    /* Update the hook to shows when there is no connection with back-end OR user is connected with heart beat signal */
    useEffect(() => {
        dispatch(setHeartBeat({
            className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
            color: connectionStatus ? 'lightgreen' : 'red',
            text: connectionStatus ? 'Connected' : 'No connection'
        }));

        // eslint-disable-next-line 
    }, [connectionStatus]);

    return (
        <div className={classes.navbar_desktop}>

            <div className={classes.heart_beat_field}>
                <div className={heartBeat['className']} style={styles.heart_beat}>
                    <Button
                        style={Object.assign({ backgroundColor: heartBeat['color'] }, styles.heart_beat)}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        {heartBeat['text']}
                    </Button>
                </div>
            </div>

            {/* {allStatusesAvailable[currentStatus].title} */}

        </div>
    );
}

let styles = {
    heart_beat: {
        borderRadius: '15px',
        //backgroundColor: 'lightgreen',
    }
}

export default NavBar;