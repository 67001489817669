import cloneDeep from 'lodash.clonedeep';
import { store } from '../../store/index';
import { sportID, allPopUpCommands } from './common/constants';
import { event_id } from '../../App';
import { setOverlayDisplay, setHeartBeat } from '../../store/actions';
import { sendCommand } from '../CollectionTool.utils';

export const recalculateScore = (commandLog: any[], lastGameScoreInc: number): any[] => {
    let arr = commandLog.slice(0);
    let length = arr.length;
    let homePoints = 0;
    let awayPoints = 0;
    let firstPoint = false;

    for (let index = length - 1; index >= 0; index--) {
        let element = arr[index];
        if (element.key === "point") {
            if (!firstPoint) {
                element.score_home = 0;
                element.score_away = 0;
            }
            if (element.team === 1 && element.sequence > lastGameScoreInc) {
                homePoints++;
                element.score_home = homePoints;
                element.score_away = awayPoints;
            } else if (element.team === 2 && element.sequence > lastGameScoreInc) {
                awayPoints++;
                element.score_home = homePoints;
                element.score_away = awayPoints;
            }
        }

        if (element.key === "gamescore") {
            homePoints = element.feed.scoreInPoints.competitorOne;
            awayPoints = element.feed.scoreInPoints.competitorTwo;
            element.score_home = homePoints;
            element.score_away = awayPoints;
        }

        if (element.key === "gamestart") {
            homePoints = 0;
            awayPoints = 0;
            element.score_home = 0;
            element.score_away = 0;
        }

        firstPoint = true;
    }
    
    return arr;
};

/**
 * Calculate the validity of a table tennis score
 * @param point point tuble
 * @returns boolean
 */
export const validateScorePoint = (score: number[], maxScore = 11): boolean => {
    const [a, b] = cloneDeep(score);
    // Sanity check
    return (
        a >= 0 &&
        b >= 0 &&
        (((a >= maxScore || b >= maxScore) && Math.abs(a - b) === 2) ||
            (a === maxScore && b < maxScore && Math.abs(a - b) > 1)  ||
            (a < maxScore && b === maxScore && Math.abs(b - a) > 1)  ||
            (a < maxScore && b < maxScore)                           ||
            ((a >= maxScore || b >= maxScore) && Math.abs(a - b) < 2))
    );
};

export const calculateOverallGameScore = (score: number[], maxScore = 11): number[] => {
    const [home, away] = cloneDeep(score);
    let overallGameScore = [0,0];

    if (home >= maxScore && away < maxScore && (home - away) >= 2) {
        overallGameScore[0]++; 
    } else if (away >= maxScore && home < maxScore && (away - home) >= 2) {
        overallGameScore[1]++;
    } else if (home >= maxScore && away >= maxScore && (home - away) >= 2) {
        overallGameScore[0]++; 
    } else if (home >= maxScore && away >= maxScore && (away - home) >= 2) {
        overallGameScore[1]++;
    }

    return overallGameScore;
};

export const checkIsFinishedStatus  = (score: number[], maxScore = 11): boolean => {
    const [home, away] = cloneDeep(score);
    let isFinished = false;

    if ((home >= maxScore || away >= maxScore) && Math.abs(home - away) >= 2) {
        isFinished = true;
    }

    return isFinished;
};


export const handleSendMainCommands = (id: number, type: string, key: string, team: number, status: number, isConfirmed: boolean,
    isCancelled: boolean, isModified: boolean, test: boolean, type_comment?: any, value?: any, edit?: any, pointNumber?: any) => {

    let feed = {};
    let feedAction = '';

    const feedCommandInc = store.getState().feedCommandInc;
    const currentStatus = store.getState().currentStatus;
    const eventInfo = store.getState().eventInfo;
    const user = store.getState().currentUser;
    const commandInc = store.getState().commandInc;
    const connectionStatus = store.getState().connectionStatus;
    const mapIdToActionId = cloneDeep(store.getState().mapIdToActionId);
    const commandId = store.getState().commandId;
    const score = store.getState().score;
    const popUpcommand = store.getState().currentPopUpCommand['tabletennis'];
    const teams = [typeof eventInfo.team_home !== 'undefined' ? eventInfo.team_home : "No Team 1 Info", typeof eventInfo.team_away !== 'undefined' ? eventInfo.team_away : "No Team 2 Info"];

    switch (key) {
        case 'firstServe':
            feed = {
                "competitor": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                //"player": team === 1 ? "PlayerOne" : "PlayerTwo",
                "sequence": feedCommandInc,
                "isConfirmed": true,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            };

            feedAction = 'currentserver';
            break;

        case 'point':
            if (isCancelled) {
                feed = {
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            }
            else {
                feed = {
                    "gameNumber": Math.abs(currentStatus * 1) || 1,
                    "pointNumber": edit === 'edit' ? pointNumber || 1 : ((typeof score.current_score !== 'undefined' ? score.current_score[0] : 0) + (typeof score.current_score !== 'undefined' ? score.current_score[1] : 0)) + 1, /* Edit commands will take pointNumber from the sent command */
                    "pointStatus": "Won",
                    "competitor": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                    //"player": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                    "sequence": feedCommandInc,
                    "isConfirmed": isConfirmed,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            }
            feedAction = 'point';
            break;

        case 'serve':
            feed = {
                "gameNumber": Math.abs(currentStatus * 1) || 1,
                "pointNumber": edit === 'edit' ? pointNumber || 1 : ((typeof score.current_score !== 'undefined' ? score.current_score[0] : 0) + (typeof score.current_score !== 'undefined' ? score.current_score[1] : 0)) + 1, /* Edit commands will not increase PointNumber prop */
                "pointStatus": "Served",
                "competitor": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                //"player": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                "sequence": feedCommandInc,
                "isConfirmed": isConfirmed,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            }
            feedAction = 'point';
            break;

        case 'let':
            feed = {
                "gameNumber": Math.abs(currentStatus * 1) || 1,
                "pointNumber": edit === 'edit' ? pointNumber || 1 : ((typeof score.current_score !== 'undefined' ? score.current_score[0] : 0) + (typeof score.current_score !== 'undefined' ? score.current_score[1] : 0)) + 1, /* Edit commands will not increase PointNumber prop */
                "pointStatus": "Let",
                "competitor": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                //"player": team === 1 ? "CompetitorOne" : "CompetitorTwo",
                "sequence": feedCommandInc,
                "isConfirmed": isConfirmed,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            }
            feedAction = 'point';
            break;

        case 'timeout':
            feed = {
                "matchStatus": team === 1 ? "TimeoutCompetitorOne" : "TimeoutCompetitorTwo",
                "sequence": feedCommandInc,
                "isConfirmed": true,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            }
            feedAction = 'matchstatus';
            break;

        case 'status_ok':
            feed = {
                "sportId": sportID,
                "isReliable": true,
                "isConfirmed": isConfirmed,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            }
            feedAction = 'reliability';
            break;

        case 'status_unsure':
            feed = {
                "sportId": sportID,
                "reason": 'unknown',
                "isReliable": false,
                "isConfirmed": isConfirmed,
                "utcTimestamp": test ? 'test' : new Date().toISOString(),
            }
            feedAction = 'reliability';
            break;

        /* Send matchStatus feed for predifined comments */
        case 'comment':
            if (edit === 'delete' && 
                (value.startsWith('Walkover competitor') || value.startsWith('Retirement competitor'))) {                    
                feed = {
                    /*Commented since MSF want to set on their own the current status*/
                    //"matchStatus": currentMSFStatus,
                    "matchStatus": "Unknown",
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            } else {
                if (value === ('Walkover competitor ' + teams[0])) {
                    feed = {
                        "matchStatus": "WalkoverCompetitorOne",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
                if (value === 'Walkover competitor ' + teams[1]) {
                    feed = {
                        "matchStatus": "WalkoverCompetitorTwo",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
                if (value === 'Retirement competitor ' + teams[0]) {
                    feed = {
                        "matchStatus": "WinnerCompetitorOneByRetirementOrDefault",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
                if (value === 'Retirement competitor ' + teams[1]) {
                    feed = {
                        "matchStatus": "WinnerCompetitorTwoByRetirementOrDefault",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
                if (value === 'Home medical timeout') {
                    feed = {
                        "matchStatus": "MedicalTimeoutCompetitorOne",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
                if (value === 'Away medical timeout') {
                    feed = {
                        "matchStatus": "MedicalTimeoutCompetitorTwo",
                        "sequence": feedCommandInc,
                        "isConfirmed": true,
                        "utcTimestamp": test ? 'test' : new Date().toISOString(),
                    }
                }
            }

            feedAction = type_comment === 'custom' ? '' : 'matchstatus';
            //}

            break;

        case 'preMatchInfo':
            if (value === 'Players on the court') {
                feed = {
                    "matchStatus": "OnCourt",
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            }
            if (value === 'Players not on the court') {
                feed = {
                    "matchStatus": "PreMatch",
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            }
            if (value === 'Players are warming up') {
                feed = {
                    "matchStatus": "WarmingUp",
                    "sequence": feedCommandInc,
                    "isConfirmed": true,
                    "utcTimestamp": test ? 'test' : new Date().toISOString(),
                }
            }
            feedAction = 'matchstatus';
            break;

        default:
            break;
    }

    let obj = {
        type: type,
        page: 'desktop',
        value: {
            "key": key,
            "team": team,
            "isConfirmed": isConfirmed,
            "gameNumber": status,
            "event_id": event_id,
            "UTCtimestamp": test ? 'test' : new Date().toISOString(),
            "sport_id": sportID,
            "internal": true,
            "feedAction": feedAction
        },
        user: user
    };

    if (key === "comment") {
        obj.value['internal'] = false;
    }

    /* Add new prop to determine whether should be PUT request to MSF */
    if (key === 'point' && isConfirmed) {
        obj.value['putRequest'] = true;
    }

    if (type === 'command') {
        obj.value['id'] = id;
        obj.value['sequence'] = commandInc;
    }

    /* Add feed object to the command ONLY if we have full feed object with needed props for MSF */
    if (Object.keys(feed).length > 0) {
        obj.value['feed'] = feed;
    }

    if (isModified) {
        obj.value['isModified'] = true;
    }

    /* This shows when command is from EDIT/CANCEL tab */
    if (edit === 'edit') {
        obj.value['edit'] = true;
    } else if (edit === 'delete') {
        obj.value['isDeleted'] = true;
    }

    if (isCancelled) {
        obj.value['isCancelled'] = isCancelled;
        obj.value['id'] = popUpcommand['id'];

        /* Still this is not ready for MSF!!!  */
        //feed['isCancelled'] = true;
    }

    if (allPopUpCommands.includes(key) && isConfirmed) {
        obj.value['id'] = popUpcommand['id'];
    }

    if (key === 'comment' ||
        key === 'preMatchInfo' ||
        key === 'pitch' ||
        key === 'homeColor1' ||
        key === 'homeColor2' ||
        key === 'awayColor1' ||
        key === 'awayColor2' ||
        key === 'attendance'
    ) {

        if (typeof obj.value['value'] === 'undefined') {
            obj.value['value'] = { text: value }
        }
    }

    /* Show overlay pop up to prevent adding other commands before this one is processed */
    store.dispatch(setOverlayDisplay('block'));
    store.dispatch(setHeartBeat({
        className: connectionStatus ? 'heart_beat heart' : 'heart_beat',
        color: connectionStatus ? 'lightgreen' : 'red',
        text: connectionStatus ? 'Sending...' : 'No connection'
    }));

    /* related to feedId */
    /* All cancelled/modified command should have feedId  */
    if (((commandId !== id || (key === 'point' && isConfirmed) || isCancelled) && typeof mapIdToActionId[id] !== 'undefined') || test) {
        obj.value['feedId'] = test ? 1 : mapIdToActionId[id];
    }
    
    sendCommand(obj);
}

export const getInitialTTScoreState = (sets) => {
    const initialScoreState = {};
    for (let i = 1; i <= sets; i++) {
        initialScoreState['game_' + i] = [0, 0];
    }
    initialScoreState['overall_game_score'] = [0, 0];
    initialScoreState['current_score'] = [0, 0];
    return initialScoreState;
};

export const handleSendStatus = (suggestedStatus: number, test = false) => {
    const commandId = store.getState().commandId;
    const user = store.getState().currentUser;
    const commandInc = store.getState().commandInc;
    const feedCommandInc = store.getState().feedCommandInc;

    let obj = {
        type: "command",
        page: "desktop",
        value: {
            "id": commandId,
            "key": (suggestedStatus * 1) < 100 ? ((suggestedStatus * 1) > 0 ? 'gamestart' : 'gamefinish') : 'matchstatus',
            "team": 0,
            "isConfirmed": true,
            "gameNumber": Math.abs(suggestedStatus * 1),
            "event_id": event_id,
            "sequence": commandInc,
            "internal": (suggestedStatus * 1) < 100 ? ((suggestedStatus * 1) > 0 ? true : false) : false,
            "UTCtimestamp": test ? 'test' : new Date().toISOString(),
            "sport_id": sportID,
        },
        user: user
    };

    let matchStatus = '';

    switch (suggestedStatus * 1) {
        case (100):
            matchStatus = 'PlaySuspended'
            break;
        case 101:
            matchStatus = 'Abandoned'
            break;
        case 102:
            matchStatus = 'Cancelled'
            break;

        default:
            break;
    }

    if (matchStatus !== '') {
        obj.value['feed'] = {
            "matchStatus": matchStatus,
            //"id": commandId,
            "sequence": feedCommandInc,
            "isConfirmed": true,
            "utcTimestamp": test ? 'test' : new Date().toISOString(),
        }

        obj["feedAction"] = 'matchstatus';
    }
    
    /* Show overlay pop up to prevent adding other commands before this one is processed */
    store.dispatch(setOverlayDisplay('block'));
    sendCommand(obj);
};

export const checkMatchIsFinished = (sets: number, matchScore: number[]) : boolean => {
    const [home, away] = cloneDeep(matchScore);
    let matchIsFinished = false;

    if (home === Math.ceil(sets / 2) || away === Math.ceil(sets / 2)) {
        matchIsFinished = true;
    }

    return matchIsFinished;
};