export const isAuthorized = async (lambdaPingHost: string) => {
    // console.log('in isAuthorized' , lambdaPingHost);
    const res = await fetch(`${lambdaPingHost}/lambda/Ping`, {
        credentials: "include",
    });
    // console.log('res = ' , res);

    return res.status === 200;
};

export const completeAuthorization = async (
    clientId: string,
    redirectURI: string,
    lambdaCookiesHost: string,
) => {
    const urlParams = new window.URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const postParams = {
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: redirectURI,
        code: code,
    };

    const exchangeRes = await fetch(`${lambdaCookiesHost}/lambda/Cookies`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(postParams),
    });

    if (exchangeRes.status !== 200) {
        throw new Error();
    }

    window.location.assign(
        localStorage.getItem("parentUrl") !== null
            ? localStorage.getItem("parentUrl")
            : localStorage.getItem("url") !== null
            ? localStorage.getItem("url")
            : "/",
    );
};

export const fetchData = async (url: string) => {
    try {
        const data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return await data.json();
    } catch (e) {
        return null;
    }
};