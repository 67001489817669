import React, { } from 'react';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useCommentsDropdownStyles } from './CommentsDropdownStyles';
import { useSelector } from 'react-redux';
import { selectEventInfo } from '../../../../../store/selectors';

const CommentsDropdown = ({ premadeText, setPremadeText }) => {

    const eventInfo = useSelector(selectEventInfo);
    const teams = [eventInfo ? eventInfo.team_home : 'No Team 1 Info', eventInfo ? eventInfo.team_away : 'No Team 2 Info'];
    const handleChangePremadeText = (event) => {
        setPremadeText(event.target.value)
    }

    const classes = useCommentsDropdownStyles();

    return (
        <form /* className="root"  */autoComplete="off" className={classes.form_root}>
            <FormControl id="select_premade_comment" /* className="formControl" */ className={classes.formControl}>
                <InputLabel data-testid="predefined-comment-label" data-cy="predefined-comment-label" htmlFor="comment-simple">{('*predefined comment')}</InputLabel>
                <Select
                    value={premadeText}
                    onChange={handleChangePremadeText}
                    inputProps={{
                        name: 'premade_comment'
                    }}
                    data-cy="select-predefined-comment"
                    data-testid="select-predefined-comment"
                >
                    <MenuItem data-cy="predefined-comment-item-1" value={"Walkover competitor " + teams[0]} /* classes={{ root: classes.root }} */>{"Walkover competitor " + teams[0]}</MenuItem>
                    <MenuItem data-cy="predefined-comment-item-2" value={"Walkover competitor " + teams[1]} /* classes={{ root: classes.root }} */>{"Walkover competitor " + teams[1]}</MenuItem>
                    <MenuItem data-cy="predefined-comment-item-3" value={"Retirement competitor " + teams[0]} /* classes={{ root: classes.root }} */>{"Retirement competitor " + teams[0]}</MenuItem>
                    <MenuItem data-cy="predefined-comment-item-4" value={"Retirement competitor " + teams[1]} /* classes={{ root: classes.root }} */>{"Retirement competitor " + teams[1]}</MenuItem>
                    <MenuItem data-cy="predefined-comment-item-5" value={"Home medical timeout"} /* classes={{ root: classes.root }} */>{"Home medical timeout"}</MenuItem>
                    <MenuItem data-cy="predefined-comment-item-6" value={"Away medical timeout"} /* classes={{ root: classes.root }} */>{"Away medical timeout"}</MenuItem>
                </Select>
            </FormControl>
        </form>
    )
}

export default CommentsDropdown;