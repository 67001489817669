//import { useState } from "react";

export const setClockValue = (gameTime: number): string => {
    const min = Math.floor(gameTime / 60);
    let sec = String(gameTime % 60);
    if (sec.length === 1) {
        sec = "0" + sec;
    }

    return min + ":" + sec;
};
