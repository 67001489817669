import { makeStyles } from "@material-ui/core";

export const useNavBarStyles = makeStyles((theme) => ({
    navbar_desktop: {
        margin: '0.3% 1% 0% 1%',
        height: '6%',
        //width: '98%',
        display: 'flex',
        justifyContent: 'space-between',
          alignItems: 'center',
          //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          borderRadius: '15px',
          //borderBottom: '1px solid',
          //backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)'
      },
      flex_divs:{
          display: 'flex',
          alignItems: 'center',
          width: '33%'
      },
      heart_beat_field: {
          display: 'flex',
          alignItems: 'center',
          width: '33%'
          //justifyContent: 'space-around',
      },
      heart_beat:{
          borderRadius: '15px',
          //backgroundColor: 'lightgreen',
      },
      curent_day_field:{
          justifyContent: 'space-between',
          display: 'flex',
          width: '33%',
          //flexDirection: 'column',
          alignItems: 'flex-end',
          fontFamily: '"Times New Roman", Times, serif'
      },	
      current_time:{
          fontWeight: 'bold',
          //fontFamily: '"Times New Roman", Times, serif'
          //boxShadow: "0px 0px 31px 2px rgba(255,119,3,0.9) inset 0px 0px 31px 2px rgba(255,119,3,0.9)",
          //textShadow: "0 0 5px #fff,  0 0 10px #fff,  0 0 7px #fff,  0 0 20px #88dfff, 0 0 35px #68ffc8, 0 0 40px #68ffc8, 0 0 50px #68ffc8, 0 0 75px #68ffc8"
      },
      server_time_text: {
          fontSize: '70%',
      },
      status_and_game_time_clock: {
          display: 'flex',
          alignItems: 'center',
          width: '33%',
          justifyContent: 'center',
          flexDirection: 'column',
          fontSize: '0.8em'
      },
      current_status: {
          fontSize: '2.5em',
          fontFamily: '"Times New Roman", Times, serif'
      },
}));
