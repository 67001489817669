import { makeStyles } from "@material-ui/core";

export const useStatisticsStyles = makeStyles((theme) => ({
    statistics: {
        margin: "1%",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "30%",
    },
    stat: {
        padding: "0.9%",
        display: "flex",
        justifyContent: "space-between",
        width: "99%",
        borderBottom: "1px dashed",
    },
}));
