/*eslint no-unreachable: "error"*/
import React from 'react';
import { selectCurrentGameInfoState, selectEventInfo } from '../../../store/selectors';
import { useSelector } from "react-redux";
import { useParticipantFieldStyles } from './ParticipantFieldStyles';


const ParticipantField = ({ team }) => {

    const classes = useParticipantFieldStyles();
    const { currentHomeColor1, currentHomeColor2, currentAwayColor1, currentAwayColor2 } = useSelector(selectCurrentGameInfoState);
    const eventInfo = useSelector(selectEventInfo);
    const teams = [eventInfo ? eventInfo.team_home : 'No Team 1 Info', eventInfo ? eventInfo.team_away : 'No Team 2 Info'];

    if (team === 'home') {
        return (
            <div /* className="participant_field_home" */ data-cy="participant-name-home" className={classes.participantFieldHome}>
                <div data-testid={"teamName-" + team} /* className={"teamName-" + team} */ className={classes.teamNameHome}>
                    {teams && teams[0]}
                </div>

                <div data-testid="colorFieldHome" className="colorFieldHome" style={styles.colorFieldHomeAway}>
                    <div
                        data-testid="homeColor1"
                        className="homeColor1"
                        data-cy="home-colors-1"
                        style={Object.assign({}, styles.homeAwayColor, { backgroundColor: currentHomeColor1 })}>
                    </div>

                    <div
                        data-testid="homeColor2"
                        className="homeColor2"
                        data-cy="home-colors-2"
                        style={Object.assign({}, styles.homeAwayColor, { backgroundColor: currentHomeColor2 })}>
                    </div>
                </div>

            </div>
        )
    }
    else {
        return (
            <div /* className="participant_field_away"  */data-cy="participant-name-away" className={classes.participantFieldAway}>
                <div data-testid="colorFieldAway" className="colorFieldAway" style={styles.colorFieldHomeAway}>
                    <div
                        data-testid="awayColor1"
                        className="awayColor1"
                        data-cy="away-colors-1"
                        style={Object.assign({}, styles.homeAwayColor, { backgroundColor: currentAwayColor1 })}>
                    </div>

                    <div
                        data-testid="awayColor2"
                        className="awayColor2"
                        data-cy="away-colors-2"
                        style={Object.assign({}, styles.homeAwayColor, { backgroundColor: currentAwayColor2 })}>
                    </div>
                </div>

                <div data-testid={"teamName-" + team} /* className={"teamName-" + team} */ className={classes.teamNameAway}>
                    {teams && teams[1]}
                </div>
            </div>
        )
    }
}

let styles = {
    colorFieldHomeAway: {
        border: "1px solid",
        backgroundColor: "white",
        height: "30%",
        width: "25%",
        display: "flex",
    },
    homeAwayColor: {
        width: "50%",
        height: "100%",
    }
}

export default ParticipantField;

