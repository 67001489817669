import React from 'react';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { selectCommandLogArrays } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import { useEditCancelDialogStyle } from './EditCancelDialogStyles';

export default function EditCancelDialog({open, cancelEditProps, team, handleChangeTeam, handleCloseCommandList} : any) {
	const commandLogArrays = useSelector(selectCommandLogArrays);
	const classes = useEditCancelDialogStyle();

	return (
		<Dialog
            PaperProps={{
                style: {
                    //backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            open={open}
            BackdropProps={{ style: { opacity: 0.1, whiteSpace: 'pre-line' } }}
            //disableBackdropClick={true}
            // onClose={(event, reason) => {
            //     if (reason !== 'backdropClick') {
            //         onClose(event, reason);
            //     }
            // }}
            aria-labelledby="alert-dialog-title-command-list"
            aria-describedby="alert-dialog-description-command-list"
            data-testid="alert-dialog-command-list"
        >
            <DialogContent data-cy="popup-editcancel">
                <DialogContentText data-testid="alert-dialog-description-command-list" id="alert-dialog-description-command-list">
                    {cancelEditProps.type === 'cancel' ?
                        'Are you sure you want to DELETE '
                        :
                        cancelEditProps.type === 'edit' ? 'Edit  ' : 'Invalid Score!'
                    }

                    {
                        cancelEditProps.type === 'invalidScore' ? null :
                            "#" + commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.id + " " +
                            (commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team === 1 ? 'Home' :
                                commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team === 0 || !commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.team ? ''
                                    :
                                    'Away') + " " +
                            commandLogArrays.edit_cancel_commands[cancelEditProps.index]?.key + "?"
                    }
                </DialogContentText>

                {/* Show/hide team dropdown depends on the type of the popup (Cancel or Edit) */}
                {
                    cancelEditProps.type === 'cancel' || cancelEditProps.type === 'invalidScore' ?
                        null
                        :
                        <form autoComplete="off" className={classes.form_root}>
                            <FormControl id="team_formControl" className={classes.formControl}>
                                <InputLabel htmlFor="select_team_command_list">{('*TEAM')}</InputLabel>
                                <Select
                                    data-testid="select_team_command_list"
                                    id="select_team_command_list"
                                    value={team}
                                    onChange={handleChangeTeam}
                                    data-cy="select-team_command_list"
                                >
                                    <MenuItem data-cy="attendance-item-1" value="home">Home</MenuItem>
                                    <MenuItem data-cy="attendance-item-2" value="away">Away</MenuItem>
                                </Select>
                            </FormControl>
                        </form>
                }
            </DialogContent>

            {
                cancelEditProps.type === 'invalidScore' ?
                    <DialogActions>
                        <Button data-testid="ok-button" onClick={() => {
                            handleCloseCommandList(cancelEditProps.type, false, null, null)
                        }}
                            variant="contained"
                            data-cy="popup-editcancel-ok"
                            color="secondary">
                            OK
                        </Button>
                    </DialogActions>

                    :

                    <DialogActions>
                        <Button data-testid="no-button" onClick={() => {
                            handleCloseCommandList(cancelEditProps.type, false, null, null)
                        }}
                            variant="contained"
                            data-cy="popup-editcancel-no"
                            color="secondary">
                            No
                        </Button>
                        <Button
                            data-testid="yes-button"
                            onClick={() => {
                                handleCloseCommandList(cancelEditProps.type, true, 'edit', cancelEditProps.index)
                            }}
                            variant="contained"
                            data-cy="popup-editcancel-yes"
                            color="primary"
                            autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
            }
        </Dialog>
	);
}