export interface RootState {
    gameTime: number;
    isClockRunning: boolean;
    currentWeather: string;
    currentPreMatchInfo: string;
    currentWind: string;
    currentAttendance: string;
    currentTossWinner: string;
    currentHomeChoice: string;
    currentAwayChoice: string;
    currentHomeColor1: string;
    currentHomeColor2: string;
    currentAwayColor1: string;
    currentAwayColor2: string;
    currentTeams: ObjectTeamList[];
    currentSafety: string;
    currentHomeFieldGoal: boolean;
    currentAwayFieldGoal: boolean;
    currentHomeTouchdownDisabled: boolean;
    currentAwayTouchdownDisabled: boolean;
    currentHomeOnePointMadeMissed: boolean;
    currentAwayOnePointMadeMissed: boolean;
    currentHomeTwoPointsMadeMissed: boolean;
    currentAwayTwoPointsMadeMissed: boolean;
    currentKickOff: string;
    currentCloseOpenGame: string;
    currentHomeActiveTeam: string;
    currentAwayActiveTeam: string;
    currentOverlayDisplay: string;
    currentStatus: number;
    currentHomeDown: string;
    currentAwayDown: string;
    currentHomeYards: number;
    currentAwayYards: number;
    currentTeamPossesion: string;
    currentSnap: string;
    currentFirstServeDisplay: string;
    currentUser: string;
    currentSportSuggestedStatus: SportSuggestedStatus;
    currentTeamButtonsDisabled: object;
    currentOpenConfirmPopUp: object;
    currentUncfPointId: number;
    currentPopUpCommand: PopUpCommand;
    currentGameScoreToStatusId: object;
    currentMasterCloseGame: boolean;
    currentMSFStatus: string;
    currentLastIdForUpdateStatus: number;
    currentLastCnfPointId: number;
    currentIsDisabledTakenControl: boolean;
    mapIdToActionId: object;
    commandId: number;
    commandInc: number;
    feedCommandInc: number;
    connectionStatus: boolean;
    heartBeat: object;
    eventInfo: any;
    score: any;
    overallGameScore: number[];
    lock: object;
    buttonBlink: string;
    commandLogArrays: any;
    authorized: boolean;
	config: any;
    username: string;
    token: string;
    organizationId: string;
}
export interface ObjectTeamList {
    offensive: Player[];
    defensive: Player[];
    special: Player[];
}

export interface Player {
    name: string;
    order: number;
    playerId: number;
    position: string;
    teamType: string;
}

export interface SportSuggestedStatus {
    tabletennis: number;
    americanfootball: number;
}

export interface PopUpCommand {
    tabletennis: object;
    americanfootball: object;
}

export const actionTypes = {
    setGameTime: "setGameTime",
    setIsClockRunning: "setIsClockRunning",
    stopClock: "stopClock",
    startClock: "startClock",
    setWeather: "setWeather",
    setPreMatchInfo: "setPreMatchInfo",
    setWind: "setWind",
    setAttendance: "setAttendance",
    setTossWinner: "setTossWinner",
    setHomeChoice: "setHomeChoice",
    setAwayChoice: "setAwayChoice",
    setHomeColor1: "setHomeColor1",
    setHomeColor2: "setHomeColor2",
    setAwayColor1: "setAwayColor1",
    setAwayColor2: "setAwayColor2",
    setPlayers: "setPlayers",
    setSafety: "setSafety",
    setHomeFieldGoal: "setHomeFieldGoal",
    setAwayFieldGoal: "setAwayFieldGoal",
    setHomeTouchdownDisabled: "setHomeTouchdownDisabled",
    setAwayTouchdownDisabled: "setAwayTouchdownDisabled",
    setHomeOnePointMadeMissed: "setHomeOnePointMadeMissed",
    setAwayOnePointMadeMissed: "setAwayOnePointMadeMissed",
    setHomeTwoPointsMadeMissed: "setHomeTwoPointsMadeMissed",
    setAwayTwoPointsMadeMissed: "setAwayTwoPointsMadeMissed",
    setKickOff: "setKickOff",
    setCloseOpenGame: "setCloseOpenGame",
    setHomeActiveTeam: "setHomeActiveTeam",
    setAwayActiveTeam: "setAwayActiveTeam",
    setOverlayDisplay: "setOverlayDisplay",
    setStatus: "setStatus",
    setHomeDown: "setHomeDown",
    setAwayDown: "setAwayDown",
    setHomeYards: "setHomeYards",
    setAwayYards: "setAwayYards",
    setTeamPossesion: "setTeamPossesion",
    setSnap: "setSnap",
    setFirstServeDisplay: "setFirstServeDisplay",
    setCurrentUser: "setCurrentUser",
    setSportSuggestedStatus : "setSportSuggestedStatus",
    setTeamButtonsDisabled: "setTeamButtonsDisabled",
    setOpenConfirmPopUp: "setOpenConfirmPopUp",
    setUncfPointId: "setUncfPointId",
    setPopUpCommand: "setPopUpCommand",
    setGameScoreToStatusId: "setGameScoreToStatusId",
    setMasterCloseGame: "setMasterCloseGame",
    setMSFStatus: "setMSFStatus",
    setLastIdForUpdateStatus: "setLastIdForUpdateStatus",
    setLastCnfPointId: "setLastCnfPointId",
    setIsDisabledTakenControl: "setIsDisabledTakenControl",
    setMapIdToActionId: "setMapIdToActionId",
    setCommandId: "setCommandId",
    setCommandInc: "setCommandInc",
    setFeedCommandInc: "setFeedCommandInc",
    setConnectionStatus: "setConnectionStatus",
    setHeartBeat: "setHeartBeat",
    setEventInfo: "setEventInfo",
    setScore: "setScore",
    setLock: "setLock",
    setButtonBlink: "setButtonBlink",
    setCommandLogArrays: "setCommandLogArrays",
    setAuthorized: "setAuthorized",
    setConfig: "setConfig",
    setOverallGameScore: "setOverallGameScore",
    setUsername: "setUsername",
    setAuth0Token: "setAuth0Token",
    setOrganizationId: "setOrganizationId"
};
