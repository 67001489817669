import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { allStatusesAvailable } from './AmericanFootball';
import { useSelector } from 'react-redux';
import { selectHomeDown, selectAwayDown, selectStatus } from '../../store/selectors';
import { useTableStyles } from './TableStyles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: purple[500],
//         },
//         secondary: {
//             main: green[500],
//         },
//     },
// });

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: '0.8em',
        },
        body: {
            //fontSize: 11,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

function createData(teamName: string, down: string, first: number, second: number, third: number, fourth: number, ot: number, total: number) {
    return { teamName, down, first, second, third, fourth, ot, total };
}


const FixtureTable = ({ teams, score }: any) => {
    const currentStatus = useSelector(selectStatus);
    const homeDown = useSelector(selectHomeDown);
    const awayDown = useSelector(selectAwayDown);
    const classes = useTableStyles();
    const total = [
        (score[26][0] + score[28][0] + score[30][0] + score[32][0] + score[34][0]),
        (score[26][1] + score[28][1] + score[30][1] + score[32][1] + score[34][1])
    ];

    const rows = [
        createData(teams[1], awayDown,
            score[26][1] !== 0 && score[26][1],
            score[28][1] !== 0 && score[28][1],
            score[30][1] !== 0 && score[30][1],
            score[32][1] !== 0 && score[32][1],
            score[34][1] !== 0 && score[34][1],
            total[1]),
        createData(teams[0], homeDown,
            score[26][0] !== 0 && score[26][0],
            score[28][0] !== 0 && score[28][0],
            score[30][0] !== 0 && score[30][0],
            score[32][0] !== 0 && score[32][0],
            score[34][0] !== 0 && score[34][0],
            total[0])
    ];
    
    return (
        <div className="table" style={styles.table}>
            <TableContainer component={Paper} style={styles.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className={classes.minWidth}><div>{allStatusesAvailable[currentStatus].title}</div></StyledTableCell>
                            <StyledTableCell align="right">DOWN</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">2</StyledTableCell>
                            <StyledTableCell align="right">3</StyledTableCell>
                            <StyledTableCell align="right">4</StyledTableCell>
                            <StyledTableCell align="right">OT</StyledTableCell>
                            <StyledTableCell align="right">TOTAL</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.teamName}>
                                <StyledTableCell className={classes.backgroundStyle} component="th" scope="row">
                                    {row.teamName}
                                </StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center"> {row.down}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.first}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.second}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.third}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.fourth}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.ot}</StyledTableCell>
                                <StyledTableCell className={classes.backgroundStyle} align="center">{row.total}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

const styles = {
    table: {
        fontSize: '1vw'
    },
    paper: {
        //width: '100%',
    }
}

export default FixtureTable;