import { makeStyles } from "@material-ui/core";

export const useLockOverlayStyles = makeStyles((theme) => ({
    lock_overlay_field_block: {
        display: 'block',
        //pointerEvents: 'none',
        //pointerEvents: 'all' ,
        position: "absolute",
        //height : '15%',
        backgroundColor: "rgb(0 0 0)",
        //width: '44%',
        //borderRadius: '10px',
        zIndex: 1000,
        opacity: 0.4,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    lock_overlay_field_none: {
        display: 'none',
        position: "absolute",
        backgroundColor: "rgb(0 0 0)",
        zIndex: 1000,
        opacity: 0.4,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));
