import { makeStyles } from "@material-ui/core";

export const useTableStyles = makeStyles((theme) => ({
    minWidth: {
        minWidth: 100,
        textTransform: 'uppercase'
    },
    backgroundStyle: {
        fontSize: '1vw',
        //backgroundColor: 'grey',
        //color: 'white',
    },
    tableCellStyle: {
        border: '3px solid black',
        borderRadius: '15px',
    }	
}));