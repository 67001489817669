import React from 'react';
import { useEventInfoStyles } from './EventInfoStyles';
import { selectEventInfo, selectScore } from '../../../store/selectors';
import { useSelector } from 'react-redux';

const EventInfo = () => {

    const classes = useEventInfoStyles();
    const eventInfo = useSelector(selectEventInfo);
    const score = useSelector(selectScore);
    const teams = [eventInfo?.team_home || 'No Team 1 Info', eventInfo?.team_away || 'No Team 2 Info'];
    const start_date = [eventInfo?.startdate || 'No start date Available'];

    return (
        <div className={classes.event_info}>

            <div className={classes.flex_divs}>
                <div data-testid="start-date" className="start_date" data-cy="start-date">{start_date}</div>
            </div>

            <div className={classes.teams_and_score_field}>
                <div className={classes.teams_and_score}>
                    <div data-testid="team-name-home" className={classes.divs_team_name_field} data-cy="team-name-home">{teams[0]}</div>
                    <div data-testid="current-score" className={classes.score} data-cy="score">{(score.current_score ? score.current_score[0] : 0) + ' : ' + (score.current_score ? score.current_score[1] : 0)}</div>
                    <div data-testid="team-name-away" className={classes.divs_team_name_field} data-cy="team-name-away">{teams[1]}</div>
                </div>
            </div>

            <div className={classes.flex_divs}>
                {/* <div>Scout Name:</div>
						<div>Skype ID:</div> */}
            </div>
        </div>
    )
}

export default EventInfo;
