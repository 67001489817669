import React, { useEffect, useState } from 'react';
import { List } from "react-virtualized";
import CommandRow from './CommandRow';
import Button from '@material-ui/core/Button';
import { /* sportID ,  */allTeamCommands, allNoTeamCommands, allTeamCommandsKeys, allNoTeamCommandsKeys } from './AmericanFootball';
import { buttonColors } from './SRTMainButton';

export default function CommandList({ commandLogArray, mapCommandLog, deletedCommandIds, showButtons,
    tab, score, handleClickOpenCommandList }: any) { /* We can use this status to display deleted_at: {whatever game was done it -> 'deleted_at: gamenumber 5" for example} */

    const [listHeight, setListHeight] = useState(window.innerHeight * 0.40);
    const [rowHeight, setRowHeight] = useState(window.innerHeight * 0.40 * 0.12);

    const renderRow = ({ index, /* parent, */ key, style }) => {
        let isCancelled = deletedCommandIds.includes(commandLogArray[index].id) ? true : false;
        console.log();
        return (
            <div
                key={key}
                className="renderRowWrapper"
                style={Object.assign({
                    // backgroundImage : commandLogArray[index].team === undefined || commandLogArray[index].team === 0 ? 
                    // allNoTeamCommands[commandLogArray[index].key]?.color
                    // : 
                    // commandLogArray[index].team === 1 ? 'linear-gradient(90deg, #c0dbfa 0%, #f5f7fa 60% , ' + allTeamCommands[commandLogArray[index].key].color + ')' : 'linear-gradient(90deg, #fee140 0%, #f5f7fa 60% , ' + allTeamCommands[commandLogArray[index].key].color + ')'},styles.row_wrapper , style
                    
                    // backgroundColor: commandLogArray[index].team === undefined || commandLogArray[index].team === 0 ?
                    //     allNoTeamCommands[commandLogArray[index].key]?.color
                    //     :
                    //     commandLogArray[index].team === 1 ? 'linear-gradient(90deg, #c0dbfa 50%, #c0dbfa 100%)' : 'linear-gradient(90deg, #f8fc65 0%, #f8fc65 100%)'

                    backgroundColor: buttonColors[commandLogArray[index].key]?.color ,
                    color: buttonColors[commandLogArray[index].key]?.textColor
                }, styles.row_wrapper, style)}>

                <div className='button_cancel_field hidden'>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={showButtons ? styles.show_button : styles.hide_button}
                        disabled={
                            allTeamCommandsKeys.indexOf(commandLogArray[index].key) !== -1 ?
                                allTeamCommands[commandLogArray[index].key].cancelDisabled
                                :
                                allNoTeamCommandsKeys.indexOf(commandLogArray[index].key) !== -1 && allNoTeamCommands[commandLogArray[index].key].cancelDisabled
                        }
                        onClick={() => handleClickOpenCommandList('cancel', index, false)}
                    >
                        Cancel
                    </Button>
                </div>
                <div className='button_edit_field'>
                    <Button
                        style={showButtons ? Object.assign({ backgroundColor: 'lightgreen' }, styles.show_button) : styles.hide_button}
                        disabled={
                            allTeamCommandsKeys.indexOf(commandLogArray[index].key) !== -1 ?
                                allTeamCommands[commandLogArray[index].key].editDisabled
                                :
                                allNoTeamCommandsKeys.indexOf(commandLogArray[index].key) !== -1 && allNoTeamCommands[commandLogArray[index].key].editDisabled
                        }
                        onClick={() => {
                            // if (commandLogArray[index].isConfirmed) {
                            //   // TODO add ALERT pop up
                            //   return;
                            // }
                            handleClickOpenCommandList('edit', index, true);
                        }
                        }
                        variant="contained"
                        //color="primary"
                        size="small"
                    >
                        Edit
                    </Button>
                </div>

                <CommandRow key={key} id={commandLogArray[index].id} isConfirmed={commandLogArray[index].isConfirmed} isCancelled={isCancelled} home_score={commandLogArray[index].score_home} away_score={commandLogArray[index].score_away} sequence={commandLogArray[index].sequence} mapCommandLog={mapCommandLog} tab={tab} />

            </div>
        );
    }

    const updateDimensions = () => {
        setListHeight(window.innerHeight * 0.3);
        setRowHeight(window.innerHeight * 0.3 * 0.15);
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };

        // eslint-disable-next-line 
    }, []);

    return (
        <List
            style={styles.overlay_list} // HACK:  We have this as height and width props are required for List element
            className="ListWrapper"
            width={window.innerWidth} //This multiplication by "10" is to set max-width for the GRID div which is inside the LIST, as we have style prop in the LIST element which overwrite the height & width values
            height={listHeight}
            rowHeight={rowHeight}
            rowRenderer={renderRow}
            rowCount={commandLogArray.length}
        />
    );
}

const styles = {
    overlay_list: {
        //padding: '0.5%',
        height: '100%',
        width: '100%',
        //overflow: 'hidden',
        //maxWidth: "100%",
    },
    show_button: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '15px',
    },
    hide_button: {
        display: 'none'
    },
    row_wrapper: {
        margin: '0%',
        //padding: '0%',
        border: '0.5px solid',
        //padding: '0.5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        //gap: '1%',
        //borderRadius: '10px',
    },
    form_root: {
        //width: '30%',
        //justifyContent: 'space-around',
        display: 'flex',
        alignItems: 'center',
    }
}